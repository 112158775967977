import "./dashboard-household.scss";
import { DashboardObject } from "@core/model/dashboard-object";
import { LabelValue } from "@shared/models/label-value.interface";
import { useQyGetHouseholdDashboard } from "@factory/query/dashboard.query";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import { FaHome } from "react-icons/fa";
import { useDashboard } from "@core/utility/dashboard-contex";

export function DashboardHousehold() {
  const { householdCount, setHouseholdCount } = useDashboard();

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    setHouseholdCount(cleanedArray[0]?.value || 0);
  };

  const { isLoading, isError, error } = useQyGetHouseholdDashboard(
    true,
    handleSuccess
  );

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );

  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occurred" message={(error as any)?.message} />
    </div>
  );

  const householdCard = (
    <div className="dashboard-card-household-card">
      <div className="info">
        <span>{householdCount}</span>
        <h5>Household</h5>
      </div>
      <FaHome className="icon household-icon" />
    </div>
  );

  return (
    <div id="DashboardHousehold" className="dashboard-household">
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && <>{householdCard}</>}
    </div>
  );
}

export default DashboardHousehold;
