import { Outlet } from "react-router-dom";

import { FilterHouseholdProvider } from "./filter-household/filter-household.context";
import './household.scss';

export function Household() {
  return (
    <div id="Household" className="household">
       <FilterHouseholdProvider>
        <Outlet />
      </FilterHouseholdProvider>
    </div>
  );
}

export default Household;
