import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetMeetingModel } from "@factory/model/query-model-meeting";
import { meetingFormDefault } from "./form.default";
import { MeetingFormSchema } from "./form.rule";

export const useFormDefaultMeeting = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetMeetingModel | undefined) => {
    return !cachedValue
      ? meetingFormDefault
     : ({
        title: cachedValue.title,
        information: cachedValue.information,
        date: cachedValue.date,
        time: cachedValue.time,
        location: cachedValue.location,
      } as MeetingFormSchema);
  };

  return {
    getDefault,
  };
};
