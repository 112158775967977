import { createContext, useContext, useState } from "react";

const DashboardContext = createContext<{
  householdCount: number;
  setHouseholdCount: (count: number) => void;
  inhabitantCount: number;
  setInhabitantCount: (count: number) => void;
} | null>(null);

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [householdCount, setHouseholdCount] = useState<number>(0);
  const [inhabitantCount, setInhabitantCount] = useState<number>(0);

  return (
    <DashboardContext.Provider
      value={{
        householdCount,
        setHouseholdCount,
        inhabitantCount,
        setInhabitantCount,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context)
    throw new Error("useDashboard must be used within a DashboardProvider");
  return context;
};
