import "./edit-meeting.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import { useEditMeeting } from "./edit-meeting.hook";

import { TabMenu } from "primereact/tabmenu";
import { Outlet } from "react-router-dom";

export function EditMeeting() {
  const {
    meetingData,
    isLoading,
    meetingError,
    formMethod,
    actions,
    menu,
    activeIndex,
    handleBack,
    handleAction,
  } = useEditMeeting();

  const displayLoading = (
    <div className="card-loading">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );

  const mainContent = (
    <div>
      <section className="grid grid-cols-1 gap-2 mb-4 bg-white">
        <TabMenu model={menu} activeIndex={activeIndex} />
        <Outlet />
      </section>
    </div>
  );

  return (
    <div id="Meeting" className="meeting">
      <HeaderContent
        title={meetingData?.title || "Edit Meeting"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <ConfirmDialog />
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {meetingError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EditMeeting;
