import { ReactNode, useCallback } from "react";
import { FaCertificate, FaHouse, FaPerson, FaPrint } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { MdCameraIndoor } from "react-icons/md";
import { useUserIdentityContext } from "@core/services/user-identity.context";

const activeClass =
  "bg-gradient-to-tr from-buster-green-200 to-buster-green-100 text-gray-800";
const hoverClass = "hover:bg-buster-green-100";
const newNavigation = (
  title: string,
  icon: ReactNode,
  path: string,
  isHidden?: boolean
) => {
  return {
    title,
    icon,
    path,
    activeClass,
    hoverClass,
    isHidden,
  };
};

export function useAuthShellMenu() {
  const { userIsAdmin, getUser } = useUserIdentityContext();
  const homeNav = newNavigation("Home", <MdDashboard />, "");
  const householdNav = newNavigation("Household", <FaHouse />, "household");
  const inhabitantNav = newNavigation("Inhabitant", <FaUsers />, "inhabitant");
  const meetingNav = newNavigation("Meeting", <MdCameraIndoor />, "meeting");
  const accountNav = newNavigation("Account", <FaPerson />, "person");
  const certificateNav = newNavigation(
    "Certifications",
    <FaPrint />,
    "certificate"
  );
  const getDesktopNavigation = useCallback(() => {
    const navigation = [
      homeNav,
      householdNav,
      inhabitantNav,
      meetingNav,
      accountNav,
      certificateNav,
    ];

    const filteredNavigation = navigation.filter((item) => !item.isHidden);

    return filteredNavigation;
  }, [userIsAdmin, getUser]);

  return {
    homeNav,
    getDesktopNavigation,
  };
}
