import { useFormContext } from "react-hook-form";
import { InhabitantFormSchema } from "@factory/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { sexOptions } from "@shared/data/sex";

import { purokOption } from "@shared/data/purok";

type InputFormSchema = InhabitantFormSchema;

export function FormInhabitantAttendance() {
  const { control } = useFormContext<InhabitantFormSchema>();

  return (
    <div className="form-wrapper flex justify-center items-center h-screen">
      <div
        id="FormInhabitant"
        className="inhabitant-attendance w-full md:w-1/2  mt-4"
      >
        <h4 className="text-center">Personal Information</h4>
        <DropdownControl<InputFormSchema>
          control={control}
          name="purok"
          label="Purok"
          className="w-full"
          options={purokOption}
        />
        <InputControl<InputFormSchema>
          control={control}
          name="firstName"
          label="First Name"
          className="w-full"
          containerClassName="pb-2"
        />
        <InputControl<InputFormSchema>
          control={control}
          name="middleName"
          label="Middle Name"
          className="w-full"
          containerClassName="pb-2"
        />
        <InputControl<InputFormSchema>
          control={control}
          name="lastName"
          label="Last Name"
          className="w-full"
          containerClassName="pb-2"
        />
        <DropdownControl<InputFormSchema>
          control={control}
          name="gender"
          label="Sex"
          className="w-full"
          options={sexOptions}
        />
        <InputDateControl<InputFormSchema>
          control={control}
          name="birthday"
          label="Birthday"
          className="w-full"
          containerClassName="pb-2"
        />
        <InputControl<InputFormSchema>
          control={control}
          name="mobile"
          label="Mobile"
          className="w-full"
          containerClassName="pb-2"
        />
        <CheckboxControl<InputFormSchema>
          control={control}
          name="isVoter"
          label="Voter"
          containerClassName="pb-2"
        />
      </div>
    </div>
  );
}

export default FormInhabitantAttendance;
