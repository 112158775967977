import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { PersonAddressFormRule, PersonAddressFormSchema } from "@factory/form/form.rule";
import { useFormDefaultPersonAddress } from "@factory/form/form-default-person-address.hook";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { ActionEnum } from "@factory/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdatePersonAddress, 
  useQyGetPersonAddressById, 
  useQyDeletePersonAddress 
} from "@factory/query/person-address.query";
import { GetResponsePersonAddressModel } from "@factory/model/query-model-person-address";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditPersonAddress() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultPersonAddress();
  const navigate = useNavigate();
  const { mapUpdatePersonAddress, mapDeletePersonAddress } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { personAddressId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID PersonAddress
  const handleGetApiSuccess = (data: GetResponsePersonAddressModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("userCode", responseData?.user_code || "");
      setValue("streetName", responseData?.street_name || "");
      setValue("subdivision", responseData?.subdivision || "");
      setValue("zone", responseData?.zone || "");
      setValue("sitio", responseData?.sitio || "");
      setValue("purok", responseData?.purok || "");
      setValue("barangay", responseData?.barangay || "");
      setValue("municipality", responseData?.municipality || "");
      setValue("province", responseData?.province || "");
      setValue("region", responseData?.region || "");
      setValue("country", responseData?.country || "");
      setValue("zip", responseData?.zip || "");
      setValue("longitude", responseData?.longitude);
      setValue("latitude", responseData?.latitude);
    }
  };
  const {
    data: personAddressResponse,
    isLoading,
    isError: personAddressError,
  } = useQyGetPersonAddressById(personAddressId || "", handleGetApiSuccess);
  const personAddressData = personAddressResponse?.data?.[0];

  // API UPDATE PersonAddress
  const handleUpdateApiSuccess = () => {
    showSuccess("PersonAddress updated successfully");
  };
  const { mutate: updatePersonAddress, isLoading: isEditLoading } = useQyUpdatePersonAddress(
    handleUpdateApiSuccess
  );

  // API DELETE PersonAddress
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("PersonAddress deleted successfully");
  };
  const { mutate: deletePersonAddress } = useQyDeletePersonAddress(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<PersonAddressFormSchema>({
    defaultValues: getDefault(personAddressData),
    resolver: zodResolver(PersonAddressFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: PersonAddressFormSchema) => {
    if (!personAddressData) {
      throw new Error("No personAddress data");
    }

    const formData = mapUpdatePersonAddress(form, personAddressData.code);
    updatePersonAddress(formData);
  };
  const handleValidateError = (err: FieldErrors<PersonAddressFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!personAddressData) {
      throw new Error("No personAddress data found");
    }

    const form = mapDeletePersonAddress(personAddressData.code);
    deletePersonAddress(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    personAddressId,
    personAddressData,
    isLoading,
    personAddressError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}