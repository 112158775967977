import { useNavigate } from "react-router-dom";

export const handleChartClick = (
  event: React.MouseEvent,
  chartRef: any,
  chartData: any,
  navigate: ReturnType<typeof useNavigate>,
  filterKey: string,
  filterValues: { [key: string]: string },
  redirectType: "inhabitant" | "household"
) => {
  const chartInstance = chartRef.current.getChart();

  if (!chartInstance) {
    console.log("Chart instance is not available.");
    return;
  }

  const points = chartInstance.getElementsAtEventForMode(
    event,
    "nearest",
    { intersect: true },
    false
  );

  if (points.length) {
    const firstPoint = points[0];
    const clickedIndex = firstPoint.index;
    const clickedLabel = chartData.labels[clickedIndex];

    const filter = filterValues[clickedLabel];
    if (filter) {
      if (redirectType === "inhabitant") {
        navigate(`/inhabitant?filter=${encodeURIComponent(filter)}`);
      } else if (redirectType === "household") {
        navigate(`/household?filter=${encodeURIComponent(filter)}`);
      }
    }
  } else {
    console.log("No active points detected.");
  }
};
