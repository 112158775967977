import { Chart } from "primereact/chart";
import "./dashboard-inhabitant-purok.scss";
import ErrorSection from "@shared/ui/error-section/error-section";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import { useQyGetInhabitantPurokDashboard } from "@factory/query/dashboard.query";
import { LabelValue } from "@shared/models/label-value.interface";
import { DashboardObject } from "@core/model/dashboard-object";
import { useRef, useState } from "react";
import { useDashboard } from "@core/utility/dashboard-contex";
import { handleChartClick } from "@core/utility/handle-chart-click";
import { useNavigate } from "react-router-dom";

export interface DashboardInhabitantPurokProps {}

export function DashboardInhabitantPurok() {
  const { inhabitantCount } = useDashboard();
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({
    labels: [],
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef<any>(null);
  const navigate = useNavigate();

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    const bgColor = [
      "#e0950a",
      "#ec27ec",
      "#1d29ce",
      "#20c429",
      "#d4692c",
      "#183b20",
      "#c51c24",
      "#2b2828",
    ];
    const bgHoverColor = [
      "#b4790b",
      "#911091",
      "#0e168a",
      "#157a1a",
      "#9e4410",
      "#102916",
      "#850e14",
      "#1d1b1b",
    ];
    const model = {
      labels: cleanedArray.map((item) => item.label),
      datasets: [
        {
          data: cleanedArray.map((item) => item.value),
          backgroundColor: bgColor,
          hoverBackgroundColor: bgHoverColor,
        },
      ],
    };
    const options = {
      cutout: "60%",
      responsive: true,
      maintainAspectRatio: false,
    };

    setChartData(model);
    setChartOptions(options);
  };
  const { isLoading, isError, error } = useQyGetInhabitantPurokDashboard(
    true,
    handleSuccess
  );

  const filterValues = {
    "PUROK 1": JSON.stringify({ purok: "PUROK 1" }),
    "PUROK 2": JSON.stringify({ purok: "PUROK 2" }),
    "PUROK 3": JSON.stringify({ purok: "PUROK 3" }),
    "PUROK 4": JSON.stringify({ purok: "PUROK 4" }),
    "PUROK 5": JSON.stringify({ purok: "PUROK 5" }),
    "PUROK 6": JSON.stringify({ purok: "PUROK 6" }),
    "PUROK 7": JSON.stringify({ purok: "PUROK 7" }),
    UNASSIGNED: JSON.stringify({ purok: "" }),
  };
  const handleiInhabitantPurokChartClick = (event: React.MouseEvent) => {
    handleChartClick(
      event,
      chartRef,
      chartData,
      navigate,
      "purok",
      filterValues,
      "inhabitant"
    );
  };

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occurred" message={(error as any)?.message} />
    </div>
  );
  const chart = (
    <div className="dashboard-inhabitant-purok-chart">
      <h5 className="text-gray-500">Inhabitant Purok</h5>
      <div className="chart-container">
        <Chart
          type="doughnut"
          data={chartData}
          options={chartOptions}
          className="w-full h-full"
          onClick={handleiInhabitantPurokChartClick}
          ref={chartRef}
        />
        <div className="inhabitant-count-overlay">
          <span>{inhabitantCount}</span>
        </div>
      </div>
    </div>
  );

  return (
    <div id="DashboardInhabitantPurok" className="dashboard-inhabitantPurok">
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && chart}
    </div>
  );
}

export default DashboardInhabitantPurok;
