import "./amount-paid.scss";

export function AmountPaid() {
  return (
    <footer className="amount-paid mt-16 ml-2 text-left">
      <p>
        Amount Paid:
        <span
          className="italic underline ml-2"
          contentEditable
          suppressContentEditableWarning
        >
          ₱130.00
        </span>
      </p>
      <p>
        O.R. No:
        <span
          className="italic underline ml-2"
          contentEditable
          suppressContentEditableWarning
        >
          1050170
        </span>
      </p>
    </footer>
  );
}

export default AmountPaid;
