import "./form-household.scss";
import { useFormContext } from "react-hook-form";
import { HouseholdFormSchema } from "@factory/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";
import { Divider } from "primereact/divider";
import { MapWithMarker } from "@core/component/map-with-marker/map-with-marker";
import { purokOption } from "@shared/data/purok";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";

type InputFormSchema = HouseholdFormSchema;

export function FormHousehold() {
  const { control, getValues } = useFormContext<HouseholdFormSchema>();
  const lat = getValues("latitude");
  const long = getValues("longitude");

  return (
    <div id="FormHousehold" className="household">
      <section className="mt-4">
        <ImageControl<InputFormSchema>
          control={control}
          name="photo"
          label="Household Photo"
          imagePreviewClass="w-32 h-32"
        />
      </section>

      <Divider />
      <div className="w-full grid grid-cols-1 md:grid-cols-3">
        <section>
          <h4>Address</h4>
          <InputControl<InputFormSchema>
            control={control}
            name="number"
            label="Household Number"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="name"
            label="Household Name"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="streetName"
            label="Street Name"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="subdivision"
            label="Subdivision"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="zone"
            label="Zone"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="sitio"
            label="Sitio"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <DropdownControl<InputFormSchema>
            control={control}
            name="purok"
            label="Purok"
            className="w-full md:w-3/4"
            options={purokOption}
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="latitude"
            label="Latitude"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            minFractionDigits={2}
            maxFractionDigits={50}
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="longitude"
            label="Longitude"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            minFractionDigits={2}
            maxFractionDigits={50}
          />
        </section>
        <section>
          <h4>General</h4>
          <CheckboxControl<InputFormSchema>
            control={control}
            name="allowBoarders"
            label="Do you allow boaders?"
            containerClassName="pb-2"
          />
          <CheckboxControl<InputFormSchema>
            control={control}
            name="hasBoardingPermit"
            label="Do you have a rental permit?"
            containerClassName="pb-2"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="houseMaterial"
            label="Househol Materials"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="rooms"
            label="Number of Rooms"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="toilets"
            label="Number of Toilets"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <CheckboxControl<InputFormSchema>
            control={control}
            name="hasBackyardGarden"
            label="Has backyard garden?"
            containerClassName="pb-2"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="incomeSources"
            label="Income sources"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="pets"
            label="Number of Pets"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="twoWheelVehicle"
            label="Number of Two-wheeled Vehicles"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="threeWheelVehicle"
            label="Number of Three-wheeled Vehicles"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="fourWheelVehicle"
            label="Number of Four-wheeled Vehicles"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
          />
        </section>
        <section>
          <MapWithMarker lat={lat} long={long} containerWidth="400px" />
        </section>
      </div>
    </div>
  );
}

export default FormHousehold;
