import { SigninAuthenticationModel } from "@factory/model/query-model-authentication";
import {
  CreateHouseholdModel,
  CreateHouseholdModelExtension,
  UpdateHouseholdModelExtension,
} from "@factory/model/query-model-household";
import { UpdateHouseholdModel } from "@factory/model/query-model-household";
import { DeleteHouseholdModel } from "@factory/model/query-model-household";
import { CreateMeetingModel } from "@factory/model/query-model-meeting";
import { UpdateMeetingModel } from "@factory/model/query-model-meeting";
import { DeleteMeetingModel } from "@factory/model/query-model-meeting";
import { CreateAttendanceModel } from "@factory/model/query-model-attendance";
import { DeleteAttendanceModel } from "@factory/model/query-model-attendance";
import {
  CreateInhabitantModel,
  CreateInhabitantModelExtension,
  UpdateInhabitantModelExtension,
} from "@factory/model/query-model-inhabitant";
import { UpdateInhabitantModel } from "@factory/model/query-model-inhabitant";
import { DeleteInhabitantModel } from "@factory/model/query-model-inhabitant";
import {
  CreatePersonModel,
  CreatePersonModelExtension,
  UpdatePersonModelExtension,
} from "@factory/model/query-model-person";
import { UpdatePersonModel } from "@factory/model/query-model-person";
import { DeletePersonModel } from "@factory/model/query-model-person";
import { CreatePersonAddressModel } from "@factory/model/query-model-person-address";
import { UpdatePersonAddressModel } from "@factory/model/query-model-person-address";
import { DeletePersonAddressModel } from "@factory/model/query-model-person-address";
import { CreateRoleModel } from "@factory/model/query-model-role";
import { UpdateRoleModel } from "@factory/model/query-model-role";
import { DeleteRoleModel } from "@factory/model/query-model-role";
// MAPPER QUERY MODEL IMPORT

import {
  LoginFormSchema,
  HouseholdFormSchema,
  MeetingFormSchema,
  AttendanceFormSchema,
  InhabitantFormSchema,
  PersonFormSchema,
  PersonAddressFormSchema,
  RoleFormSchema,
  // MAPPER IMPORT
} from "../form/form.rule";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useFormApiMapper() {
  const { formatDate } = useDateTimeContext();

  const mapLogin = (form: LoginFormSchema) => {
    const payload = {
      username: form.username,
      password: form.password,
    } as SigninAuthenticationModel;

    return payload;
  };

  const mapCreateHousehold = (form: HouseholdFormSchema) => {
    const payload = {
      number: form.number,
      name: form.name,
      street_name: form.streetName,
      subdivision: form.subdivision,
      zone: form.zone,
      sitio: form.sitio,
      purok: form.purok,
      barangay: form.barangay,
      municipality: form.municipality,
      province: form.province,
      region: form.region,
      country: form.country,
      longitude: form.longitude,
      latitude: form.latitude,
      avatar: form.avatar,
      allow_boarders: form.allowBoarders,
      has_boarding_permit: form.hasBoardingPermit,
      house_material: form.houseMaterial,
      rooms: form.rooms,
      toilets: form.toilets,
      has_backyard_garden: form.hasBackyardGarden,
      income_sources: form.incomeSources,
      pets: form.pets,
      two_wheel_vehicle: form.twoWheelVehicle,
      three_wheel_vehicle: form.threeWheelVehicle,
      four_wheel_vehicle: form.fourWheelVehicle,
    } as CreateHouseholdModel;

    const newPayload = {
      household: payload,
      file: form.photo,
    } as CreateHouseholdModelExtension;

    return newPayload;
  };
  const mapUpdateHousehold = (form: HouseholdFormSchema, code: string) => {
    const payload = {
      code,
      number: form.number,
      name: form.name,
      street_name: form.streetName,
      subdivision: form.subdivision,
      zone: form.zone,
      sitio: form.sitio,
      purok: form.purok,
      barangay: form.barangay,
      municipality: form.municipality,
      province: form.province,
      region: form.region,
      country: form.country,
      longitude: form.longitude,
      latitude: form.latitude,
      avatar: form.avatar,
      allow_boarders: form.allowBoarders || false,
      has_boarding_permit: form.hasBoardingPermit,
      house_material: form.houseMaterial,
      rooms: form.rooms,
      toilets: form.toilets,
      has_backyard_garden: form.hasBackyardGarden,
      income_sources: form.incomeSources,
      two_wheel_vehicle: form.twoWheelVehicle,
      three_wheel_vehicle: form.threeWheelVehicle,
      four_wheel_vehicle: form.fourWheelVehicle,
    } as UpdateHouseholdModel;

    const newPayload = {
      household: payload,
      file: form.photo,
    } as UpdateHouseholdModelExtension;

    return newPayload;
  };
  const mapDeleteHousehold = (code: string) => {
    const payload = {
      code,
    } as DeleteHouseholdModel;

    return payload;
  };
  const mapCreateMeeting = (form: MeetingFormSchema) => {
    const payload = {
      title: form.title,
      information: form.information,
      date: form.date,
      time: form.time,
      location: form.location,
    } as CreateMeetingModel;

    return payload;
  };
  const mapUpdateMeeting = (form: MeetingFormSchema, code: string) => {
    const payload = {
      code,
      title: form.title,
      information: form.information,
      date: form.date,
      time: form.time,
      location: form.location,
    } as UpdateMeetingModel;

    return payload;
  };
  const mapDeleteMeeting = (code: string) => {
    const payload = {
      code,
    } as DeleteMeetingModel;

    return payload;
  };
  const mapCreateAttendance = (form: AttendanceFormSchema) => {
    const payload = {
      household: form.household,
      inhabitant: form.inhabitant,
      meeting: form.meeting,
    } as CreateAttendanceModel;

    return payload;
  };
  const mapDeleteAttendance = (code: string) => {
    const payload = {
      code,
    } as DeleteAttendanceModel;
    return payload;
  };

  const mapCreateInhabitant = (form: InhabitantFormSchema) => {
    const payload = {
      household_code: form.householdCode,
      first_name: form.firstName,
      last_name: form.lastName,
      middle_name: form.middleName,
      avatar: form.avatar,
      role: form.role,
      gender: form.gender,
      civil_status: form.civilStatus,
      birthday: formatDate(form.birthday as Date, ""),
      email: form.email,
      mobile: form.mobile,
      phone: form.phone,
      is_verified: form.isVerified,
      is_deceased: form.isDeceased,
      is_voter: form.isVoter,
      is_representative: form.isRepresentative,
      note: form.note,
      occupation: form.occupation,
      occupation_place: form.occupationPlace,
      is_schooling: form.isSchooling,
      grade_level: form.gradeLevel,
      place_of_registration: form.placeOfRegistration,
      blood_type: form.bloodType,
      health_remarks: form.healthRemarks,
      is_pregnant: form.isPregnant,
      expected_labour_date: formatDate(form.expectedLabourDate as Date, ""),
      is_single_parent: form.isSingleParent,
      street_name: form.streetName,
      subdivision: form.subdivision,
      zone: form.zone,
      sitio: form.sitio,
      purok: form.purok,
      barangay: form.barangay,
      municipality: form.municipality,
      province: form.province,
      region: form.region,
      country: form.country,
      longitude: form.longitude,
      latitude: form.latitude,
    } as CreateInhabitantModel;

    const newPayload = {
      inhabitant: payload,
      file: form.photo,
    } as CreateInhabitantModelExtension;

    return newPayload;
  };
  const mapUpdateInhabitant = (form: InhabitantFormSchema, code: string) => {
    const payload = {
      code,
      household_code: form.householdCode,
      first_name: form.firstName,
      last_name: form.lastName,
      middle_name: form.middleName,
      avatar: form.avatar,
      role: form.role,
      gender: form.gender,
      civil_status: form.civilStatus,
      birthday: formatDate(form.birthday as Date, ""),
      email: form.email,
      mobile: form.mobile,
      phone: form.phone,
      is_verified: form.isVerified,
      is_deceased: form.isDeceased,
      is_voter: form.isVoter,
      is_representative: form.isRepresentative,
      note: form.note,
      occupation: form.occupation,
      occupation_place: form.occupationPlace,
      is_schooling: form.isSchooling,
      grade_level: form.gradeLevel,
      place_of_registration: form.placeOfRegistration,
      blood_type: form.bloodType,
      health_remarks: form.healthRemarks,
      is_pregnant: form.isPregnant,
      expected_labour_date: formatDate(form.expectedLabourDate as Date, ""),
      is_single_parent: form.isSingleParent,
      street_name: form.streetName,
      subdivision: form.subdivision,
      zone: form.zone,
      sitio: form.sitio,
      purok: form.purok,
      barangay: form.barangay,
      municipality: form.municipality,
      province: form.province,
      region: form.region,
      country: form.country,
      longitude: form.longitude,
      latitude: form.latitude,
    } as UpdateInhabitantModel;

    const newPayload = {
      inhabitant: payload,
      file: form.photo,
    } as UpdateInhabitantModelExtension;

    return newPayload;
  };
  const mapDeleteInhabitant = (code: string) => {
    const payload = {
      code,
    } as DeleteInhabitantModel;

    return payload;
  };
  const mapCreatePerson = (form: PersonFormSchema) => {
    const address = form.address
      ? mapCreatePersonAddress(form.address)
      : undefined;
    const payload = {
      role: form.role,
      first_name: form.firstName,
      middle_name: form.middleName,
      last_name: form.lastName,
      username: form.username,
      email: form.email,
      password: form.password,
      is_verified: form.isVerified,
      avatar: form.avatar,
      activation_code: form.activationCode,
      gender: form.gender,
      birthday: form.birthday,
      is_blocked: form.isBlocked,
      mobile_number: form.mobileNumber,
      father_name: form.fatherName,
      mother_name: form.motherName,
      emergency_contact_1_number: form.emergencyContact_1Number,
      emergency_contact_1_name: form.emergencyContact_1Name,
      emergency_contact_1_relationship: form.emergencyContact_1Relationship,
      emergency_contact_2_number: form.emergencyContact_2Number,
      emergency_contact_2_name: form.emergencyContact_2Name,
      emergency_contact_2_relationship: form.emergencyContact_2Relationship,
      sss: form.sss,
      philhealth: form.philhealth,
      pagibig: form.pagibig,
      tin: form.tin,
      medical_condition: form.medicalCondition,
      blood_type: form.bloodType,
      elementary: form.elementary,
      high_school: form.highSchool,
      college: form.college,
      post_college: form.postCollege,
      undergraduate: form.undergraduate,
      curriculum_vitae: form.curriculumVitae,
      supporting_doc: form.supportingDoc,
      intent_letter: form.intentLetter,
      application_letter: form.applicationLetter,
      address,
    } as CreatePersonModel;

    const newPayload = {
      person: payload,
      file: form.photo,
    } as CreatePersonModelExtension;

    return newPayload;
  };
  const mapUpdatePerson = (form: PersonFormSchema, code: string) => {
    const address = form.address
      ? mapUpdatePersonAddress(form.address, form.address.code || "")
      : undefined;
    const payload = {
      code,
      role: form.role,
      first_name: form.firstName,
      middle_name: form.middleName,
      last_name: form.lastName,
      username: form.username,
      email: form.email,
      password: form.password,
      is_verified: form.isVerified,
      avatar: form.avatar,
      activation_code: form.activationCode,
      gender: form.gender,
      birthday: form.birthday,
      is_blocked: form.isBlocked,
      mobile_number: form.mobileNumber,
      father_name: form.fatherName,
      mother_name: form.motherName,
      emergency_contact_1_number: form.emergencyContact_1Number,
      emergency_contact_1_name: form.emergencyContact_1Name,
      emergency_contact_1_relationship: form.emergencyContact_1Relationship,
      emergency_contact_2_number: form.emergencyContact_2Number,
      emergency_contact_2_name: form.emergencyContact_2Name,
      emergency_contact_2_relationship: form.emergencyContact_2Relationship,
      sss: form.sss,
      philhealth: form.philhealth,
      pagibig: form.pagibig,
      tin: form.tin,
      medical_condition: form.medicalCondition,
      blood_type: form.bloodType,
      elementary: form.elementary,
      high_school: form.highSchool,
      college: form.college,
      post_college: form.postCollege,
      undergraduate: form.undergraduate,
      curriculum_vitae: form.curriculumVitae,
      supporting_doc: form.supportingDoc,
      intent_letter: form.intentLetter,
      application_letter: form.applicationLetter,
      address,
    } as UpdatePersonModel;

    const newPayload = {
      person: payload,
      file: form.photo,
    } as UpdatePersonModelExtension;

    return newPayload;
  };
  const mapDeletePerson = (code: string) => {
    const payload = {
      code,
    } as DeletePersonModel;

    return payload;
  };
  const mapCreatePersonAddress = (form: PersonAddressFormSchema) => {
    const payload = {
      user_code: form.userCode,
      street_name: form.streetName,
      subdivision: form.subdivision,
      zone: form.zone,
      sitio: form.sitio,
      purok: form.purok,
      barangay: form.barangay,
      municipality: form.municipality,
      province: form.province,
      region: form.region,
      country: form.country,
      zip: form.zip,
      longitude: form.longitude,
      latitude: form.latitude,
    } as CreatePersonAddressModel;

    return payload;
  };
  const mapUpdatePersonAddress = (
    form: PersonAddressFormSchema,
    code: string
  ) => {
    const payload = {
      code,
      user_code: form.userCode,
      street_name: form.streetName,
      subdivision: form.subdivision,
      zone: form.zone,
      sitio: form.sitio,
      purok: form.purok,
      barangay: form.barangay,
      municipality: form.municipality,
      province: form.province,
      region: form.region,
      country: form.country,
      zip: form.zip,
      longitude: form.longitude,
      latitude: form.latitude,
    } as UpdatePersonAddressModel;

    return payload;
  };
  const mapDeletePersonAddress = (code: string) => {
    const payload = {
      code,
    } as DeletePersonAddressModel;

    return payload;
  };
  const mapCreateRole = (form: RoleFormSchema) => {
    const payload = {
      name: form.name,
      description: form.description,
    } as CreateRoleModel;

    return payload;
  };
  const mapUpdateRole = (form: RoleFormSchema, code: string) => {
    const payload = {
      code,
      name: form.name,
      description: form.description,
    } as UpdateRoleModel;

    return payload;
  };
  const mapDeleteRole = (code: string) => {
    const payload = {
      code,
    } as DeleteRoleModel;

    return payload;
  };
  // MAPPER FUNCTION

  return {
    mapLogin,
    mapCreateHousehold,
    mapUpdateHousehold,
    mapDeleteHousehold,
    mapCreateMeeting,
    mapUpdateMeeting,
    mapDeleteMeeting,
    mapCreateAttendance,
    mapDeleteAttendance,
    mapCreateInhabitant,
    mapUpdateInhabitant,
    mapDeleteInhabitant,
    mapCreatePerson,
    mapUpdatePerson,
    mapDeletePerson,
    mapCreatePersonAddress,
    mapUpdatePersonAddress,
    mapDeletePersonAddress,
    mapCreateRole,
    mapUpdateRole,
    mapDeleteRole,
    // MAPPER RETURN
  };
}
