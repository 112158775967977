import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import "./register-inhabitant-with-attendance.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { MessageResponseDto } from "@api/api";
import { useQyCreateInhabitant } from "@factory/query/inhabitant.query";
import {
  AttendanceFormSchema,
  InhabitantFormRule,
  InhabitantFormSchema,
} from "@factory/form/form.rule";
import { inhabitantFormDefault } from "@factory/form/form.default";
import { zodResolver } from "@hookform/resolvers/zod";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { FilterInhabitantProvider } from "@domain/inhabitant/filter-inhabitant/filter-inhabitant.context";
import { Button } from "primereact/button";
import { useNewAttendance } from "@domain/attendance/new-attendance/new-attendance.hook";
import FormInhabitantAttendance from "@domain/inhabitant/form-inhabitant/form-register-attendance";

export interface RegisterInhabitantWithAttendanceProps {}

export function RegisterInhabitantWithAttendance() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateInhabitant, mapCreateAttendance } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { meetingId } = useParams();
  const { addAttendance, isCreating: isCreatingAttendance } =
    useNewAttendance();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;
    const householdCode: string = data.householdCode;
    const inhabitantCode: string = data.code;

    const formData: AttendanceFormSchema = {
      household: householdCode,
      inhabitant: inhabitantCode,
      meeting: meetingId,
    };

    const attendanceData = mapCreateAttendance(formData);
    addAttendance(attendanceData, {
      onSuccess: () => {
        showSuccess(`Register inhabitant successfully.`);
        navigate(`/meeting/${meetingId}/scan`);
      },
      onError: (error) => {
        showError(`Failed to save attendance: ${error.message}`);
      },
    });
  };

  const { mutate: addInhabitant, isLoading: isCreatingInhabitant } =
    useQyCreateInhabitant(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<InhabitantFormSchema>({
    defaultValues: inhabitantFormDefault,
    resolver: zodResolver(InhabitantFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: InhabitantFormSchema) => {
    const formData = mapCreateInhabitant(form);
    addInhabitant(formData);
  };

  const handleValidateError = (err: FieldErrors<InhabitantFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  return (
    <div
      id="RegisterInhabitantWithAttendance"
      className="register-inhabitant-with-attendance"
    >
      <div className="button-container">
        <Button
          className="save-button"
          label="Save"
          disabled={isCreatingInhabitant || isCreatingAttendance}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        />
      </div>
      <div className="p-7 form-section">
        <FilterInhabitantProvider>
          <FormProvider {...formMethod}>
            <section className="grid grid-cols-1 gap-2 mb-4 bg-white">
              <FormInhabitantAttendance />
            </section>
          </FormProvider>
        </FilterInhabitantProvider>
      </div>
    </div>
  );
}

export default RegisterInhabitantWithAttendance;
