import "./new-attendance.scss";

import { FormProvider } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import HeaderContent from "@shared/ui/header-content/header-content";
import FormAttendance from "../form-attendance/form-attendance";
import { useNewAttendance } from "./new-attendance.hook";

export function NewAttendance() {
  const {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
  } = useNewAttendance();

  return (
    <div id="Attendance" className="attendance">
      <HeaderContent
        title="New Attendance"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 bg-white">
            <FormAttendance />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewAttendance;
