import './form-attendance.scss';
import { useFormContext } from "react-hook-form";
import { AttendanceFormSchema } from "@factory/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";

type InputFormSchema = AttendanceFormSchema;
export function FormAttendance() {
  const { control } = useFormContext<AttendanceFormSchema>();
  return (
    <div id="Attendance" className="attendance">
      <InputControl<InputFormSchema>
        control={control}
        name="household"
        label="Household"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter household"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="inhabitant"
        label="Inhabitant"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter inhabitant"
      />
      <InputControl<InputFormSchema>
        control={control}
        name="meeting"
        label="Meeting"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter meeting"
      />
    </div>
  );
}

export default FormAttendance;