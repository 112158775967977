import { ReactNode, createContext, useContext } from "react";
import { useFilterMeeting } from "./filter-meeting.hook";

const FilterMeetingContext = createContext(
  {} as ReturnType<typeof useFilterMeeting>
);
export function useFilterMeetingContext() {
  return useContext(FilterMeetingContext);
}

interface FilterMeetingProviderProps {
  children: ReactNode;
}
export function FilterMeetingProvider({
  children,
}: FilterMeetingProviderProps) {
  return (
    <FilterMeetingContext.Provider value={useFilterMeeting()}>
      {children}
    </FilterMeetingContext.Provider>
  );
}