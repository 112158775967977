import "./new-inhabitant.scss";
import { FormProvider } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormInhabitant from "../form-inhabitant/form-inhabitant";
import { useNewInhabitant } from "./new-inhabitant.hook";

export function NewInhabitant() {
  const {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
  } = useNewInhabitant();

  return (
    <div id="Inhabitant" className="inhabitant">
      <HeaderContent
        title="New Inhabitant"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 gap-2 mb-4 bg-white">
            <FormInhabitant />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewInhabitant;
