import { ReactNode, createContext, useContext } from "react";
import { useFilterHousehold } from "./filter-household.hook";

const FilterHouseholdContext = createContext(
  {} as ReturnType<typeof useFilterHousehold>
);
export function useFilterHouseholdContext() {
  return useContext(FilterHouseholdContext);
}

interface FilterHouseholdProviderProps {
  children: ReactNode;
}
export function FilterHouseholdProvider({
  children,
}: FilterHouseholdProviderProps) {
  return (
    <FilterHouseholdContext.Provider value={useFilterHousehold()}>
      {children}
    </FilterHouseholdContext.Provider>
  );
}