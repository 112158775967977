export const getColorForPercentage = (percentage: number) => {
  if (percentage <= 40) return "#ff4d4d";
  if (percentage <= 60) return "#ff5507";
  if (percentage <= 70) return "#ff7707";
  if (percentage <= 75) return "#ff9307";
  if (percentage <= 80) return "#ffc107";
  if (percentage <= 85) return "#f8c839";
  if (percentage <= 90) return "#f5d166";
  if (percentage <= 98) return "#9ac0a3";
  return "#7baa86";
};

export const calculatePercentage = (data: object, unwantedKeys: string[]) => {
  const fieldsToInclude = Object.keys(data).filter(
    (key) =>
      typeof data[key as keyof object] !== "boolean" &&
      !unwantedKeys.includes(key)
  );
  const totalFields = fieldsToInclude.length;

  const filledFields = fieldsToInclude.filter((key) => {
    const value = data[key as keyof object];
    return value !== null && value !== undefined && value !== "";
  }).length;
  const completionPercentage =
    totalFields > 0 ? (filledFields / totalFields) * 100 : 0;

  return Math.round(completionPercentage);
};
