import { useMutation, useQueryClient } from "react-query";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { AxiosError } from "axios";
import {
  MutateResponseAuthenticationModel,
  QueryKeyAuthentication,
  renewAuthenticationController,
  signinAuthenticationController,
  SigninAuthenticationModel,
} from "@factory/model/query-model-authentication";

export function useQyCreateAuthentication(
  onSuccess?:
    | ((data: MutateResponseAuthenticationModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (payload: SigninAuthenticationModel) => {
    showProgress();
    const operation = await signinAuthenticationController(payload);
    const response = (await operation()).data;
    return response as MutateResponseAuthenticationModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyAuthentication);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyRenewAuthentication(
  onSuccess?:
    | ((data: MutateResponseAuthenticationModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const queryClient = useQueryClient();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async () => {
    showProgress();
    const operation = await renewAuthenticationController();
    const response = (await operation()).data;
    return response as MutateResponseAuthenticationModel;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      queryClient.invalidateQueries(QueryKeyAuthentication);
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
