import "./barangay-official.scss";

/* eslint-disable-next-line */
export interface BarangayOfficialProps {}

export function BarangayOfficial() {
  return (
    <div className="barangay-official text-center custom-font">
      <p>~~~~~oOo~~~~~</p>

      <div className="official" contentEditable suppressContentEditableWarning>
        <p>HON. JUNE RAMON F. DUROY</p>
        <p>Punong Barangay</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>HON. ELMER D. TALDO</p>
        <p>Sanguniang Barangay Member</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>HON. RALPH L. CABAGNOT</p>
        <p>Sanguniang Barangay Member</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>HON. RITGIE FELIX G. VERANO</p>
        <p>Sanguniang Barangay Member</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>HON. TOM CESAR V. VERGARA</p>
        <p>Sanguniang Barangay Member</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>HON. MARC PAUL T. BOLANIO</p>
        <p>Sanguniang Barangay Member</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>HON. ROBERTO MIGUEL C. ALBA</p>
        <p>Sanguniang Barangay Member</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>HON. LEOBARDO E. VISARRA</p>
        <p>Sanguniang Barangay Member</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>HON. FRAZL ANNE J. EGAGAMAO</p>
        <p>SK Chairman</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>MR. CARLOS P. CAMACHO II</p>
        <p>Barangay Secretary</p>
      </div>
      <div className="official" contentEditable suppressContentEditableWarning>
        <p>MR. FLORIE LYNN V. VERGARA</p>
        <p>Barangay Treasurer</p>
      </div>

      <p>~~~~~oOo~~~~~</p>
    </div>
  );
}

export default BarangayOfficial;
