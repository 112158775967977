import {
  MessageResponseDto,
  GetPersonDto,
  PersonControllerGet200Response,
  PersonApiFp,
  DeletePersonDto,
  CreatePersonExtendedDto,
  EditPersonExtendedDto,
  CreatePersonAddressDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponsePersonModel = PersonControllerGet200Response;
export type GetPersonModel = GetPersonDto;
export type MutateResponsePersonModel = MessageResponseDto;
export type CreatePersonModel = CreatePersonExtendedDto;
export type UpdatePersonModel = EditPersonExtendedDto;
export type DeletePersonModel = DeletePersonDto;

export interface CreatePersonModelExtension {
  person: CreatePersonModel;
  file: File;
}

export interface UpdatePersonModelExtension {
  person: UpdatePersonModel;
  file: File;
}

export const apiPersonFp = PersonApiFp(apiConfig);
export const getPersonController = apiPersonFp.personControllerGet;
export const createPersonController = apiPersonFp.personControllerCreate;
export const updatePersonController = apiPersonFp.personControllerEdit;
export const deletePersonController = apiPersonFp.personControllerDelete;

export const QueryKeyPerson = QueryKey.Person;
