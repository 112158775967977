import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, useForm } from "react-hook-form";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { useQyCreateAttendance } from "@factory/query/attendance.query";
import {
  AttendanceFormRule,
  AttendanceFormSchema,
} from "@factory/form/form.rule";
import { attendanceFormDefault } from "@factory/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export function useNewAttendance() {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateAttendance } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New Attendance is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addAttendance, isLoading: isCreating } =
    useQyCreateAttendance(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<AttendanceFormSchema>({
    defaultValues: attendanceFormDefault,
    resolver: zodResolver(AttendanceFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: AttendanceFormSchema) => {
    const formData = mapCreateAttendance(form);
    addAttendance(formData);
  };
  const handleValidateError = (err: FieldErrors<AttendanceFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
    addAttendance,
  };
}
