export const familyRolesOption = [
  { label: "Father", value: "FATHER" },
  { label: "Mother", value: "MOTHER" },
  { label: "Son", value: "SON" },
  { label: "Daughter", value: "DAUGHTER" },
  { label: "Brother", value: "BROTHER" },
  { label: "Sister", value: "SISTER" },
  { label: "Grandfather", value: "GRANDFATHER" },
  { label: "Grandmother", value: "GRANDMOTHER" },
  { label: "Uncle", value: "UNCLE" },
  { label: "Aunt", value: "AUNT" },
  { label: "Cousin", value: "COUSIN" },
  { label: "Nephew", value: "NEPHEW" },
  { label: "Niece", value: "NIECE" },
  { label: "Husband", value: "HUSBAND" },
  { label: "Wife", value: "WIFE" },
  { label: "Stepfather", value: "STEPFATHER" },
  { label: "Stepmother", value: "STEPMOTHER" },
  { label: "Stepson", value: "STEPSON" },
  { label: "Stepdaughter", value: "STEPDAUGHTER" },
  { label: "Brother-in-law", value: "BROTHER_IN_LAW" },
  { label: "Sister-in-law", value: "SISTER_IN_LAW" },
  { label: "Father-in-law", value: "FATHER_IN_LAW" },
  { label: "Mother-in-law", value: "MOTHER_IN_LAW" },
  { label: "Son-in-law", value: "SON_IN_LAW" },
  { label: "Daughter-in-law", value: "DAUGHTER_IN_LAW" },
  { label: "Godfather", value: "GODFATHER" },
  { label: "Godmother", value: "GODMOTHER" },
  { label: "Godson", value: "GODSON" },
  { label: "Goddaughter", value: "GODDAUGHTER" },
  { label: "Guardian", value: "GUARDIAN" },
  { label: "Tenant", value: "TENANT" },
];
