import "./new-household.scss";
import { FormProvider } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";
import HeaderContent from "@shared/ui/header-content/header-content";
import FormHousehold from "../form-household/form-household";
import { useNewHousehold } from "./new-household.hook";

export function NewHousehold() {
  const {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
  } = useNewHousehold();

  return (
    <div id="Household" className="household">
      <HeaderContent
        title="New Household"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>
      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 gap-2 mb-4 bg-white">
            <FormHousehold />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewHousehold;
