import "./form-person-address.scss";
import { useFormContext } from "react-hook-form";
import { PersonAddressFormSchema } from "@factory/form/form.rule";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import { Divider } from "primereact/divider";
import { MapPosition } from "@shared/ui/gmap/map-position.interface";
import { useCallback } from "react";
import { appFeature } from "../../../app.feature";
import { MapWithMarker } from "@core/component/map-with-marker/map-with-marker";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { purokOption } from "@shared/data/purok";

const containerStyle = {
  width: "500px",
  height: "600px",
};
const mapCenter = appFeature.config.googleMap.mapView;
const getDefaultPosition = (lat?: number, lng?: number) => {
  if (!lat || !lng) {
    return mapCenter as MapPosition;
  }
  return { lat, lng };
};

type InputFormSchema = PersonAddressFormSchema;
export function FormPersonAddress() {
  const { control, getValues } = useFormContext<PersonAddressFormSchema>();
  const lat = getValues("latitude");
  const long = getValues("longitude");
  // const [mapPosition] = useState<MapPosition>(getDefaultPosition(lat, long));

  const getMapPosition = useCallback(() => {
    return getDefaultPosition(lat, long);
  }, [lat, long]);
  return (
    <div id="PersonAddress" className="person-address">
      <Divider />
      <div className="w-full grid grid-cols-1 md:grid-cols-2">
        <section>
          <h4>Address</h4>
          <InputControl<InputFormSchema>
            control={control}
            name="userCode"
            label="User Code"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter userCode"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="streetName"
            label="Street Name"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter streetName"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="subdivision"
            label="Subdivision"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter subdivision"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="zone"
            label="Zone"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter zone"
          />
          <InputControl<InputFormSchema>
            control={control}
            name="sitio"
            label="Sitio"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter sitio"
          />
          <DropdownControl<InputFormSchema>
            control={control}
            name="purok"
            label="Purok"
            className="w-full md:w-3/4"
            options={purokOption}
          />
          <InputControl<InputFormSchema>
            control={control}
            name="zip"
            label="Zip"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter zip"
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="longitude"
            label="Longitude"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter longitude"
          />
          <InputDigitControl<InputFormSchema>
            control={control}
            name="latitude"
            label="Latitude"
            className="w-full md:w-3/4"
            containerClassName="pb-2"
            placeholder="Enter latitude"
          />
        </section>
        <section>
          <MapWithMarker lat={lat} long={long} containerWidth="500px" />
        </section>
      </div>
    </div>
  );
}

export default FormPersonAddress;
