import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { MeetingFormRule, MeetingFormSchema } from "@factory/form/form.rule";
import { useFormDefaultMeeting } from "@factory/form/form-default-meeting.hook";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { ActionEnum } from "@factory/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateMeeting,
  useQyGetMeetingById,
  useQyDeleteMeeting,
} from "@factory/query/meeting.query";
import { GetResponseMeetingModel } from "@factory/model/query-model-meeting";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useEditMeetingMenu } from "./edit-meeting-menu";

export function useEditMeeting() {
  // LOCAL DECLARATION
  const { menu, activeIndex, setActiveIndex } = useEditMeetingMenu();
  const { getDefault } = useFormDefaultMeeting();
  const navigate = useNavigate();
  const { mapUpdateMeeting, mapDeleteMeeting } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { meetingId } = useParams();

  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Meeting
  const handleGetApiSuccess = (data: GetResponseMeetingModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("title", responseData?.title || "");
      setValue("information", responseData?.information || "");
      setValue("date", responseData?.date || "");
      setValue("time", responseData?.time || "");
      setValue("location", responseData?.location || "");
    }
  };
  const {
    data: meetingResponse,
    isLoading,
    isError: meetingError,
  } = useQyGetMeetingById(meetingId || "", handleGetApiSuccess);
  const meetingData = meetingResponse?.data?.[0];

  // API UPDATE Meeting
  const handleUpdateApiSuccess = () => {
    showSuccess("Meeting updated successfully");
  };
  const { mutate: updateMeeting, isLoading: isEditLoading } =
    useQyUpdateMeeting(handleUpdateApiSuccess);

  // API DELETE Meeting
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Meeting deleted successfully");
  };
  const { mutate: deleteMeeting } = useQyDeleteMeeting(handleDeleteApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<MeetingFormSchema>({
    defaultValues: getDefault(meetingData),
    resolver: zodResolver(MeetingFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: MeetingFormSchema) => {
    if (!meetingData) {
      throw new Error("No meeting data");
    }

    const formData = mapUpdateMeeting(form, meetingData.code || "");
    updateMeeting(formData);
  };
  const handleValidateError = (err: FieldErrors<MeetingFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!meetingData) {
      throw new Error("No meeting data found");
    }

    const form = mapDeleteMeeting(meetingData.code || "");
    deleteMeeting(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    meetingId,
    meetingData,
    isLoading,
    meetingError,
    formMethod,
    actions,
    isEditLoading,
    menu,
    activeIndex,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
