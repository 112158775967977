import {
  MessageResponseDto,
  GetHouseholdDto,
  HouseholdControllerGet200Response,
  HouseholdApiFp,
  DeleteHouseholdDto,
  CreateHouseholBasedDto,
  EditHouseholdBaseDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponseHouseholdModel = HouseholdControllerGet200Response;
export type GetHouseholdModel = GetHouseholdDto;
export type MutateResponseHouseholdModel = MessageResponseDto;
export type CreateHouseholdModel = CreateHouseholBasedDto;
export type UpdateHouseholdModel = EditHouseholdBaseDto;
export type DeleteHouseholdModel = DeleteHouseholdDto;

export interface CreateHouseholdModelExtension {
  household: CreateHouseholdModel;
  file: File;
}
export interface UpdateHouseholdModelExtension {
  household: EditHouseholdBaseDto;
  file: File;
}

export const apiHouseholdFp = HouseholdApiFp(apiConfig);
export const getHouseholdController = apiHouseholdFp.householdControllerGet;
export const createHouseholdController =
  apiHouseholdFp.householdControllerCreate;
export const updateHouseholdController = apiHouseholdFp.householdControllerEdit;
export const deleteHouseholdController =
  apiHouseholdFp.householdControllerDelete;

export const QueryKeyHousehold = QueryKey.Household;
