import { 
    MessageResponseDto, 
    GetPersonAddressDto, 
    CreatePersonAddressDto, 
    EditPersonAddressDto,
    PersonAddressControllerGet200Response,
    PersonAddressApiFp,
    DeletePersonAddressDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponsePersonAddressModel = PersonAddressControllerGet200Response;
export type GetPersonAddressModel = GetPersonAddressDto;
export type MutateResponsePersonAddressModel = MessageResponseDto;
export type CreatePersonAddressModel = CreatePersonAddressDto;
export type UpdatePersonAddressModel = EditPersonAddressDto;
export type DeletePersonAddressModel = DeletePersonAddressDto;

export const apiPersonAddressFp = PersonAddressApiFp(apiConfig);
export const getPersonAddressController = apiPersonAddressFp.personAddressControllerGet;
export const createPersonAddressController = apiPersonAddressFp.personAddressControllerCreate;
export const updatePersonAddressController = apiPersonAddressFp.personAddressControllerEdit;
export const deletePersonAddressController = apiPersonAddressFp.personAddressControllerDelete;

export const QueryKeyPersonAddress = QueryKey.PersonAddress;