import { ReactNode, createContext, useContext } from "react";
import { useFilterInhabitant } from "./filter-inhabitant.hook";

const FilterInhabitantContext = createContext(
  {} as ReturnType<typeof useFilterInhabitant>
);
export function useFilterInhabitantContext() {
  return useContext(FilterInhabitantContext);
}

interface FilterInhabitantProviderProps {
  children: ReactNode;
}
export function FilterInhabitantProvider({
  children,
}: FilterInhabitantProviderProps) {
  return (
    <FilterInhabitantContext.Provider value={useFilterInhabitant()}>
      {children}
    </FilterInhabitantContext.Provider>
  );
}