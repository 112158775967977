import { useState } from "react";
import "./dashboard-voter-card.scss";
import { DashboardObject } from "@core/model/dashboard-object";
import { LabelValue } from "@shared/models/label-value.interface";
import { useQyGetVoterDashboard } from "@factory/query/dashboard.query";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import { FaUserCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export function DashboardVoterCard() {
  const [nonVoterCount, setNonVoterCount] = useState<number>(0);
  const navigate = useNavigate();

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    setNonVoterCount(cleanedArray[1]?.value || 0);
  };

  const { isLoading, isError, error } = useQyGetVoterDashboard(
    true,
    handleSuccess
  );

  const handleCardClick = () => {
    navigate(
      "/inhabitant?filter=" +
        encodeURIComponent(JSON.stringify({ is_voter: "1" }))
    );
  };

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );

  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occurred" message={(error as any)?.message} />
    </div>
  );

  const voterCard = (
    <div className="dashboard-card-voter-card" onClick={handleCardClick}>
      <div className="info">
        <span>{nonVoterCount}</span>
        <h5>Voters</h5>
      </div>
      <FaUserCheck className="icon voter-icon" />
    </div>
  );

  return (
    <div>
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && <>{voterCard}</>}
    </div>
  );
}

export default DashboardVoterCard;
