import CertificateHeader from "@core/component/certificate/certificate-header/certificate-header";
import "./print-certificate-indigency.scss";
import { GetInhabitantModel } from "@factory/model/query-model-inhabitant";
import CertificateTitle from "@core/component/certificate/certificate-title/certificate-title";
import BarangayOfficial from "@core/component/certificate/barangay-official/barangay-official";
import PunongBarangay from "@core/component/certificate/punong-barangay/punong-barangay";
import AmountPaid from "@core/component/certificate/amount-paid/amount-paid";
import { calculateAge } from "@core/utility/calculate-age/calculate-age";

/* eslint-disable-next-line */
export interface PrintCertificateIndigencyProps {
  selectedInhabitant: Partial<GetInhabitantModel> | null;
}

export function PrintCertificateIndigency({
  selectedInhabitant,
}: PrintCertificateIndigencyProps) {
  const age = selectedInhabitant?.birthday
    ? calculateAge(selectedInhabitant.birthday)
    : "AGE";

  const inhabitantName = selectedInhabitant
    ? `${selectedInhabitant.first_name} ${selectedInhabitant.last_name}`
    : "____________";

  const inhabitantStatus = selectedInhabitant?.civil_status || "Single";
  const inhabitantStreetAddress = selectedInhabitant?.street_name || "STREET";
  return (
    <div id="PrintCertificateIndigency" className="print-certificate-indigency">
      <style type="text/css" media="print">
        {`
      @page { size: A4 portrait; }
    `}
      </style>
      <CertificateHeader />

      <section className="clearance-text">
        <CertificateTitle textTitle="CERTIFICATE OF INDIGENCY" />

        <div className="flex gap-2 ">
          <div className="w-1/4 border border-gray-600">
            <div className=" my-7">
              <h4 className="text-center font-bold">
                BARANGAY <br />
                OFFICIALS
              </h4>
            </div>
            <BarangayOfficial />
          </div>
          <div className="w-3/4 border border-gray-600">
            <div className="font-bold mt-24 text-start ">
              <h4 className="ml-2">TO WHOM IT MAY CONCERN:</h4>
            </div>
            <div className="mt-9 ml-2 ">
              <p className="indented-paragraph ml-8">
                THIS IS TO CERTIFY that{" "}
                <strong contentEditable suppressContentEditableWarning>
                  {inhabitantName.toLocaleUpperCase()}
                </strong>
                ,{" "}
                <span contentEditable suppressContentEditableWarning>
                  {age} years old, {inhabitantStatus}
                </span>
                , Filipino citizen, is a bonafide resident of{" "}
                <span contentEditable suppressContentEditableWarning>
                  {inhabitantStreetAddress}, POBLACION II, TAGBILARAN CITY
                </span>
              </p>
            </div>
            <div className="mt-9 ml-2">
              <p className="indented-paragraph mt-9 ml-8">
                This further certifies that the above-mentioned name belongs to
                the identified indigent families in the barangay.
              </p>
            </div>
            <div className="mt-9 ml-2">
              <p className="indented-paragraph ml-8">
                This certification is being issued upon the request of the party
                concerned for.
              </p>
            </div>

            <div className="mt-9 ml-2">
              <p className="indented-paragraph">
                Given this day{" "}
                <span contentEditable suppressContentEditableWarning>
                  th day of October, 2024 at Barangay Poblacion II, Tagbilaran
                  City, Bohol, Phillipines.
                </span>
              </p>
            </div>

            <PunongBarangay />
            <AmountPaid />
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrintCertificateIndigency;
