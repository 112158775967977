import Login from "@domain/login/login";
import MainShell from "@domain/shell/main-shell/main-shell";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthShell from "@domain/shell/auth-shell/auth-shell";
import { SessionProvider } from "@factory/authentication/session.context";
import Dashboard from "@domain/dashboard/dashboard";
import { UserIdentityProvider } from "@core/services/user-identity.context";
import { ProtectedRoute } from "@shared/ui/protected-route/protected-route";
import Household from "@domain/household/household";
import ListHousehold from "@domain/household/list-household/list-household";
import NewHousehold from "@domain/household/new-household/new-household";
import EditHousehold from "@domain/household/edit-household/edit-household";
import Meeting from "@domain/meeting/meeting";
import ListMeeting from "@domain/meeting/list-meeting/list-meeting";
import NewMeeting from "@domain/meeting/new-meeting/new-meeting";
import EditMeeting from "@domain/meeting/edit-meeting/edit-meeting";
import Attendance from "@domain/attendance/attendance";
import ListAttendance from "@domain/attendance/list-attendance/list-attendance";
import NewAttendance from "@domain/attendance/new-attendance/new-attendance";
import Inhabitant from "@domain/inhabitant/inhabitant";
import ListInhabitant from "@domain/inhabitant/list-inhabitant/list-inhabitant";
import NewInhabitant from "@domain/inhabitant/new-inhabitant/new-inhabitant";
import EditInhabitant from "@domain/inhabitant/edit-inhabitant/edit-inhabitant";
import FormHousehold from "@domain/household/form-household/form-household";
import FormMeeting from "@domain/meeting/form-meeting/form-meeting";
import ScanAttendance from "@domain/attendance/scan-attendance/scan-attendance";
import DashboardHousehold from "@domain/dashboard/dashboard-household/dashboard-household";
import RegisterInhabitantWithAttendance from "@domain/meeting/register-inhabitant-with-attendance/register-inhabitant-with-attendance";
import Person from "@domain/person/person";
import ListPerson from "@domain/person/list-person/list-person";
import NewPerson from "@domain/person/new-person/new-person";
import EditPerson from "@domain/person/edit-person/edit-person";
import PersonAddress from "@domain/person-address/person-address";
import ListPersonAddress from "@domain/person-address/list-person-address/list-person-address";
import NewPersonAddress from "@domain/person-address/new-person-address/new-person-address";
import EditPersonAddress from "@domain/person-address/edit-person-address/edit-person-address";
import Role from "@domain/role/role";
import ListRole from "@domain/role/list-role/list-role";
import NewRole from "@domain/role/new-role/new-role";
import EditRole from "@domain/role/edit-role/edit-role";
import Certificate from "@domain/certificate/certificate";
import BarangayClearance from "@domain/certificate/barangay-clearance/barangay-clearance";
import CertificateOptions from "@domain/certificate/certificate-option/certificate-option";
import CertificateResidency from "@domain/certificate/certificate-residency/certificate-residency";
import CertificateIndigency from "@domain/certificate/certificate-indigency/certificate-indigency";
import BarangayCertification from "@domain/certificate/barangay-certification/barangay-certification";

// ROUTE IMPORT

const inhabitantRoute = {
  path: "inhabitant",
  element: <Inhabitant />,
  children: [
    {
      path: "",
      element: <ListInhabitant />,
    },
    {
      path: "new",
      element: <NewInhabitant />,
    },
    {
      path: ":inhabitantId",
      element: <EditInhabitant />,
    },
  ],
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainShell />,
    children: [
      { path: "login", element: <Login /> },
      {
        path: "",
        element: (
          <UserIdentityProvider>
            <SessionProvider>
              <ProtectedRoute>
                <AuthShell />
              </ProtectedRoute>
            </SessionProvider>
          </UserIdentityProvider>
        ),
        children: [
          {
            path: "",
            element: <Dashboard />,
          },
          {
            path: "dashboard-household",
            element: <DashboardHousehold />,
          },
          {
            path: "household",
            element: <Household />,
            children: [
              {
                path: "",
                element: <ListHousehold />,
              },
              {
                path: "new",
                element: <NewHousehold />,
              },
              {
                path: ":householdId",
                element: <EditHousehold />,
                children: [
                  {
                    path: "",
                    element: <FormHousehold />,
                  },
                  inhabitantRoute,
                ],
              },
            ],
          },
          inhabitantRoute,
          {
            path: "meeting",
            element: <Meeting />,
            children: [
              {
                path: "",
                element: <ListMeeting />,
              },
              {
                path: "new",
                element: <NewMeeting />,
              },
              {
                path: ":meetingId",
                element: <EditMeeting />,
                children: [
                  {
                    path: "",
                    element: <FormMeeting />,
                  },
                  {
                    path: "register",
                    element: <RegisterInhabitantWithAttendance />,
                  },
                  {
                    path: "attendance",
                    element: <Attendance />,
                    children: [
                      {
                        path: "",
                        element: <ListAttendance />,
                      },
                      {
                        path: "new",
                        element: <NewAttendance />,
                      },
                    ],
                  },
                  {
                    path: "scan",
                    element: <ScanAttendance />,
                  },
                ],
              },
              {
                path: "attendance-list",
                element: <ListAttendance />,
              },
            ],
          },
          {
            path: "person",
            element: <Person />,
            children: [
              {
                path: "",
                element: <ListPerson />,
              },
              {
                path: "new",
                element: <NewPerson />,
              },
              {
                path: ":personId",
                element: <EditPerson />,
              },
            ],
          },
          {
            path: "person-address",
            element: <PersonAddress />,
            children: [
              {
                path: "",
                element: <ListPersonAddress />,
              },
              {
                path: "new",
                element: <NewPersonAddress />,
              },
              {
                path: ":personAddressId",
                element: <EditPersonAddress />,
              },
            ],
          },
          {
            path: "role",
            element: <Role />,
            children: [
              {
                path: "",
                element: <ListRole />,
              },
              {
                path: "new",
                element: <NewRole />,
              },
              {
                path: ":roleId",
                element: <EditRole />,
              },
            ],
          },
          {
            path: "certificate",
            element: <Certificate />,
            children: [
              {
                path: "",
                element: <CertificateOptions />,
              },
              {
                path: "barangay-clearance",
                element: <BarangayClearance />,
              },
              {
                path: "residency",
                element: <CertificateResidency />,
              },
              {
                path: "indigency",
                element: <CertificateIndigency />,
              },
              {
                path: "certification",
                element: <BarangayCertification />,
              },
            ],
          },
          // ROUTE ADD
        ],
      },
    ],
  },
]);
export function AppRoute() {
  return <RouterProvider router={router} />;
}
