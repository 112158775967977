export const educationLevels = [
  { label: "Preschool", value: "PRESCHOOL" },
  { label: "Kindergarten", value: "KINDERGARTEN" },
  { label: "Elementary School", value: "ELEMENTARY_SCHOOL" },
  { label: "Middle School", value: "MIDDLE_SCHOOL" },
  { label: "High School", value: "HIGH_SCHOOL" },
  { label: "High School Diploma", value: "HIGH_SCHOOL_DIPLOMA" },
  { label: "Vocational Training", value: "VOCATIONAL_TRAINING" },
  { label: "Associate's Degree", value: "ASSOCIATES_DEGREE" },
  { label: "Bachelor's Degree", value: "BACHELORS_DEGREE" },
  { label: "Master's Degree", value: "MASTERS_DEGREE" },
  { label: "Doctorate (PhD)", value: "DOCTORATE" },
  { label: "Professional Degree (e.g., MD, JD)", value: "PROFESSIONAL_DEGREE" },
  { label: "Postdoctoral Training", value: "POSTDOCTORAL_TRAINING" },
  { label: "Certificate Program", value: "CERTIFICATE_PROGRAM" },
  { label: "GED (General Education Diploma)", value: "GED" },
  { label: "Diploma", value: "DIPLOMA" },
  { label: "Some College (No Degree)", value: "SOME_COLLEGE" },
  { label: "Continuing Education", value: "CONTINUING_EDUCATION" },
  { label: "Adult Education", value: "ADULT_EDUCATION" },
  { label: "Home Schooling", value: "HOME_SCHOOLING" },
];
