import classNames from "classnames";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Chip, ChipRemoveEvent } from "primereact/chip";
import { LabelValue } from "@shared/models/label-value.interface";
import { isNil } from "lodash-es";

const defaultFilter = (household_code = "", filter: string | null) => {
  let defaultFilter = {} as Record<string, LabelValue>;
  const parsed = JSON.parse(filter as any) as Record<string, string>;

  if (household_code) {
    defaultFilter.household_code = {
      label: household_code,
      value: household_code,
      payload: {
        show: false,
      },
    };
  }
  if (!isNil(parsed)) {
    defaultFilter = Object.keys(parsed).reduce((acc, key) => {
      let label = parsed[key];
      if (key === "is_voter") {
        label = parsed[key] === "0" ? "false" : "true";
      }

      return {
        ...acc,
        [key]: {
          label,
          value: parsed[key],
          payload: { show: true },
        },
      };
    }, {});
  }

  return defaultFilter;
};

export function useFilterInhabitant() {
  // THIS IS THE LOCAL DECLARATION
  let [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get("filter");
  const { householdId } = useParams();
  const [filterEntity, setFilterEntity] = useState<Record<string, LabelValue>>(
    defaultFilter(householdId, filter)
  );

  // ADD/REPLACE DEFAULT FILTER STATE
  const [selectedDate, setSelectedDate] = useState("");

  const getFilterEntityValues = useCallback(() => {
    const transformed = LabelValue.TransformAsRecord(filterEntity);
    return transformed;
  }, [filterEntity]);

  const getFilterCount = () => {
    const values = Object.values(filterEntity).filter((x) => !!x);
    let filterEntityCount = values.length || 0;

    return filterEntityCount;
  };

  const applyFilter = (field: string, data: LabelValue) => {
    const filterVal = {
      ...filterEntity,
      [field]: data,
    } as Record<string, LabelValue>;
    setFilterEntity(filterVal);
  };

  const removeAllFilters = () => {
    searchParams.delete("filter");
    setSearchParams({});
    setFilterEntity({});
    setSelectedDate("");
  };

  const removeFilterBy = (filterName: string) => {
    const updatedFilterEntity = { ...filterEntity };
    delete updatedFilterEntity[filterName];
    setFilterEntity(updatedFilterEntity);
  };

  const handleRemoveChip = (e: ChipRemoveEvent) => {
    if (e.value.indexOf(":") < 0) {
      return;
    }

    const filterName = e.value.split(":")[0];
    removeFilterBy(filterName);

    searchParams.delete("filter");
    setSearchParams(searchParams);
  };
  const handleClear = (e: SyntheticEvent) => {
    e.preventDefault();
    removeAllFilters();
  };

  // THIS IS HTML
  const clearFiltersElement = (
    <Button
      label="Clear Filters"
      severity="secondary"
      className="block mt-4"
      outlined
      onClick={handleClear}
    />
  );
  const hasAppliedFilters =
    Object.values(filterEntity).filter((i) => !!i?.payload?.show).length > 0;
  const filterChipsElement = hasAppliedFilters ? (
    <section>
      <small>Applied Filters:</small>
      <div className="flex">
        {Object.keys(filterEntity).map((i) => {
          if (
            filterEntity[i] === ({} as LabelValue) ||
            filterEntity[i]?.payload?.show === false
          ) {
            return null;
          }

          return (
            <Chip
              key={i}
              label={`${i}: ${filterEntity[i].label}`}
              removable
              onRemove={handleRemoveChip}
            />
          );
        })}
      </div>
    </section>
  ) : null;

  // ADD / REPLACE FILTER ELEMENT
  const dateFilterElement = (
    <span className={classNames("flex flex-col-reverse gap-1")}>
      <InputText
        value={selectedDate}
        type="date"
        id="date"
        onInput={(e: any) => {
          setSelectedDate(e.target.value);
          applyFilter("date", e.target.value);
        }}
      />

      <label className="text-xs text-gray-500" htmlFor="date">
        Date
      </label>
    </span>
  );

  return {
    dateFilterElement,
    filterEntity,
    clearFiltersElement,
    filterChipsElement,
    removeAllFilters,
    getFilterCount,
    getFilterEntityValues,
  };
}
