import {
  MessageResponseDto,
  GetAttendanceDto,
  CreateAttendanceDto,
  AttendanceControllerGet200Response,
  AttendanceApiFp,
  DeleteAttendanceDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponseAttendanceModel = AttendanceControllerGet200Response;
export type GetAttendanceModel = GetAttendanceDto;
export type MutateResponseAttendanceModel = MessageResponseDto;
export type CreateAttendanceModel = CreateAttendanceDto;
export type DeleteAttendanceModel = DeleteAttendanceDto;

export const apiAttendanceFp = AttendanceApiFp(apiConfig);
export const getAttendanceController = apiAttendanceFp.attendanceControllerGet;
export const createAttendanceController =
  apiAttendanceFp.attendanceControllerCreate;
export const deleteAttendanceController =
  apiAttendanceFp.attendanceControllerDelete;

export const QueryKeyAttendance = QueryKey.Attendance;
