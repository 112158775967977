import { useMutation, useQuery } from "react-query";
import { authHeaders } from "../authentication/auth-header";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { AxiosError } from "axios";
import { useSessionContext } from "../authentication/session.context";
import {
  getAgeDashboardController,
  getDeceasedDashboardController,
  getGenderDashboardController,
  getHouseholdPurokDashboardController,
  getHouseholdsDashboardController,
  getInhabitantPurokDashboardController,
  getInhabitantsDashboardController,
  GetResponseDashboardModel,
  getVotersDashboardController,
  MutateResponseDashboardModel,
  QueryKeyDashboardAge,
  QueryKeyDashboardDeceased,
  QueryKeyDashboardGender,
  QueryKeyDashboardHousehold,
  QueryKeyDashboardHouseholdPurok,
  QueryKeyDashboardInhabitant,
  QueryKeyDashboardInhabitantPurok,
  QueryKeyDashboardVoter,
} from "@factory/model/query-model-dashboard";

export function useQyGetAgeDashboard(
  enabled?: boolean,
  onSuccess?:
    | ((data: MutateResponseDashboardModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async () => {
    await checkSession();

    showProgress();
    const operation = await getAgeDashboardController(authHeaders());
    const response = (await operation()).data;
    return response["data"] as GetResponseDashboardModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDashboardAge],
    queryFn: () => apiFn(),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetInhabitantPurokDashboard(
  enabled?: boolean,
  onSuccess?:
    | ((data: MutateResponseDashboardModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async () => {
    await checkSession();

    showProgress();
    const operation = await getInhabitantPurokDashboardController(
      authHeaders()
    );
    const response = (await operation()).data;
    return response["data"] as GetResponseDashboardModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDashboardInhabitantPurok],
    queryFn: () => apiFn(),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetHouseholdPurokDashboard(
  enabled?: boolean,
  onSuccess?:
    | ((data: MutateResponseDashboardModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async () => {
    await checkSession();

    showProgress();
    const operation = await getHouseholdPurokDashboardController(authHeaders());
    const response = (await operation()).data;
    return response["data"] as GetResponseDashboardModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDashboardHouseholdPurok],
    queryFn: () => apiFn(),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetGenderDashboard(
  enabled?: boolean,
  onSuccess?:
    | ((data: MutateResponseDashboardModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async () => {
    await checkSession();

    showProgress();
    const operation = await getGenderDashboardController(authHeaders());
    const response = (await operation()).data;
    return response["data"] as GetResponseDashboardModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDashboardGender],
    queryFn: () => apiFn(),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetVoterDashboard(
  enabled?: boolean,
  onSuccess?:
    | ((data: MutateResponseDashboardModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async () => {
    await checkSession();

    showProgress();
    const operation = await getVotersDashboardController(authHeaders());
    const response = (await operation()).data;
    return response["data"] as GetResponseDashboardModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDashboardVoter],
    queryFn: () => apiFn(),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

//new
export function useQyGetHouseholdDashboard(
  enabled?: boolean,
  onSuccess?:
    | ((data: MutateResponseDashboardModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async () => {
    await checkSession();

    showProgress();
    const operation = await getHouseholdsDashboardController(authHeaders());
    const response = (await operation()).data;
    return response["data"] as GetResponseDashboardModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDashboardHousehold],
    queryFn: () => apiFn(),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

//new
export function useQyGetInhabitantDashboard(
  enabled?: boolean,
  onSuccess?:
    | ((data: MutateResponseDashboardModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async () => {
    await checkSession();

    showProgress();
    const operation = await getInhabitantsDashboardController(authHeaders());
    const response = (await operation()).data;
    return response["data"] as GetResponseDashboardModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDashboardInhabitant],
    queryFn: () => apiFn(),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetDeceasedDashboard(
  enabled?: boolean,
  onSuccess?:
    | ((data: MutateResponseDashboardModel) => void | Promise<unknown>)
    | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async () => {
    await checkSession();

    showProgress();
    const operation = await getDeceasedDashboardController(authHeaders());
    const response = (await operation()).data;
    return response["data"] as GetResponseDashboardModel;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyDashboardDeceased],
    queryFn: () => apiFn(),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
