import { useCallback, useEffect, useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import "./scan-attendance.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useQyCreateAttendance } from "@factory/query/attendance.query";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { Button } from "primereact/button";

export interface ScanAttendanceProps {}

export function ScanAttendance() {
  let html5QrcodeScanner: any;

  const navigate = useNavigate();
  const [scanResult, setScanResult] = useState("");
  const { meetingId } = useParams();
  const { showSuccess, showWarning } = useNotificationContext();

  const handleSuccess = () => {
    const name = trimScan(scanResult);
    showSuccess(`Thank you ${name} for your attendance`);
  };
  const handleError = () => {
    navigate("../attendance");
  };
  const { mutate: attendance } = useQyCreateAttendance(
    handleSuccess,
    handleError
  );

  const handleRegister = () => {
    navigate(`../register`);
  };

  const trimScan = useCallback((result: string) => {
    const split = result.split(":");
    return split.length > 0 ? split[2] : "User";
  }, []);

  useEffect(() => {
    if (!html5QrcodeScanner?.getState()) {
      html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: { width: 550, height: 550 } },
        false
      );
      html5QrcodeScanner.render(success, undefined);
    }

    function success(result: any) {
      const split = result.split(":");
      const code = split[0];

      if (!code) {
        showWarning("Invalid code");
        navigate("../");
        return;
      }

      attendance({
        meeting: meetingId,
        inhabitant: code,
        arrival_time: new Date().toISOString(),
      });

      html5QrcodeScanner.clear();
      setScanResult(result);
    }
  }, []);

  const handleNavigate = () => {
    if (html5QrcodeScanner) {
      html5QrcodeScanner
        .clear()
        .then((x: any) => {})
        .catch((error: any) => {
          console.error("Failed to clear html5QrcodeScanner. ", error);
        });
    }
    navigate("../");
  };

  return (
    <div id="ScanAttendance" className="scan-attendance flex flex-col p-7">
      {scanResult ? (
        <section className="flex flex-col justify-center items-center">
          <h1>Thank you, {trimScan(scanResult)}, for scanning.</h1>
        </section>
      ) : (
        <div id="reader"></div>
      )}

      <Button
        className="mt-4"
        severity="secondary"
        label="Return"
        onClick={() => handleNavigate()}
        outlined
      />
      <Button label="Register" severity="secondary" onClick={handleRegister} />
    </div>
  );
}

export default ScanAttendance;
