import { format, isValid } from "date-fns";

interface useDateTimeProps {
  dateFormat?: string;
  dateTimeFormat?: string;
}
export function useDateTime({
  dateFormat = "yyyy-MM-dd",
  dateTimeFormat = "yyyy-MM-dd p",
}: useDateTimeProps) {
  const format12HourWithUTC = (date: string) => {
    if (!date || date === "") return "";

    const withUTC = `${date} UTC`;
    const newDateFormat = new Date(withUTC);

    const newDate = format(newDateFormat, "yyyy/MM/dd h:mm a");
    return newDate;
  };

  const formatDate = (
    date: Date | string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    let displayDate = defaultDisplay;
    if (typeof date === "string") {
      const dateIsValid = isValid(new Date(date));
      displayDate =
        date && dateIsValid
          ? format(new Date(date), dateFormat)
          : defaultDisplay;
    } else {
      displayDate = date ? format(date, dateFormat) : defaultDisplay;
    }

    return displayDate;
  };

  const formatDateTime = (
    date: Date | string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    let displayDate = defaultDisplay;
    if (typeof date === "string") {
      const dateIsValid = isValid(new Date(date));
      displayDate =
        date && dateIsValid
          ? format(new Date(date), dateTimeFormat)
          : defaultDisplay;
    } else {
      displayDate = date ? format(date, dateTimeFormat) : defaultDisplay;
    }

    return displayDate;
  };

  const getTime = (time: string) => {
    const dateOnly = new Date().toISOString().replace(/T.*$/, "");
    return `${dateOnly}T${time}:00`;
  };

  const formatTime = (
    date: Date | string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    const timeFormat = "h:mm a";
    let displayDate = defaultDisplay;
    if (typeof date === "string") {
      const dateIsValid = isValid(new Date(date));
      displayDate =
        date && dateIsValid
          ? format(new Date(date), timeFormat)
          : defaultDisplay;
    } else {
      displayDate = date ? format(date, timeFormat) : defaultDisplay;
    }

    return displayDate;
  };

  const formatDateTimeForInput = (
    date: Date | string | null | undefined,
    defaultDisplay: string = ""
  ) => {
    const inputFormat = "yyyy-MM-dd hh:mm";
    let displayDate = defaultDisplay;
    if (typeof date === "string") {
      const dateIsValid = isValid(new Date(date));
      displayDate =
        date && dateIsValid
          ? format(new Date(date), inputFormat)
          : defaultDisplay;
    } else {
      displayDate = date ? format(date, inputFormat) : defaultDisplay;
    }

    return displayDate;
  };

  return {
    dateFormat,
    dateTimeFormat,
    format12HourWithUTC,
    formatDate,
    formatDateTime,
    formatDateTimeForInput,
    formatTime,
    getTime
  };
}
