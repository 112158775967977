import "./certificate-title.scss";

/* eslint-disable-next-line */
export interface CertificateTitleProps {
  textTitle: string;
}

export function CertificateTitle({ textTitle }: CertificateTitleProps) {
  return <h2 className="title text-xl font-bold mt-7 mb-2">{textTitle}</h2>;
}

export default CertificateTitle;
