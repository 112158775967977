import React from "react";
import { useNavigate } from "react-router-dom";
import "./certificate-option.scss";

export interface CertificateOptionProps {}

export function CertificateOptions() {
  const navigate = useNavigate();

  const goToCertificate = (route: string) => {
    navigate(`./${route}`);
  };

  return (
    <div id="Certificate" className="certificate">
      <h1 className="certificate-title">Welcome to Certificate Center</h1>
      <div className="certificate-cards">
        <div
          className="certificate-card"
          onClick={() => goToCertificate("certification")}
        >
          <h2>Barangay Certification</h2>
          <p>General barangay certification for official use.</p>
        </div>
        <div
          className="certificate-card"
          onClick={() => goToCertificate("residency")}
        >
          <h2>Residency Certificate</h2>
          <p>Proof of residency for verification purposes.</p>
        </div>
        <div
          className="certificate-card"
          onClick={() => goToCertificate("indigency")}
        >
          <h2>Indigency Certificate</h2>
          <p>Certification for proof of indigency.</p>
        </div>
        <div
          className="certificate-card"
          onClick={() => goToCertificate("barangay-clearance")}
        >
          <h2>Barangay Clearance</h2>
          <p>Get official barangay clearance for various purposes.</p>
        </div>
      </div>
    </div>
  );
}

export default CertificateOptions;
