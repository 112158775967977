export const appConfig = {
  assetPath: `https://storage.googleapis.com/bukolabs_public/${process.env.REACT_APP_CLIENT}`,
  client: `${process.env.REACT_APP_CLIENT}`,
  apiUrl: `https://bukolabs.online/${process.env.REACT_APP_API_URL}`,
  dateFormat: "yyyy-MM-dd",
  dateTimeFormat: "yyyy-MM-dd p",
  staleTime: 20000, // 20 minutes
  minTimeForRenew: 250, // minimum time passed for the app to renew token
  maxTimeForTimeout: -180, // 3min - max time passed for the app to kick out user
};
