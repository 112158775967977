import "./certificate-header.scss";
import { appConfig } from "../../../../app.config";

export function CertificateHeader() {
  const leftlogo = `${
    appConfig.assetPath
  }/icon-512x512.png?${new Date().toISOString()}`;

  const rigntlogo = `${
    appConfig.assetPath
  }/print-1.jpg?${new Date().toISOString()}`;

  return (
    <section className="certificate-header mb-4 flex w-full justify-center gap-14 border-b border-gray-600">
      <img src={leftlogo} alt="Barangay Logo" className="w-28 h-28" />
      <div className="text-center">
        <p>Republic of the Philippines</p>
        <p>Province of Bohol</p>
        <p>City of Tagbilaran</p>
        <h4 className="text-md font-bold">BARANGAY POBLACION II</h4>
        <p>
          <i>(038) 411-2222 Loc#211</i>
        </p>
        <br />
        <h3 className="text-md">OFFICE OF THE PUNONG BARANGAY</h3>
      </div>
      <img src={rigntlogo} alt="Barangay Logo" className="w-28 h-28" />
    </section>
  );
}

export default CertificateHeader;
