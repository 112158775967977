import HeaderContent from "@shared/ui/header-content/header-content";
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import React, { useRef, useState, ReactNode } from "react";
import { useReactToPrint } from "react-to-print";
import { useQyGetInhabitant } from "@factory/query/inhabitant.query";
import SearchInput from "@shared/ui/search-input/search-input";
import { MdClear } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { GetInhabitantModel } from "@factory/model/query-model-inhabitant";

/* eslint-disable-next-line */
export interface CertificatePageProps {
  title: string;
  documentTitle: string;
  printComponent: ReactNode;
}

export function CertificatePage({
  title,
  documentTitle,
  printComponent,
}: CertificatePageProps) {
  // LOCAL DECLARATION
  const navigate = useNavigate();
  const componentRef = useRef<any>(null);
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: documentTitle,
    pageStyle: `
      @page {
        size: 8.5in 11in;
        margin: 0.5in;
      }
      body {
        font-family: Arial, sans-serif;
      }
    `,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedInhabitant, setSelectedInhabitant] =
    useState<Partial<GetInhabitantModel> | null>({
      first_name: "FIRSTNAME",
      last_name: "LASTNAME",
    });

  // QUERY
  const { data: inhabitantResponse } = useQyGetInhabitant(
    searchTerm || "",
    99,
    0,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    !!searchTerm
  );

  // LOCAL FUNCTION
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const handleSelectInhabitant = (inhabitant: GetInhabitantModel) => {
    setSelectedInhabitant(inhabitant);
    setSearchTerm("");
  };

  // HTML
  const filterElement = (
    <div className="search-section mb-4">
      <SearchInput
        searchTerm={searchTerm}
        onSearch={handleSearch}
        placeholder="Search inhabitant"
        clearIcon={<MdClear />}
        searchIcon={<CiSearch />}
        className="w-full"
      />

      {searchTerm &&
        inhabitantResponse &&
        inhabitantResponse.data &&
        inhabitantResponse?.data.length > 0 && (
          <div className="search-results">
            {inhabitantResponse.data.map((inhabitant) => (
              <div
                key={inhabitant.code}
                onClick={() => handleSelectInhabitant(inhabitant)}
                className="search-result-item"
              >
                {inhabitant.first_name} {inhabitant.last_name}
              </div>
            ))}
          </div>
        )}
    </div>
  );

  const printElement = (
    <div ref={componentRef}>
      {React.cloneElement(printComponent as React.ReactElement, {
        selectedInhabitant,
      })}
    </div>
  );

  return (
    <div
      id="CertificatePage"
      className="certificate-page bg-white p-6 w-full h-full"
    >
      <HeaderContent
        title={title}
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Print"
          onClick={() => handlePrint()}
        />
      </HeaderContent>

      <div className="p-7">
        {filterElement}
        {printElement}
      </div>
    </div>
  );
}

export default CertificatePage;
