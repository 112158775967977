import "./form-inhabitant.scss";
import { useFormContext } from "react-hook-form";
import { InhabitantFormSchema } from "@factory/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import CheckboxControl from "@shared/ui/hook-form/checkbox-control/checkbox-control";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";
import { useQyGetHousehold } from "@factory/query/household.query";
import { LabelValue } from "@shared/models/label-value.interface";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { useFilterInhabitantContext } from "../filter-inhabitant/filter-inhabitant.context";
import { useCallback } from "react";
import { sexOptions } from "@shared/data/sex";
import { civilStatusOptions } from "@shared/data/civil-status";
import { familyRolesOption } from "@shared/data/family-role";
import { educationLevels } from "@shared/data/education-level";
import InputTextareaControl from "@shared/ui/hook-form/input-textarea-control/input-textarea-control";
import { Divider } from "primereact/divider";
import { bloodTypesOption } from "@shared/data/blood-type";
import { TabPanel, TabView } from "primereact/tabview";
import InputDigitControl from "@shared/ui/hook-form/input-digit-control/input-digit-control";
import { purokOption } from "@shared/data/purok";
import { MapWithMarker } from "@core/component/map-with-marker/map-with-marker";

type InputFormSchema = InhabitantFormSchema;

export function FormInhabitant() {
  const { getFilterEntityValues } = useFilterInhabitantContext();
  const { household_code } = getFilterEntityValues();
  const { control, setValue, getValues } =
    useFormContext<InhabitantFormSchema>();
  const lat = getValues("latitude");
  const long = getValues("longitude");

  const { data: household } = useQyGetHousehold("", 99999);
  const householdData = household?.data || [];
  const options = LabelValue.MapArray(
    householdData,
    (label) => label.name || "-",
    (value) => value.code
  );

  const householdDropdownElement = useCallback(() => {
    if (household_code) {
      setValue("householdCode", household_code);
      return null;
    }

    return (
      <DropdownControl<InputFormSchema>
        control={control}
        name="householdCode"
        label="Household Name"
        className="w-full md:w-3/4"
        options={options}
      />
    );
  }, [household_code, options]);

  return (
    <div id="FormInhabitant" className="inhabitant">
      <TabView>
        <TabPanel header="Personal">
          <section>
            <ImageControl<InputFormSchema>
              control={control}
              name="photo"
              label="Inhabitant Photo"
              imagePreviewClass="w-32 h-32"
            />
          </section>

          <Divider />
          <div className="w-full grid grid-cols-1 md:grid-cols-3">
            <section>
              <h4>Personal</h4>
              {householdDropdownElement()}
              <InputControl<InputFormSchema>
                control={control}
                name="firstName"
                label="First Name"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="middleName"
                label="Middle Name"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="lastName"
                label="Last Name"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <DropdownControl<InputFormSchema>
                control={control}
                name="role"
                label="Household Role"
                className="w-full md:w-3/4"
                options={familyRolesOption}
              />
              <DropdownControl<InputFormSchema>
                control={control}
                name="gender"
                label="Sex"
                className="w-full md:w-3/4"
                options={sexOptions}
              />
              <DropdownControl<InputFormSchema>
                control={control}
                name="civilStatus"
                label="Civil Status"
                className="w-full md:w-3/4"
                options={civilStatusOptions}
              />
              <InputDateControl<InputFormSchema>
                control={control}
                name="birthday"
                label="Birthday"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="email"
                label="Email"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="mobile"
                label="Mobile"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="phone"
                label="Phone"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
            </section>

            <section>
              <h4>Education / Occupation / Health</h4>
              <InputControl<InputFormSchema>
                control={control}
                name="occupation"
                label="Occupation"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="occupationPlace"
                label="Occupation place"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <CheckboxControl<InputFormSchema>
                control={control}
                name="isSchooling"
                label="Are you a Student?"
                containerClassName="pb-2"
              />
              <DropdownControl<InputFormSchema>
                control={control}
                name="gradeLevel"
                label="Grade level"
                className="w-full md:w-3/4"
                options={educationLevels}
              />
              <DropdownControl<InputFormSchema>
                control={control}
                name="bloodType"
                label="Blood Type"
                className="w-full md:w-3/4"
                options={bloodTypesOption}
              />
              <InputTextareaControl<InputFormSchema>
                control={control}
                name="healthRemarks"
                label="Health Remarks"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <CheckboxControl<InputFormSchema>
                control={control}
                name="isPregnant"
                label="Are you Pregnant?"
                containerClassName="pb-2"
              />
              <InputDateControl<InputFormSchema>
                control={control}
                name="expectedLabourDate"
                label="Expected labour date"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <CheckboxControl<InputFormSchema>
                control={control}
                name="isSingleParent"
                label="Are you a single Parent?"
                containerClassName="pb-2"
              />
            </section>

            <section>
              <h4>General</h4>
              <CheckboxControl<InputFormSchema>
                control={control}
                name="isVerified"
                label="Verified"
                containerClassName="pb-2"
              />
              <CheckboxControl<InputFormSchema>
                control={control}
                name="isDeceased"
                label="Deceased"
                containerClassName="pb-2"
              />
              <CheckboxControl<InputFormSchema>
                control={control}
                name="isVoter"
                label="Voter"
                containerClassName="pb-2"
              />
              <CheckboxControl<InputFormSchema>
                control={control}
                name="isRepresentative"
                label="Representative"
                containerClassName="pb-2"
              />
              <InputTextareaControl<InputFormSchema>
                control={control}
                name="note"
                label="Note"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="placeOfRegistration"
                label="Place of Registration"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
                placeholder="Enter placeOfRegistration"
              />
            </section>
          </div>
        </TabPanel>
        <TabPanel header="Address">
          <div className="w-full grid grid-cols-1 md:grid-cols-2">
            <section>
              <InputControl<InputFormSchema>
                control={control}
                name="streetName"
                label="Street Name"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="subdivision"
                label="Subdivision"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="zone"
                label="Zone"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <InputControl<InputFormSchema>
                control={control}
                name="sitio"
                label="Sitio"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
              />
              <DropdownControl<InputFormSchema>
                control={control}
                name="purok"
                label="Purok"
                className="w-full md:w-3/4"
                options={purokOption}
              />
              <InputDigitControl<InputFormSchema>
                control={control}
                name="latitude"
                label="Latitude"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
                minFractionDigits={2}
                maxFractionDigits={50}
              />
              <InputDigitControl<InputFormSchema>
                control={control}
                name="longitude"
                label="Longitude"
                className="w-full md:w-3/4"
                containerClassName="pb-2"
                minFractionDigits={2}
                maxFractionDigits={50}
              />
            </section>
            <section>
              <MapWithMarker lat={lat} long={long} containerWidth="500px" />
            </section>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}

export default FormInhabitant;
