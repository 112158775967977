import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetPersonModel } from "@factory/model/query-model-person";
import { personFormDefault } from "./form.default";
import { PersonFormSchema } from "./form.rule";

export const useFormDefaultPerson = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetPersonModel | undefined) => {
    return !cachedValue
      ? personFormDefault
     : ({
        role: cachedValue.role,
        firstName: cachedValue.first_name,
        middleName: cachedValue.middle_name,
        lastName: cachedValue.last_name,
        username: cachedValue.username,
        email: cachedValue.email,
        password: cachedValue.password,
        isVerified: Boolean(cachedValue.is_verified),
        avatar: cachedValue.avatar,
        activationCode: cachedValue.activation_code,
        gender: cachedValue.gender,
        birthday: cachedValue.birthday,
        isBlocked: Boolean(cachedValue.is_blocked),
        mobileNumber: cachedValue.mobile_number,
        fatherName: cachedValue.father_name,
        motherName: cachedValue.mother_name,
        emergencyContact_1Number: cachedValue.emergency_contact_1_number,
        emergencyContact_1Name: cachedValue.emergency_contact_1_name,
        emergencyContact_1Relationship: cachedValue.emergency_contact_1_relationship,
        emergencyContact_2Number: cachedValue.emergency_contact_2_number,
        emergencyContact_2Name: cachedValue.emergency_contact_2_name,
        emergencyContact_2Relationship: cachedValue.emergency_contact_2_relationship,
        sss: cachedValue.sss,
        philhealth: cachedValue.philhealth,
        pagibig: cachedValue.pagibig,
        tin: cachedValue.tin,
        medicalCondition: cachedValue.medical_condition,
        bloodType: cachedValue.blood_type,
        elementary: cachedValue.elementary,
        highSchool: cachedValue.high_school,
        college: cachedValue.college,
        postCollege: cachedValue.post_college,
        undergraduate: cachedValue.undergraduate,
        curriculumVitae: cachedValue.curriculum_vitae,
        supportingDoc: cachedValue.supporting_doc,
        intentLetter: cachedValue.intent_letter,
        applicationLetter: cachedValue.application_letter,
      } as PersonFormSchema);
  };

  return {
    getDefault,
  };
};
