import { GetInhabitantModel } from "@factory/model/query-model-inhabitant";
import CertificatePage from "../certificate-page/certificate-page";
import PrintCertificateResidency from "../print-certificate-residency/print-certificate-residency";
import "./certificate-residency.scss";

/* eslint-disable-next-line */
export interface CertificateIndigencyProps {}

export function CertificateResidency(
  selectedInhabitant: Partial<GetInhabitantModel> | null
) {
  return (
    <CertificatePage
      title="Certificate of Residency"
      documentTitle="Certificate Residency"
      printComponent={
        <PrintCertificateResidency selectedInhabitant={selectedInhabitant} />
      }
    />
  );
}
export default CertificateResidency;
