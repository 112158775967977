import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetPersonAddressModel } from "@factory/model/query-model-person-address";
import { personAddressFormDefault } from "./form.default";
import { PersonAddressFormSchema } from "./form.rule";

export const useFormDefaultPersonAddress = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetPersonAddressModel | undefined) => {
    return !cachedValue
      ? personAddressFormDefault
     : ({
        userCode: cachedValue.user_code,
        streetName: cachedValue.street_name,
        subdivision: cachedValue.subdivision,
        zone: cachedValue.zone,
        sitio: cachedValue.sitio,
        purok: cachedValue.purok,
        barangay: cachedValue.barangay,
        municipality: cachedValue.municipality,
        province: cachedValue.province,
        region: cachedValue.region,
        country: cachedValue.country,
        zip: cachedValue.zip,
        longitude: cachedValue.longitude,
        latitude: cachedValue.latitude,
      } as PersonAddressFormSchema);
  };

  return {
    getDefault,
  };
};
