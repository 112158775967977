export const civilStatusOptions = [
  {
    label: "Married",
    value: "MARRIED",
  },
  {
    label: "Separated",
    value: "SEPARATED",
  },
  {
    label: "Divorced",
    value: "DIVORCED",
  },
  {
    label: "Widowed",
    value: "WIDOWED",
  },
  {
    label: "Single",
    value: "SINGLE",
  },
];
