import { Outlet } from "react-router-dom";

import { FilterPersonProvider } from "./filter-person/filter-person.context";
import './person.scss';

export function Person() {
  return (
    <div id="Person" className="person">
       <FilterPersonProvider>
        <Outlet />
      </FilterPersonProvider>
    </div>
  );
}

export default Person;
