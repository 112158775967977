import { useNotificationContext } from "@shared/ui/notification/notification.context";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import InputControl, {
  InputControlType,
} from "@shared/ui/hook-form/input-control/input-control";
import { generateCaptcha } from "@shared/utility/generate-captcha";
import StorageService from "@shared/services/storage.service";
import { FieldErrors, useForm } from "react-hook-form";
import { LoginFormSchema, LoginRule } from "@factory/form/form.rule";
import { loginFormDefault } from "@factory/form/form.default";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "primereact/button";
import BackgroundSlider from "react-background-slider";
import { getFormErrorMessage } from "@shared/utility/get-error-message";
import useScreenSize from "@shared/utility/screen-size";
import { SigninResponseAuthenticationModel } from "@factory/model/query-model-authentication";
import { ApiToFormService } from "@core/services/api-to-form.service";
import { LocalAuth } from "@core/model/local-auth";
import { AUTH } from "@core/utility/settings";
import { useQyCreateAuthentication } from "@factory/query/authentication.query";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { appConfig } from "../../app.config";
import { appFeature } from "../../app.feature";

export function Login() {
  const { mapLogin } = useFormApiMapper();
  const { isMobileMode } = useScreenSize();
  const { showError, showWarning } = useNotificationContext();
  const navigate = useNavigate();
  const logo = `${
    appConfig.assetPath
  }/icon-512x512.png?${new Date().toISOString()}`;
  const bukoLogo = "/buko-logo.png";
  const loginbg = `${
    appConfig.assetPath
  }/login-bg-1.jpg?${new Date().toISOString()}`;
  const loginbg2 = `${
    appConfig.assetPath
  }/login-bg-2.jpg?${new Date().toISOString()}`;
  const [passwordType, setPasswordType] =
    useState<InputControlType>("password");
  const [captcha] = useState<string>(generateCaptcha(6));
  const buildVersion = process.env.REACT_APP_BUILD_VERSION || "b000000.0";
  const semanticVersion = process.env.REACT_APP_SEMANTIC_VERSION || "";
  const version = `${buildVersion}-${semanticVersion}`;

  useEffect(() => {
    StorageService.clearAll();
  }, []);

  // LOGIN API
  const handleLoginSuccess = (response: SigninResponseAuthenticationModel) => {
    const mappedData = ApiToFormService.MapLocalAuth(response.data);
    StorageService.save<LocalAuth>(AUTH, mappedData);
    navigate("/");
  };
  const { mutate: loginUser } = useQyCreateAuthentication(handleLoginSuccess);

  // FORM
  const formMethod = useForm<LoginFormSchema>({
    defaultValues: loginFormDefault,
    resolver: zodResolver(LoginRule),
  });
  const { control, handleSubmit } = formMethod;
  const handleValidate = useCallback(
    (form: LoginFormSchema) => {
      const formData = mapLogin(form);
      loginUser(formData);
    },
    [loginUser, captcha, showWarning]
  );
  const handleValidateError = useCallback(
    (err: FieldErrors<LoginFormSchema>) => {
      const formMessage = getFormErrorMessage(err);
      showError(formMessage);
    },
    [showError]
  );
  const handleBukoLogo = () => {
    window.open("https://bukolabs.io/", "_blank", "noreferrer");
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(handleValidate, handleValidateError)();
    }
  };
  const handleRightIconAction = () => {
    const inputType = passwordType === "password" ? "text" : "password";
    setPasswordType(inputType);
  };
  const handleHome = () => {
    navigate("/");
  };

  return (
    <div id="Login" className="login">
      {!isMobileMode ? (
        <BackgroundSlider
          images={[loginbg, loginbg2]}
          duration={10}
          transition={2}
        />
      ) : null}
      <section className="flex flex-col h-screen justify-center items-center">
        <div className="p-0 md:p-16 bg-white opacity-95 rounded-lg">
          <div className="flex flex-col w-full">
            <div className="flex flex-col items-center mb-20">
              <img
                src={logo}
                className="w-[300px]"
                alt={`${appFeature.company}`}
              />
            </div>
            <h1 className="m-0 mt-4">Login</h1>
            <span className="text-gray-600">
              Welcome to {appFeature.appName}
            </span>
            <InputControl<LoginFormSchema>
              control={control}
              name="username"
              label="Username"
              className="w-full"
              onKeyDown={handleKeyDown}
            />
            <InputControl<LoginFormSchema>
              control={control}
              name="password"
              label="Password"
              className="w-full"
              type={passwordType}
              onKeyDown={handleKeyDown}
            />

            <div className="flex flex-col mt-5 gap-2">
              <Button
                label="Login"
                onClick={handleSubmit(handleValidate, handleValidateError)}
              />
              <Button
                label="Home"
                severity="secondary"
                outlined
                onClick={handleHome}
              />
            </div>
          </div>
          <div
            className="flex flex-col w-full md:w-[400px] items-center mt-4"
            onClick={handleBukoLogo}
          >
            <p className="m-0 font-bold">Powered by:</p>
            <img
              src={bukoLogo}
              className="w-[250px] cursor-pointer"
              alt="buko-labs"
            />
          </div>
          <span className="text-center fixed bottom-0 right-0 text-gray-300">
            {version}
          </span>
        </div>
      </section>
    </div>
  );
}

export default Login;
