import { Outlet } from "react-router-dom";

import { FilterMeetingProvider } from "./filter-meeting/filter-meeting.context";
import "./meeting.scss";

export function Meeting() {
  return (
    <div id="Meeting" className="meeting">
      <FilterMeetingProvider>
        <Outlet />
      </FilterMeetingProvider>
    </div>
  );
}

export default Meeting;
