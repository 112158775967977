import "./edit-person.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormPerson from "../form-person/form-person";
import { useEditPerson } from "./edit-person.hook";

export function EditPerson() {
  const {
    personData,
    isLoading,
    personError,
    formMethod,
    actions,
    handleBack,
    handleAction,
  } = useEditPerson();

  const displayLoading = (
    <div className="card-loading">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 gap-2 mb-4 bg-white">
        <FormPerson />
      </section>
    </div>
  );

  return (
    <div id="Person" className="person">
      <HeaderContent
        title={
          personData?.first_name + " " + personData?.last_name || "Edit Person"
        } //from name- role
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <ConfirmDialog />
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {personError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EditPerson;
