import { useQyGetAgeDashboard } from "@factory/query/dashboard.query";
import "./dashboard-age.scss";
import { LabelValue } from "@shared/models/label-value.interface";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import { Chart } from "primereact/chart";
import ErrorSection from "@shared/ui/error-section/error-section";
import { useRef, useState } from "react";
import { DashboardObject } from "@core/model/dashboard-object";
import { useNavigate } from "react-router-dom";
import { handleChartClick } from "@core/utility/handle-chart-click";

export function DashboardAge() {
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({ labels: [], datasets: [] });
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef<any>(null);
  const navigate = useNavigate();

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    const bgColor = [
      "#e0950a",
      "#ec27ec",
      "#1d29ce",
      "#20c429",
      "#265330",
      "#183b20",
    ];
    const bgHoverColor = [
      "#b4790b",
      "#911091",
      "#0e168a",
      "#157a1a",
      "#265330",
      "#102916",
    ];

    const model = {
      labels: cleanedArray.map((item) => item.label),
      datasets: [
        {
          data: cleanedArray.map((item) => item.value),
          backgroundColor: bgColor,
          hoverBackgroundColor: bgHoverColor,
        },
      ],
    };
    const options = {
      cutout: "60%",
      responsive: true,
      maintainAspectRatio: false,
    };

    setChartData(model);
    setChartOptions(options);
  };
  const { isLoading, isError, error } = useQyGetAgeDashboard(
    true,
    handleSuccess
  );

  const filterValues = {
    INFANT: JSON.stringify({ age_range: "INFANT" }),
    KID: JSON.stringify({ age_range: "KID" }),
    TEEN: JSON.stringify({ age_range: "TEEN" }),
    ADULT: JSON.stringify({ age_range: "ADULT" }),
    SENIOR: JSON.stringify({ age_range: "SENIOR" }),
    UNASSIGNED: JSON.stringify({ age_range: "UNASSIGNED" }),
  };

  const handleAGeRangeChartClick = (event: React.MouseEvent) => {
    handleChartClick(
      event,
      chartRef,
      chartData,
      navigate,
      "is_voter",
      filterValues,
      "inhabitant"
    );
  };

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occurred" message={(error as any)?.message} />
    </div>
  );
  const chart = (
    <div className="dashboard-age-chart">
      <h5 className="text-gray-500">Age Bracket</h5>
      <div className="chart-container">
        <Chart
          type="doughnut"
          data={chartData}
          options={chartOptions}
          className="w-full h-full"
          onClick={handleAGeRangeChartClick}
          ref={chartRef}
        />
      </div>
    </div>
  );

  return (
    <div id="DashboardAge" className="dashboard-age">
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && chart}
    </div>
  );
}

export default DashboardAge;
