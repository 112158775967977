import "./form-person.scss";
import { useFormContext } from "react-hook-form";
import { PersonFormSchema } from "@factory/form/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import { TabPanel, TabView } from "primereact/tabview";
import FormPersonAddress from "@domain/person-address/form-person-address/form-person-address";
import ImageControl from "@shared/ui/hook-form/image-control/image-control";
import { Divider } from "primereact/divider";
import DropdownControl from "@shared/ui/hook-form/dropdown-control/dropdown-control";
import { sexOptions } from "@shared/data/sex";
import InputDateControl from "@shared/ui/hook-form/input-date-control/input-date-control";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useQyGetRole } from "@factory/query/role.query";
import { LabelValue } from "@shared/models/label-value.interface";

type InputFormSchema = PersonFormSchema;
export function FormPerson() {
  const { control } = useFormContext<PersonFormSchema>();

  const { data: roleResponse } = useQyGetRole("");
  const mappedRole = (roleResponse?.data || []).map(
    (item) =>
      ({
        label: item.name,
        value: item.code,
      } as LabelValue)
  );

  return (
    <div id="FormInhabitant" className="inhabitant">
      <TabView>
        <TabPanel header="Personal">
          <section>
            <ImageControl<InputFormSchema>
              control={control}
              name="photo"
              label="Person Photo"
              imagePreviewClass="w-32 h-32"
            />
          </section>

          <Divider />
          <div className="w-full grid grid-cols-1">
            <Accordion activeIndex={0}>
              <AccordionTab header="Personal">
                <section>
                  <InputControl<InputFormSchema>
                    control={control}
                    name="firstName"
                    label="First Name"
                    className="w-full md:w-3/4"
                    containerClassName="pb-2"
                    placeholder="Enter firstName"
                  />
                  <InputControl<InputFormSchema>
                    control={control}
                    name="middleName"
                    label="Middle Name"
                    className="w-full md:w-3/4"
                    containerClassName="pb-2"
                    placeholder="Enter middleName"
                  />
                  <InputControl<InputFormSchema>
                    control={control}
                    name="lastName"
                    label="Last Name"
                    className="w-full md:w-3/4"
                    containerClassName="pb-2"
                    placeholder="Enter lastName"
                  />
                  <DropdownControl<InputFormSchema>
                    control={control}
                    name="gender"
                    label="Gender"
                    className="w-full md:w-3/4"
                    options={sexOptions}
                  />
                  <InputDateControl<InputFormSchema>
                    control={control}
                    name="birthday"
                    label="Birthday"
                    className="w-full md:w-3/4"
                    containerClassName="pb-2"
                  />
                  <InputControl<InputFormSchema>
                    control={control}
                    name="mobileNumber"
                    label="Mobile Number"
                    className="w-full md:w-3/4"
                    containerClassName="pb-2"
                    placeholder="Enter mobileNumber"
                  />

                  <section>
                    <DropdownControl<InputFormSchema>
                      control={control}
                      name="role"
                      label="Role"
                      options={mappedRole}
                      className="w-full md:w-3/4"
                      containerClassName="pb-2"
                      placeholder="Enter role"
                    />

                    <InputControl<InputFormSchema>
                      control={control}
                      name="username"
                      label="Username"
                      className="w-full md:w-3/4"
                      containerClassName="pb-2"
                      placeholder="Enter username"
                    />
                    <InputControl<InputFormSchema>
                      control={control}
                      name="email"
                      label="Email"
                      className="w-full md:w-3/4"
                      containerClassName="pb-2"
                      placeholder="Enter email"
                    />
                    <InputControl<InputFormSchema>
                      control={control}
                      name="password"
                      label="Password"
                      className="w-full md:w-3/4"
                      containerClassName="pb-2"
                      placeholder="Enter password"
                    />
                  </section>
                </section>
              </AccordionTab>
              <AccordionTab header="Government">
                <h4>Government IDs</h4>
                <InputControl<InputFormSchema>
                  control={control}
                  name="sss"
                  label="Sss"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter sss"
                />
                <InputControl<InputFormSchema>
                  control={control}
                  name="philhealth"
                  label="Philhealth"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter philhealth"
                />
                <InputControl<InputFormSchema>
                  control={control}
                  name="pagibig"
                  label="Pagibig"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter pagibig"
                />
                <InputControl<InputFormSchema>
                  control={control}
                  name="tin"
                  label="Tin"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter tin"
                />
              </AccordionTab>
              <AccordionTab header="Emergency Contacts">
                <InputControl<InputFormSchema>
                  control={control}
                  name="emergencyContact_1Number"
                  label="Emergency Contact 1 Number"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter emergencyContact_1Number"
                />
                <InputControl<InputFormSchema>
                  control={control}
                  name="emergencyContact_1Name"
                  label="Emergency Contact 1 Name"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter emergencyContact_1Name"
                />
                <InputControl<InputFormSchema>
                  control={control}
                  name="emergencyContact_1Relationship"
                  label="Emergency Contact 1 Relationship"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter emergencyContact_1Relationship"
                />
                <InputControl<InputFormSchema>
                  control={control}
                  name="emergencyContact_2Number"
                  label="Emergency Contact 2 Number"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter emergencyContact_2Number"
                />
                <InputControl<InputFormSchema>
                  control={control}
                  name="emergencyContact_2Name"
                  label="Emergency Contact 2 Name"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter emergencyContact_2Name"
                />
                <InputControl<InputFormSchema>
                  control={control}
                  name="emergencyContact_2Relationship"
                  label="Emergency Contact 2 Relationship"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter emergencyContact_2Relationship"
                />
              </AccordionTab>
              <AccordionTab header="Others">
                <h4>Family</h4>
                <InputControl<InputFormSchema>
                  control={control}
                  name="fatherName"
                  label="Father Name"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter fatherName"
                />
                <InputControl<InputFormSchema>
                  control={control}
                  name="motherName"
                  label="Mother Name"
                  className="w-full md:w-3/4"
                  containerClassName="pb-2"
                  placeholder="Enter motherName"
                />
              </AccordionTab>
            </Accordion>
          </div>
        </TabPanel>
        <TabPanel header="Address">
          <FormPersonAddress />
        </TabPanel>
      </TabView>
    </div>
  );
}

export default FormPerson;
