import { GetInhabitantModel } from "@factory/model/query-model-inhabitant";
import CertificatePage from "../certificate-page/certificate-page";
import "./certificate-indigency.scss";
import PrintCertificateIndigency from "../print-certificate-indigency/print-certificate-indigency";

/* eslint-disable-next-line */
export interface CertificateIndigencyProps {}

export function CertificateIndigency(
  selectedInhabitant: Partial<GetInhabitantModel> | null
) {
  return (
    <CertificatePage
      title="Certificate of Indigency"
      documentTitle="Certificate Indigency"
      printComponent={
        <PrintCertificateIndigency selectedInhabitant={selectedInhabitant} />
      }
    />
  );
}
export default CertificateIndigency;
