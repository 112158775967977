import { Outlet } from "react-router-dom";
import "./main-shell.scss";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { useInterceptor } from "@core/services/interceptor.hook";
import { appConfig } from "../../../app.config";
import { useEffect } from "react";
import { appFeature } from "../../../app.feature";

export function MainShell() {
  const { progress, toastRef } = useNotificationContext();
  useInterceptor();
  const manifestUrl = `${appConfig.assetPath}/manifest.json`;

  useEffect(() => {
    const faviconUrl = `${appConfig.assetPath}/favicon.ico`;
    const title = `${appFeature.appName}`;

    const link: HTMLLinkElement | null = document.querySelector("#favicon");
    const manifest: HTMLLinkElement | null =
      document.querySelector("#manifest");

    if (link) {
      link.href = faviconUrl;
    }
    if (title) {
      document.title = title || "Bukolabs System";
    }

    if (manifest) {
      manifest.href = manifestUrl;
    }
  }, []);

  return (
    <div id="MainShell" className="main-shell">
      <Toast ref={toastRef} position="bottom-left" />
      {progress?.show && (
        <ProgressBar
          mode="indeterminate"
          style={{
            height: "6px",
            zIndex: 999999,
            position: "fixed",
            width: "100%",
          }}
        ></ProgressBar>
      )}

      <Outlet />
    </div>
  );
}

export default MainShell;
