import "./edit-household.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";
import { useEditHousehold } from "./edit-household.hook";
import { useEditHouseholdMenu } from "./edit-household-menu";
import { Outlet } from "react-router-dom";
import { TabMenu } from "primereact/tabmenu";

export function EditHousehold() {
  const {
    householdData,
    isLoading,
    householdError,
    formMethod,
    actions,
    handleBack,
    handleAction,
  } = useEditHousehold();
  const { menu, activeIndex } = useEditHouseholdMenu();

  const displayLoading = (
    <div className="card-loading">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 bg-white">
        <TabMenu model={menu} activeIndex={activeIndex} />
        <Outlet />
      </section>
    </div>
  );

  return (
    <div id="Household" className="household">
      <HeaderContent
        title={householdData?.name || "Edit Household"}
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>

      <section className="p-7">
        <ConfirmDialog />
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {householdError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EditHousehold;
