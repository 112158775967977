// components/shared/MapWithMarker.tsx
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { MapPosition } from "@shared/ui/gmap/map-position.interface";
import { Gmap } from "@shared/ui/gmap/gmap";
import { appFeature } from "../../../app.feature";

const mapCenter = appFeature.config.googleMap.mapView;

const getDefaultPosition = (lat?: number, lng?: number): MapPosition => {
  if (!lat || !lng) {
    return mapCenter as MapPosition;
  }
  return { lat, lng };
};

interface MapWithMarkerProps {
  lat?: number;
  long?: number;
  containerWidth?: string;
  containerHeight?: string;
}

export const MapWithMarker = ({
  lat,
  long,
  containerWidth,
  containerHeight,
}: MapWithMarkerProps) => {
  const mapPosition = getDefaultPosition(lat, long);

  return (
    <Gmap>
      <GoogleMap
        mapContainerStyle={{
          width: containerWidth || "500px",
          height: containerHeight || "600px",
        }}
        center={mapCenter}
        zoom={15}
      >
        <MarkerF position={mapPosition} />
      </GoogleMap>
    </Gmap>
  );
};
