import { Outlet } from "react-router-dom";

import { FilterAttendanceProvider } from "./filter-attendance/filter-attendance.context";
import './attendance.scss';

export function Attendance() {
  return (
    <div id="Attendance" className="attendance">
       <FilterAttendanceProvider>
        <Outlet />
      </FilterAttendanceProvider>
    </div>
  );
}

export default Attendance;
