import { 
    MessageResponseDto, 
    GetMeetingDto, 
    CreateMeetingDto, 
    EditMeetingDto,
    MeetingControllerGet200Response,
    MeetingApiFp,
    DeleteMeetingDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponseMeetingModel = MeetingControllerGet200Response;
export type GetMeetingModel = GetMeetingDto;
export type MutateResponseMeetingModel = MessageResponseDto;
export type CreateMeetingModel = CreateMeetingDto;
export type UpdateMeetingModel = EditMeetingDto;
export type DeleteMeetingModel = DeleteMeetingDto;

export const apiMeetingFp = MeetingApiFp(apiConfig);
export const getMeetingController = apiMeetingFp.meetingControllerGet;
export const createMeetingController = apiMeetingFp.meetingControllerCreate;
export const updateMeetingController = apiMeetingFp.meetingControllerEdit;
export const deleteMeetingController = apiMeetingFp.meetingControllerDelete;

export const QueryKeyMeeting = QueryKey.Meeting;