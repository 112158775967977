import React from "react";
import "./punong-barangay.scss";

interface PunongBarangayProps {
  marginTop?: string;
}

export function PunongBarangay({ marginTop = "8rem" }: PunongBarangayProps) {
  return (
    <div
      className="punong-barangay mr-10 justify-items-end"
      style={{ marginTop }}
    >
      <p
        className="font-bold border-b border-black text-center"
        contentEditable
        suppressContentEditableWarning
      >
        JUNE RAMON F. DUROY
      </p>
      <p className="mr-7">Punong Barangay</p>
    </div>
  );
}

export default PunongBarangay;
