import { Outlet } from "react-router-dom";

import "./certificate.scss";

export function Certificate() {
  return (
    <div id="Certificate" className="certificate">
      <Outlet />
    </div>
  );
}

export default Certificate;
