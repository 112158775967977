import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import { PersonFormRule, PersonFormSchema } from "@factory/form/form.rule";
import { useFormDefaultPerson } from "@factory/form/form-default-person.hook";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { ActionEnum } from "@factory/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { 
  useQyUpdatePerson, 
  useQyGetPersonById, 
  useQyDeletePerson 
} from "@factory/query/person.query";
import { GetResponsePersonModel } from "@factory/model/query-model-person";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export function useEditPerson() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultPerson();
  const navigate = useNavigate();
  const { mapUpdatePerson, mapDeletePerson } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { personId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Person
  const handleGetApiSuccess = (data: GetResponsePersonModel) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("role", responseData?.role || "");
      setValue("firstName", responseData?.first_name || "");
      setValue("middleName", responseData?.middle_name || "");
      setValue("lastName", responseData?.last_name || "");
      setValue("username", responseData?.username || "");
      setValue("email", responseData?.email || "");
      setValue("password", responseData?.password || "");
      setValue("isVerified", Boolean(responseData?.is_verified));
      setValue("avatar", responseData?.avatar || "");
      setValue("activationCode", responseData?.activation_code || "");
      setValue("gender", responseData?.gender || "");
      setValue("birthday", responseData?.birthday || "");
      setValue("isBlocked", Boolean(responseData?.is_blocked));
      setValue("mobileNumber", responseData?.mobile_number || "");
      setValue("fatherName", responseData?.father_name || "");
      setValue("motherName", responseData?.mother_name || "");
      setValue("emergencyContact_1Number", responseData?.emergency_contact_1_number || "");
      setValue("emergencyContact_1Name", responseData?.emergency_contact_1_name || "");
      setValue("emergencyContact_1Relationship", responseData?.emergency_contact_1_relationship || "");
      setValue("emergencyContact_2Number", responseData?.emergency_contact_2_number || "");
      setValue("emergencyContact_2Name", responseData?.emergency_contact_2_name || "");
      setValue("emergencyContact_2Relationship", responseData?.emergency_contact_2_relationship || "");
      setValue("sss", responseData?.sss || "");
      setValue("philhealth", responseData?.philhealth || "");
      setValue("pagibig", responseData?.pagibig || "");
      setValue("tin", responseData?.tin || "");
      setValue("medicalCondition", responseData?.medical_condition || "");
      setValue("bloodType", responseData?.blood_type || "");
      setValue("elementary", responseData?.elementary || "");
      setValue("highSchool", responseData?.high_school || "");
      setValue("college", responseData?.college || "");
      setValue("postCollege", responseData?.post_college || "");
      setValue("undergraduate", responseData?.undergraduate || "");
      setValue("curriculumVitae", responseData?.curriculum_vitae || "");
      setValue("supportingDoc", responseData?.supporting_doc || "");
      setValue("intentLetter", responseData?.intent_letter || "");
      setValue("applicationLetter", responseData?.application_letter || "");
    }
  };
  const {
    data: personResponse,
    isLoading,
    isError: personError,
  } = useQyGetPersonById(personId || "", handleGetApiSuccess);
  const personData = personResponse?.data?.[0];

  // API UPDATE Person
  const handleUpdateApiSuccess = () => {
    showSuccess("Person updated successfully");
  };
  const { mutate: updatePerson, isLoading: isEditLoading } = useQyUpdatePerson(
    handleUpdateApiSuccess
  );

  // API DELETE Person
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Person deleted successfully");
  };
  const { mutate: deletePerson } = useQyDeletePerson(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<PersonFormSchema>({
    defaultValues: getDefault(personData),
    resolver: zodResolver(PersonFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const handleValidate = (form: PersonFormSchema) => {
    if (!personData) {
      throw new Error("No person data");
    }

    const formData = mapUpdatePerson(form, personData.code);
    updatePerson(formData);
  };
  const handleValidateError = (err: FieldErrors<PersonFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!personData) {
      throw new Error("No person data found");
    }

    const form = mapDeletePerson(personData.code);
    deletePerson(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;
      
      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

   return {
    personId,
    personData,
    isLoading,
    personError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}