import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import "./dashboard-gender.scss";
import ErrorSection from "@shared/ui/error-section/error-section";
import { Chart } from "primereact/chart";
import { useQyGetGenderDashboard } from "@factory/query/dashboard.query";
import { useRef, useState } from "react";
import { LabelValue } from "@shared/models/label-value.interface";
import { DashboardObject } from "@core/model/dashboard-object";
import { useNavigate } from "react-router-dom";
import { handleChartClick } from "@core/utility/handle-chart-click";

export function DashboardGender() {
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({
    labels: [],
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef<any>(null);
  const navigate = useNavigate();

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    const bgColor = ["rgba(34, 46, 214, 0.2)", "rgba(214, 34, 214, 0.2)"];
    const borderColor = ["rgba(21, 28, 128, 0.2)", "rgba(82, 12, 82, 0.2)"];

    const model = {
      labels: cleanedArray.map((item) => item.label),
      datasets: [
        {
          label: "Gender",
          data: cleanedArray.map((item) => item.value),
          backgroundColor: bgColor.map((item) => item),
          borderColor: borderColor.map((item) => item),
          borderWidth: 1,
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setChartData(model);
    setChartOptions(options);
  };

  const { isLoading, isError, error } = useQyGetGenderDashboard(
    true,
    handleSuccess
  );

  const filterValues = {
    MALE: JSON.stringify({ gender: "MALE" }),
    FEMALE: JSON.stringify({ gender: "FEMALE" }),
  };

  const handleGenderChartClick = (event: React.MouseEvent) => {
    handleChartClick(
      event,
      chartRef,
      chartData,
      navigate,
      "gender",
      filterValues,
      "inhabitant"
    );
  };
  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );

  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occurred" message={(error as any)?.message} />
    </div>
  );

  const chart = (
    <div className="dashboard-gender-chart">
      <h5 className="text-gray-500">Gender</h5>
      <div className="chart-container">
        <Chart
          type="bar"
          data={chartData}
          options={chartOptions}
          className="w-full h-full"
          ref={chartRef}
          onClick={handleGenderChartClick}
        />
      </div>
    </div>
  );

  return (
    <div id="DashboardGender" className="dashboard-gender">
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && chart}
    </div>
  );
}

export default DashboardGender;
