import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { GetHouseholdModel } from "@factory/model/query-model-household";
import { householdFormDefault } from "./form.default";
import { HouseholdFormSchema } from "./form.rule";

export const useFormDefaultHousehold = () => {
  const { formatDate } = useDateTimeContext();

  const getDefault = (cachedValue: GetHouseholdModel | undefined) => {
    return !cachedValue
      ? householdFormDefault
      : ({
          number: cachedValue.number,
          name: cachedValue.name,
          streetName: cachedValue.street_name,
          subdivision: cachedValue.subdivision,
          zone: cachedValue.zone,
          sitio: cachedValue.sitio,
          purok: cachedValue.purok,
          barangay: cachedValue.barangay,
          municipality: cachedValue.municipality,
          province: cachedValue.province,
          region: cachedValue.region,
          country: cachedValue.country,
          longitude: cachedValue.longitude,
          latitude: cachedValue.latitude,
          avatar: cachedValue.avatar,
          allowBoarders: Boolean(cachedValue.allow_boarders),
          hasBoardingPermit: Boolean(cachedValue.has_boarding_permit),
          houseMaterial: cachedValue.house_material,
          rooms: cachedValue.rooms,
          toilets: cachedValue.toilets,
          hasBackyardGarden: Boolean(cachedValue.has_backyard_garden),
          incomeSources: cachedValue.income_sources,
          pets: cachedValue.pets,
          twoWheelVehicle: cachedValue.two_wheel_vehicle,
          threeWheelVehicle: cachedValue.three_wheel_vehicle,
          fourWheelVehicle: cachedValue.four_wheel_vehicle,
        } as HouseholdFormSchema);
  };

  return {
    getDefault,
  };
};
