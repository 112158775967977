/* tslint:disable */
/* eslint-disable */
/**
 * Multi-tenancy Barangay API
 * API documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface AccessTokenOnlyDto
 */
export interface AccessTokenOnlyDto {
  /**
   *
   * @type {string}
   * @memberof AccessTokenOnlyDto
   */
  access_token?: string;
}
/**
 *
 * @export
 * @interface AttendanceControllerGet200Response
 */
export interface AttendanceControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof AttendanceControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetAttendanceDto>}
   * @memberof AttendanceControllerGet200Response
   */
  data?: Array<GetAttendanceDto>;
}
/**
 *
 * @export
 * @interface AuthenticationControllerAuthenticate201Response
 */
export interface AuthenticationControllerAuthenticate201Response {
  /**
   *
   * @type {string}
   * @memberof AuthenticationControllerAuthenticate201Response
   */
  message?: string;
  /**
   *
   * @type {TokenDto}
   * @memberof AuthenticationControllerAuthenticate201Response
   */
  data?: TokenDto;
}
/**
 *
 * @export
 * @interface AuthenticationControllerRefreshRenew201Response
 */
export interface AuthenticationControllerRefreshRenew201Response {
  /**
   *
   * @type {string}
   * @memberof AuthenticationControllerRefreshRenew201Response
   */
  message?: string;
  /**
   *
   * @type {RenewRefreshTokenDto}
   * @memberof AuthenticationControllerRefreshRenew201Response
   */
  data?: RenewRefreshTokenDto;
}
/**
 *
 * @export
 * @interface BlockPersonDto
 */
export interface BlockPersonDto {
  /**
   *
   * @type {boolean}
   * @memberof BlockPersonDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof BlockPersonDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof BlockPersonDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof BlockPersonDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof BlockPersonDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof BlockPersonDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof BlockPersonDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof BlockPersonDto
   */
  code: string;
  /**
   * IF set as TRUE then the user will not be allowed to login ELSE the user will still be allowed to login.
   * @type {boolean}
   * @memberof BlockPersonDto
   */
  is_blocked?: boolean;
}
/**
 *
 * @export
 * @interface CoreApiPaginatedResponse
 */
export interface CoreApiPaginatedResponse {
  /**
   *
   * @type {number}
   * @memberof CoreApiPaginatedResponse
   */
  count?: number;
}
/**
 *
 * @export
 * @interface CreateAttachmentTypeDto
 */
export interface CreateAttachmentTypeDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateAttachmentTypeDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateAttachmentTypeDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttachmentTypeDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateAttachmentTypeDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttachmentTypeDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateAttachmentTypeDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttachmentTypeDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttachmentTypeDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttachmentTypeDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttachmentTypeDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface CreateAttendanceDto
 */
export interface CreateAttendanceDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateAttendanceDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  arrival_time: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  household?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  inhabitant: string;
  /**
   *
   * @type {string}
   * @memberof CreateAttendanceDto
   */
  meeting?: string;
}
/**
 *
 * @export
 * @interface CreateBranchDto
 */
export interface CreateBranchDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateBranchDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateBranchDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBranchDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateBranchDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBranchDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateBranchDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBranchDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBranchDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBranchDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateBranchDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBranchDto
   */
  head?: string;
}
/**
 *
 * @export
 * @interface CreateConfigDto
 */
export interface CreateConfigDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateConfigDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateConfigDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateConfigDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateConfigDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateConfigDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateConfigDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateConfigDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateConfigDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateConfigDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateConfigDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateConfigDto
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof CreateConfigDto
   */
  type: string;
}
/**
 *
 * @export
 * @interface CreateHouseholBasedDto
 */
export interface CreateHouseholBasedDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateHouseholBasedDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  number: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  street_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  subdivision?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  sitio?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  purok: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  barangay: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  municipality: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  province: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  country: string;
  /**
   *
   * @type {number}
   * @memberof CreateHouseholBasedDto
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof CreateHouseholBasedDto
   */
  latitude: number;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  avatar?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateHouseholBasedDto
   */
  allow_boarders?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateHouseholBasedDto
   */
  has_boarding_permit?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  house_material?: string;
  /**
   *
   * @type {number}
   * @memberof CreateHouseholBasedDto
   */
  rooms?: number;
  /**
   *
   * @type {number}
   * @memberof CreateHouseholBasedDto
   */
  toilets?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateHouseholBasedDto
   */
  has_backyard_garden?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  income_sources?: string;
  /**
   *
   * @type {number}
   * @memberof CreateHouseholBasedDto
   */
  pets?: number;
  /**
   *
   * @type {number}
   * @memberof CreateHouseholBasedDto
   */
  two_wheel_vehicle?: number;
  /**
   *
   * @type {number}
   * @memberof CreateHouseholBasedDto
   */
  three_wheel_vehicle?: number;
  /**
   *
   * @type {number}
   * @memberof CreateHouseholBasedDto
   */
  four_wheel_vehicle?: number;
}
/**
 *
 * @export
 * @interface CreateInhabitantBaseDto
 */
export interface CreateInhabitantBaseDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateInhabitantBaseDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  household_code: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  gender: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  civil_status: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  birthday?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  mobile?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateInhabitantBaseDto
   */
  is_verified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateInhabitantBaseDto
   */
  is_deceased?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateInhabitantBaseDto
   */
  is_voter?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateInhabitantBaseDto
   */
  is_representative?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  occupation?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  occupation_place?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateInhabitantBaseDto
   */
  is_schooling?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  grade_level?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  place_of_registration?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  blood_type?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  health_remarks?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateInhabitantBaseDto
   */
  is_pregnant?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBaseDto
   */
  expected_labour_date?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateInhabitantBaseDto
   */
  is_single_parent?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBasedDto
   */
  street_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  subdivision?: string;
  /**
   *
   * @type {string}
   * @memberof CreateHouseholBasedDto
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof CreatInhabitantlBasedDto
   */
  sitio?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBasedDto
   */
  purok: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBasedDto
   */
  barangay: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBasedDto
   */
  municipality: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBasedDto
   */
  province: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBasedDto
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof CreateInhabitantBasedDto
   */
  country: string;
  /**
   *
   * @type {number}
   * @memberof CreateInhabitantBasedDto
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof CreateInhabitantBasedDto
   */
  latitude: number;
}
/**
 *
 * @export
 * @interface CreateMeetingDto
 */
export interface CreateMeetingDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateMeetingDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateMeetingDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMeetingDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateMeetingDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMeetingDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateMeetingDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMeetingDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMeetingDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMeetingDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMeetingDto
   */
  information?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMeetingDto
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMeetingDto
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof CreateMeetingDto
   */
  location?: string;
}
/**
 *
 * @export
 * @interface CreatePersonAddressDto
 */
export interface CreatePersonAddressDto {
  /**
   *
   * @type {boolean}
   * @memberof CreatePersonAddressDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  user_code: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  street_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  subdivision?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  sitio?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  purok?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  barangay?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  municipality?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  province?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonAddressDto
   */
  zip?: string;
  /**
   *
   * @type {number}
   * @memberof CreatePersonAddressDto
   */
  longitude?: number;
  /**
   *
   * @type {number}
   * @memberof CreatePersonAddressDto
   */
  latitude?: number;
}
/**
 *
 * @export
 * @interface CreatePersonExtendedDto
 */
export interface CreatePersonExtendedDto {
  /**
   *
   * @type {boolean}
   * @memberof CreatePersonExtendedDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  tenant?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof CreatePersonExtendedDto
   */
  is_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  activation_code?: string;
  /**
   * MALE | FEMALE
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  gender: string;
  /**
   * The date of the event. Format: YYYY-MM-DD
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  birthday?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreatePersonExtendedDto
   */
  is_blocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  mobile_number?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  father_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  mother_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  emergency_contact_1_number?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  emergency_contact_1_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  emergency_contact_1_relationship?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  emergency_contact_2_number?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  emergency_contact_2_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  emergency_contact_2_relationship?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  sss?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  philhealth?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  pagibig?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  tin?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  medical_condition?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  blood_type?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  elementary?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  high_school?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  college?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  post_college?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  undergraduate?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  curriculum_vitae?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  supporting_doc?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  intent_letter?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePersonExtendedDto
   */
  application_letter?: string;
  /**
   *
   * @type {CreatePersonAddressDto}
   * @memberof CreatePersonExtendedDto
   */
  address?: CreatePersonAddressDto;
  /**
   *
   * @type {CreatePersonAddressDto}
   * @memberof CreatePersonExtendedDto
   */
}
/**
 *
 * @export
 * @interface CreateRequestTypeDto
 */
export interface CreateRequestTypeDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateRequestTypeDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateRequestTypeDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRequestTypeDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateRequestTypeDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRequestTypeDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateRequestTypeDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRequestTypeDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRequestTypeDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRequestTypeDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateRequestTypeDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateRoleDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateRoleDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateRoleDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateRoleDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoleDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface CreateStatusDto
 */
export interface CreateStatusDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateStatusDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateStatusDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateStatusDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateStatusDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateStatusDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateStatusDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateStatusDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateStatusDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateStatusDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateStatusDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface CreateTenantDto
 */
export interface CreateTenantDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateTenantDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateTenantDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateTenantDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof CreateTenantDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  owner?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  business_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  business_type: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  mobile_number?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  website: string;
  /**
   * The date of the event. Format: YYYY-MM-DD
   * @type {string}
   * @memberof CreateTenantDto
   */
  membership_date?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTenantDto
   */
  rewards_type: string;
}
/**
 *
 * @export
 * @interface DashboardControllerAgeRange200Response
 */
export interface DashboardControllerAgeRange200Response {
  /**
   *
   * @type {string}
   * @memberof DashboardControllerAgeRange200Response
   */
  message?: string;
  /**
   *
   * @type {DashboardDataDto}
   * @memberof DashboardControllerAgeRange200Response
   */
  data?: DashboardDataDto;
}
/**
 *
 * @export
 * @interface DashboardDataDto
 */
export interface DashboardDataDto {
  /**
   *
   * @type {number}
   * @memberof DashboardDataDto
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof DashboardDataDto
   */
  label: string;
}
/**
 *
 * @export
 * @interface DeleteAttachmentTypeDto
 */
export interface DeleteAttachmentTypeDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteAttachmentTypeDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteAttachmentTypeDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteAttachmentTypeDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteAttachmentTypeDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteAttachmentTypeDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteAttachmentTypeDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteAttachmentTypeDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteAttachmentTypeDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteAttendanceDto
 */
export interface DeleteAttendanceDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteAttendanceDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteAttendanceDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteAttendanceDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteAttendanceDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteAttendanceDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteAttendanceDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteAttendanceDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteAttendanceDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteBranchDto
 */
export interface DeleteBranchDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteBranchDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteBranchDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteBranchDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteBranchDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteBranchDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteBranchDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteBranchDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteBranchDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteConfigDto
 */
export interface DeleteConfigDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteConfigDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteConfigDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteConfigDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteConfigDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteConfigDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteConfigDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteConfigDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteConfigDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteHouseholdDto
 */
export interface DeleteHouseholdDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteHouseholdDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteHouseholdDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteHouseholdDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteHouseholdDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteHouseholdDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteHouseholdDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteHouseholdDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteHouseholdDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteInhabitantDto
 */
export interface DeleteInhabitantDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteInhabitantDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteInhabitantDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteInhabitantDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteInhabitantDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteInhabitantDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteInhabitantDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteInhabitantDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteInhabitantDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteMeetingDto
 */
export interface DeleteMeetingDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteMeetingDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteMeetingDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteMeetingDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteMeetingDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteMeetingDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteMeetingDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteMeetingDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteMeetingDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeletePersonAddressDto
 */
export interface DeletePersonAddressDto {
  /**
   *
   * @type {boolean}
   * @memberof DeletePersonAddressDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeletePersonAddressDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeletePersonAddressDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeletePersonAddressDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeletePersonAddressDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeletePersonAddressDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeletePersonAddressDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeletePersonAddressDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeletePersonDto
 */
export interface DeletePersonDto {
  /**
   *
   * @type {boolean}
   * @memberof DeletePersonDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeletePersonDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeletePersonDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeletePersonDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeletePersonDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeletePersonDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeletePersonDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeletePersonDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteRequestTypeDto
 */
export interface DeleteRequestTypeDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteRequestTypeDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteRequestTypeDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteRequestTypeDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteRequestTypeDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteRequestTypeDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteRequestTypeDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteRequestTypeDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteRequestTypeDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteRoleDto
 */
export interface DeleteRoleDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteRoleDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteRoleDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteRoleDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteRoleDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteRoleDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteRoleDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteRoleDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteRoleDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteStatusDto
 */
export interface DeleteStatusDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteStatusDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteStatusDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteStatusDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteStatusDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteStatusDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteStatusDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteStatusDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteStatusDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeleteTenantDto
 */
export interface DeleteTenantDto {
  /**
   *
   * @type {boolean}
   * @memberof DeleteTenantDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteTenantDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteTenantDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteTenantDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteTenantDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof DeleteTenantDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteTenantDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof DeleteTenantDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface DeviceAuthorization
 */
export interface DeviceAuthorization {
  /**
   *
   * @type {boolean}
   * @memberof DeviceAuthorization
   */
  authorize: boolean;
}
/**
 *
 * @export
 * @interface DeviceRegistrationControllerRegister201Response
 */
export interface DeviceRegistrationControllerRegister201Response {
  /**
   *
   * @type {string}
   * @memberof DeviceRegistrationControllerRegister201Response
   */
  message?: string;
  /**
   *
   * @type {AccessTokenOnlyDto}
   * @memberof DeviceRegistrationControllerRegister201Response
   */
  data?: AccessTokenOnlyDto;
}
/**
 *
 * @export
 * @interface EditAttachmentTypeDto
 */
export interface EditAttachmentTypeDto {
  /**
   *
   * @type {boolean}
   * @memberof EditAttachmentTypeDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditAttachmentTypeDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditAttachmentTypeDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditAttachmentTypeDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditAttachmentTypeDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditAttachmentTypeDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditAttachmentTypeDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditAttachmentTypeDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditAttachmentTypeDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EditAttachmentTypeDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface EditBranchDto
 */
export interface EditBranchDto {
  /**
   *
   * @type {boolean}
   * @memberof EditBranchDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditBranchDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditBranchDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditBranchDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditBranchDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditBranchDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditBranchDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditBranchDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditBranchDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EditBranchDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof EditBranchDto
   */
  head?: string;
}
/**
 *
 * @export
 * @interface EditConfigDto
 */
export interface EditConfigDto {
  /**
   *
   * @type {boolean}
   * @memberof EditConfigDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditConfigDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditConfigDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditConfigDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditConfigDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditConfigDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditConfigDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditConfigDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditConfigDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EditConfigDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof EditConfigDto
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof EditConfigDto
   */
  type: string;
}
/**
 *
 * @export
 * @interface EditHouseholdBaseDto
 */
export interface EditHouseholdBaseDto {
  /**
   *
   * @type {boolean}
   * @memberof EditHouseholdBaseDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  number: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  street_name: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  subdivision?: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  sitio?: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  purok: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  barangay: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  municipality: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  province: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  country: string;
  /**
   *
   * @type {number}
   * @memberof EditHouseholdBaseDto
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof EditHouseholdBaseDto
   */
  latitude: number;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  avatar?: string;
  /**
   *
   * @type {boolean}
   * @memberof EditHouseholdBaseDto
   */
  allow_boarders?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EditHouseholdBaseDto
   */
  has_boarding_permit?: boolean;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  house_material?: string;
  /**
   *
   * @type {number}
   * @memberof EditHouseholdBaseDto
   */
  rooms?: number;
  /**
   *
   * @type {number}
   * @memberof EditHouseholdBaseDto
   */
  toilets?: number;
  /**
   *
   * @type {boolean}
   * @memberof EditHouseholdBaseDto
   */
  has_backyard_garden?: boolean;
  /**
   *
   * @type {string}
   * @memberof EditHouseholdBaseDto
   */
  income_sources?: string;
  /**
   *
   * @type {number}
   * @memberof EditHouseholdBaseDto
   */
  pets?: number;
  /**
   *
   * @type {number}
   * @memberof EditHouseholdBaseDto
   */
  two_wheel_vehicle?: number;
  /**
   *
   * @type {number}
   * @memberof EditHouseholdBaseDto
   */
  three_wheel_vehicle?: number;
  /**
   *
   * @type {number}
   * @memberof EditHouseholdBaseDto
   */
  four_wheel_vehicle?: number;
}
/**
 *
 * @export
 * @interface EditInhabitantBaseDto
 */
export interface EditInhabitantBaseDto {
  /**
   *
   * @type {boolean}
   * @memberof EditInhabitantBaseDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  gender: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  civil_status: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  birthday?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  mobile?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof EditInhabitantBaseDto
   */
  is_verified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EditInhabitantBaseDto
   */
  is_deceased?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EditInhabitantBaseDto
   */
  is_voter?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EditInhabitantBaseDto
   */
  is_representative?: boolean;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  occupation?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  occupation_place?: string;
  /**
   *
   * @type {boolean}
   * @memberof EditInhabitantBaseDto
   */
  is_schooling?: boolean;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  grade_level?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  place_of_registration?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  blood_type?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  health_remarks?: string;
  /**
   *
   * @type {boolean}
   * @memberof EditInhabitantBaseDto
   */
  is_pregnant?: boolean;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  expected_labour_date?: string;
  /**
   *
   * @type {boolean}
   * @memberof EditInhabitantBaseDto
   */
  is_single_parent?: boolean;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  street_name: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  subdivision?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  sitio?: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  purok: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  barangay: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  municipality: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  province: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof EditInhabitantBaseDto
   */
  country: string;
  /**
   *
   * @type {number}
   * @memberof EditInhabitantBaseDto
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof EditInhabitantBaseDto
   */
  latitude: number;
}
/**
 *
 * @export
 * @interface EditMeetingDto
 */
export interface EditMeetingDto {
  /**
   *
   * @type {boolean}
   * @memberof EditMeetingDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditMeetingDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditMeetingDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditMeetingDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditMeetingDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditMeetingDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditMeetingDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditMeetingDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof EditMeetingDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof EditMeetingDto
   */
  information?: string;
  /**
   *
   * @type {string}
   * @memberof EditMeetingDto
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof EditMeetingDto
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof EditMeetingDto
   */
  location?: string;
}
/**
 *
 * @export
 * @interface EditPersonAddressDto
 */
export interface EditPersonAddressDto {
  /**
   *
   * @type {boolean}
   * @memberof EditPersonAddressDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  street_name?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  subdivision?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  sitio?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  purok?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  barangay?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  municipality?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  province?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonAddressDto
   */
  zip?: string;
  /**
   *
   * @type {number}
   * @memberof EditPersonAddressDto
   */
  longitude?: number;
  /**
   *
   * @type {number}
   * @memberof EditPersonAddressDto
   */
  latitude?: number;
}
/**
 *
 * @export
 * @interface EditPersonExtendedDto
 */
export interface EditPersonExtendedDto {
  /**
   *
   * @type {boolean}
   * @memberof EditPersonExtendedDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  tenant?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof EditPersonExtendedDto
   */
  is_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  activation_code?: string;
  /**
   * MALE | FEMALE
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  gender: string;
  /**
   * The date of the event. Format: YYYY-MM-DD
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  birthday?: string;
  /**
   *
   * @type {boolean}
   * @memberof EditPersonExtendedDto
   */
  is_blocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  mobile_number?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  father_name?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  mother_name?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  emergency_contact_1_number?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  emergency_contact_1_name?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  emergency_contact_1_relationship?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  emergency_contact_2_number?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  emergency_contact_2_name?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  emergency_contact_2_relationship?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  sss?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  philhealth?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  pagibig?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  tin?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  medical_condition?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  blood_type?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  elementary?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  high_school?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  college?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  post_college?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  undergraduate?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  curriculum_vitae?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  supporting_doc?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  intent_letter?: string;
  /**
   *
   * @type {string}
   * @memberof EditPersonExtendedDto
   */
  application_letter?: string;
  /**
   *
   * @type {EditPersonAddressDto}
   * @memberof EditPersonExtendedDto
   */
  address?: EditPersonAddressDto;
}
/**
 *
 * @export
 * @interface EditRequestTypeDto
 */
export interface EditRequestTypeDto {
  /**
   *
   * @type {boolean}
   * @memberof EditRequestTypeDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditRequestTypeDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditRequestTypeDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditRequestTypeDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditRequestTypeDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditRequestTypeDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditRequestTypeDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditRequestTypeDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditRequestTypeDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EditRequestTypeDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface EditRoleDto
 */
export interface EditRoleDto {
  /**
   *
   * @type {boolean}
   * @memberof EditRoleDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditRoleDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditRoleDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditRoleDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditRoleDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditRoleDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditRoleDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditRoleDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditRoleDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EditRoleDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface EditStatusDto
 */
export interface EditStatusDto {
  /**
   *
   * @type {boolean}
   * @memberof EditStatusDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditStatusDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditStatusDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditStatusDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditStatusDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditStatusDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditStatusDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditStatusDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditStatusDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EditStatusDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface EditTenantDto
 */
export interface EditTenantDto {
  /**
   *
   * @type {boolean}
   * @memberof EditTenantDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditTenantDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditTenantDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof EditTenantDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  owner?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  business_name: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  business_type: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  mobile_number?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  website: string;
  /**
   * The date of the event. Format: YYYY-MM-DD
   * @type {string}
   * @memberof EditTenantDto
   */
  membership_date?: string;
  /**
   *
   * @type {string}
   * @memberof EditTenantDto
   */
  rewards_type: string;
}
/**
 *
 * @export
 * @interface GenericErrorResponseDto
 */
export interface GenericErrorResponseDto {
  /**
   *
   * @type {string}
   * @memberof GenericErrorResponseDto
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof GenericErrorResponseDto
   */
  status_code?: number;
}
/**
 *
 * @export
 * @interface GetAttachmentTypeDto
 */
export interface GetAttachmentTypeDto {
  /**
   *
   * @type {boolean}
   * @memberof GetAttachmentTypeDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  created_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  created_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  created_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  updated_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  updated_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  updated_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  deleted_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  deleted_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  deleted_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetAttachmentTypeDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface GetAttendanceDto
 */
export interface GetAttendanceDto {
  /**
   *
   * @type {boolean}
   * @memberof GetAttendanceDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetAttendanceDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetAttendanceDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetAttendanceDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  arrival_time?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  household?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  inhabitant?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  meeting?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  information?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  household_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  household_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  last_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetAttendanceDto
   */
  middle_name?: string;
}
/**
 *
 * @export
 * @interface GetBranchDto
 */
export interface GetBranchDto {
  /**
   *
   * @type {boolean}
   * @memberof GetBranchDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetBranchDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetBranchDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetBranchDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  created_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  created_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  created_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  updated_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  updated_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  updated_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  deleted_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  deleted_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  deleted_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  head?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  head_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  head_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetBranchDto
   */
  head_last_name: string;
  /**
   *
   * @type {number}
   * @memberof GetBranchDto
   */
  population?: number;
}
/**
 *
 * @export
 * @interface GetConfigDto
 */
export interface GetConfigDto {
  /**
   *
   * @type {boolean}
   * @memberof GetConfigDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetConfigDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetConfigDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetConfigDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  created_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  created_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  created_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  updated_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  updated_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  updated_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  deleted_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  deleted_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  deleted_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof GetConfigDto
   */
  type: string;
}
/**
 *
 * @export
 * @interface GetHouseholdDto
 */
export interface GetHouseholdDto {
  /**
   *
   * @type {boolean}
   * @memberof GetHouseholdDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetHouseholdDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetHouseholdDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetHouseholdDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  number: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  street_name: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  subdivision?: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  sitio?: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  purok: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  barangay: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  municipality: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  province: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  country: string;
  /**
   *
   * @type {number}
   * @memberof GetHouseholdDto
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof GetHouseholdDto
   */
  latitude: number;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  avatar?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetHouseholdDto
   */
  allow_boarders?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetHouseholdDto
   */
  has_boarding_permit?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  house_material?: string;
  /**
   *
   * @type {number}
   * @memberof GetHouseholdDto
   */
  rooms?: number;
  /**
   *
   * @type {number}
   * @memberof GetHouseholdDto
   */
  toilets?: number;
  /**
   *
   * @type {boolean}
   * @memberof GetHouseholdDto
   */
  has_backyard_garden?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetHouseholdDto
   */
  income_sources?: string;
  /**
   *
   * @type {number}
   * @memberof GetHouseholdDto
   */
  pets?: number;
  /**
   *
   * @type {number}
   * @memberof GetHouseholdDto
   */
  two_wheel_vehicle?: number;
  /**
   *
   * @type {number}
   * @memberof GetHouseholdDto
   */
  three_wheel_vehicle?: number;
  /**
   *
   * @type {number}
   * @memberof GetHouseholdDto
   */
  four_wheel_vehicle?: number;
  /**
   *
   * @type {Array<GetInhabitantDto>}
   * @memberof GetHouseholdDto
   */
  inhabitants: Array<GetInhabitantDto>;
}
/**
 *
 * @export
 * @interface GetInhabitantDto
 */
export interface GetInhabitantDto {
  /**
   *
   * @type {boolean}
   * @memberof GetInhabitantDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetInhabitantDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetInhabitantDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetInhabitantDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  household_code: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  gender: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  civil_status: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  birthday?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  mobile?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetInhabitantDto
   */
  is_verified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetInhabitantDto
   */
  is_deceased?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetInhabitantDto
   */
  is_voter?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetInhabitantDto
   */
  is_representative?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  note?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  occupation?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  occupation_place?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetInhabitantDto
   */
  is_schooling?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  grade_level?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  place_of_registration?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  blood_type?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  health_remarks?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetInhabitantDto
   */
  is_pregnant?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  expected_labour_date?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetInhabitantDto
   */
  is_single_parent?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  street_name: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  subdivision?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  sitio?: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  purok: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  barangay: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  municipality: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  province: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof GetInhabitantDto
   */
  country: string;
  /**
   *
   * @type {number}
   * @memberof GetInhabitantDto
   */
  longitude: number;
  /**
   *
   * @type {number}
   * @memberof GetInhabitantDto
   */
  latitude: number;
}
/**
 *
 * @export
 * @interface GetMeetingDto
 */
export interface GetMeetingDto {
  /**
   *
   * @type {boolean}
   * @memberof GetMeetingDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetMeetingDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetMeetingDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetMeetingDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetMeetingDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetMeetingDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetMeetingDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetMeetingDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof GetMeetingDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof GetMeetingDto
   */
  information?: string;
  /**
   *
   * @type {string}
   * @memberof GetMeetingDto
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof GetMeetingDto
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof GetMeetingDto
   */
  location?: string;
}
/**
 *
 * @export
 * @interface GetPersonAddressDto
 */
export interface GetPersonAddressDto {
  /**
   *
   * @type {boolean}
   * @memberof GetPersonAddressDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  created_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  created_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  created_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  updated_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  updated_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  updated_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  deleted_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  deleted_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  deleted_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  user_code: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  person_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  person_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  person_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  street_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  subdivision?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  sitio?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  purok?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  barangay?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  municipality?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  province?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonAddressDto
   */
  zip?: string;
  /**
   *
   * @type {number}
   * @memberof GetPersonAddressDto
   */
  longitude?: number;
  /**
   *
   * @type {number}
   * @memberof GetPersonAddressDto
   */
  latitude?: number;
}
/**
 *
 * @export
 * @interface GetPersonDto
 */
export interface GetPersonDto {
  /**
   *
   * @type {boolean}
   * @memberof GetPersonDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetPersonDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetPersonDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetPersonDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  created_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  created_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  created_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  updated_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  updated_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  updated_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  deleted_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  deleted_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  deleted_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  tenant?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  role_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  role_description: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof GetPersonDto
   */
  is_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  activation_code?: string;
  /**
   * MALE | FEMALE
   * @type {string}
   * @memberof GetPersonDto
   */
  gender: string;
  /**
   * The date of the event. Format: YYYY-MM-DD
   * @type {string}
   * @memberof GetPersonDto
   */
  birthday?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetPersonDto
   */
  is_blocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  mobile_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  father_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  mother_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  emergency_contact_1_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  emergency_contact_1_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  emergency_contact_1_relationship?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  emergency_contact_2_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  emergency_contact_2_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  emergency_contact_2_relationship?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  sss?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  philhealth?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  pagibig?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  tin?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  medical_condition?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  blood_type?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  elementary?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  high_school?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  college?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  post_college?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  undergraduate?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  curriculum_vitae?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  supporting_doc?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  intent_letter?: string;
  /**
   *
   * @type {string}
   * @memberof GetPersonDto
   */
  application_letter?: string;
  /**
   *
   * @type {GetPersonAddressDto}
   * @memberof GetPersonDto
   */
  address: GetPersonAddressDto;
}
/**
 *
 * @export
 * @interface GetRequestTypeDto
 */
export interface GetRequestTypeDto {
  /**
   *
   * @type {boolean}
   * @memberof GetRequestTypeDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  created_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  created_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  created_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  updated_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  updated_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  updated_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  deleted_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  deleted_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  deleted_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetRequestTypeDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface GetRoleDto
 */
export interface GetRoleDto {
  /**
   *
   * @type {boolean}
   * @memberof GetRoleDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetRoleDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetRoleDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetRoleDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  created_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  created_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  created_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  updated_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  updated_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  updated_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  deleted_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  deleted_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  deleted_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetRoleDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface GetStatusDto
 */
export interface GetStatusDto {
  /**
   *
   * @type {boolean}
   * @memberof GetStatusDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetStatusDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetStatusDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetStatusDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  created_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  created_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  created_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  updated_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  updated_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  updated_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  deleted_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  deleted_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  deleted_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetStatusDto
   */
  description?: string;
}
/**
 *
 * @export
 * @interface GetTenantDto
 */
export interface GetTenantDto {
  /**
   *
   * @type {boolean}
   * @memberof GetTenantDto
   */
  is_active?: boolean;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetTenantDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  created_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetTenantDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  updated_by?: string;
  /**
   * The date and time of the event. Format: YYYY-MM-DD HH:ii:ss
   * @type {string}
   * @memberof GetTenantDto
   */
  deleted_at?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  deleted_by?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  created_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  created_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  created_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  updated_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  updated_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  updated_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  deleted_first_name: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  deleted_middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  deleted_last_name: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  owner?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  business_name: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  business_type: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  avatar?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  mobile_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  website: string;
  /**
   * The date of the event. Format: YYYY-MM-DD
   * @type {string}
   * @memberof GetTenantDto
   */
  membership_date?: string;
  /**
   *
   * @type {string}
   * @memberof GetTenantDto
   */
  rewards_type: string;
}
/**
 *
 * @export
 * @interface HouseholdControllerGet200Response
 */
export interface HouseholdControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof HouseholdControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetHouseholdDto>}
   * @memberof HouseholdControllerGet200Response
   */
  data?: Array<GetHouseholdDto>;
}
/**
 *
 * @export
 * @interface InhabitantControllerGet200Response
 */
export interface InhabitantControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof InhabitantControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetInhabitantDto>}
   * @memberof InhabitantControllerGet200Response
   */
  data?: Array<GetInhabitantDto>;
}
/**
 *
 * @export
 * @interface MeetingControllerGet200Response
 */
export interface MeetingControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof MeetingControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetMeetingDto>}
   * @memberof MeetingControllerGet200Response
   */
  data?: Array<GetMeetingDto>;
}
/**
 *
 * @export
 * @interface MessageArrayDataResponseDto
 */
export interface MessageArrayDataResponseDto {
  /**
   *
   * @type {string}
   * @memberof MessageArrayDataResponseDto
   */
  message: string;
  /**
   *
   * @type {Array<MessageWithIdentifierDto>}
   * @memberof MessageArrayDataResponseDto
   */
  data: Array<MessageWithIdentifierDto>;
}
/**
 *
 * @export
 * @interface MessageResponseDto
 */
export interface MessageResponseDto {
  /**
   *
   * @type {string}
   * @memberof MessageResponseDto
   */
  message?: string;
  /**
   *
   * @type {object}
   * @memberof MessageResponseDto
   */
  data?: object;
}
/**
 *
 * @export
 * @interface MessageWithIdentifierDto
 */
export interface MessageWithIdentifierDto {
  /**
   *
   * @type {string}
   * @memberof MessageWithIdentifierDto
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof MessageWithIdentifierDto
   */
  message: string;
}
/**
 *
 * @export
 * @interface PersonAddressControllerGet200Response
 */
export interface PersonAddressControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof PersonAddressControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetPersonAddressDto>}
   * @memberof PersonAddressControllerGet200Response
   */
  data?: Array<GetPersonAddressDto>;
}
/**
 *
 * @export
 * @interface PersonControllerGet200Response
 */
export interface PersonControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof PersonControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetPersonDto>}
   * @memberof PersonControllerGet200Response
   */
  data?: Array<GetPersonDto>;
}
/**
 *
 * @export
 * @interface RenewRefreshTokenDto
 */
export interface RenewRefreshTokenDto {
  /**
   *
   * @type {string}
   * @memberof RenewRefreshTokenDto
   */
  user_code?: string;
  /**
   *
   * @type {string}
   * @memberof RenewRefreshTokenDto
   */
  refresh_token?: string;
  /**
   *
   * @type {number}
   * @memberof RenewRefreshTokenDto
   */
  refresh_exp?: number;
}
/**
 *
 * @export
 * @interface SigninUserRequestDto
 */
export interface SigninUserRequestDto {
  /**
   *
   * @type {string}
   * @memberof SigninUserRequestDto
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof SigninUserRequestDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface SwaggerExceptionResponseDto
 */
export interface SwaggerExceptionResponseDto {
  /**
   *
   * @type {string}
   * @memberof SwaggerExceptionResponseDto
   */
  message: string;
}
/**
 *
 * @export
 * @interface TenantControllerGet200Response
 */
export interface TenantControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof TenantControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetTenantDto>}
   * @memberof TenantControllerGet200Response
   */
  data?: Array<GetTenantDto>;
}
/**
 *
 * @export
 * @interface TokenDto
 */
export interface TokenDto {
  /**
   *
   * @type {string}
   * @memberof TokenDto
   */
  access_token?: string;
  /**
   *
   * @type {string}
   * @memberof TokenDto
   */
  refresh_token?: string;
  /**
   *
   * @type {string}
   * @memberof TokenDto
   */
  user_code?: string;
  /**
   *
   * @type {number}
   * @memberof TokenDto
   */
  access_exp?: number;
  /**
   *
   * @type {number}
   * @memberof TokenDto
   */
  refresh_exp?: number;
}
/**
 *
 * @export
 * @interface UtilAttachmentTypeControllerGet200Response
 */
export interface UtilAttachmentTypeControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof UtilAttachmentTypeControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetAttachmentTypeDto>}
   * @memberof UtilAttachmentTypeControllerGet200Response
   */
  data?: Array<GetAttachmentTypeDto>;
}
/**
 *
 * @export
 * @interface UtilBranchControllerGet200Response
 */
export interface UtilBranchControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof UtilBranchControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetBranchDto>}
   * @memberof UtilBranchControllerGet200Response
   */
  data?: Array<GetBranchDto>;
}
/**
 *
 * @export
 * @interface UtilConfigControllerGet200Response
 */
export interface UtilConfigControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof UtilConfigControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetConfigDto>}
   * @memberof UtilConfigControllerGet200Response
   */
  data?: Array<GetConfigDto>;
}
/**
 *
 * @export
 * @interface UtilRequestTypeControllerGet200Response
 */
export interface UtilRequestTypeControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof UtilRequestTypeControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetRequestTypeDto>}
   * @memberof UtilRequestTypeControllerGet200Response
   */
  data?: Array<GetRequestTypeDto>;
}
/**
 *
 * @export
 * @interface UtilRoleControllerGet200Response
 */
export interface UtilRoleControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof UtilRoleControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetRoleDto>}
   * @memberof UtilRoleControllerGet200Response
   */
  data?: Array<GetRoleDto>;
}
/**
 *
 * @export
 * @interface UtilStatusControllerGet200Response
 */
export interface UtilStatusControllerGet200Response {
  /**
   *
   * @type {number}
   * @memberof UtilStatusControllerGet200Response
   */
  count?: number;
  /**
   *
   * @type {Array<GetStatusDto>}
   * @memberof UtilStatusControllerGet200Response
   */
  data?: Array<GetStatusDto>;
}

/**
 * AttendanceApi - axios parameter creator
 * @export
 */
export const AttendanceApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateAttendanceDto} createAttendanceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attendanceControllerCreate: async (
      createAttendanceDto: CreateAttendanceDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAttendanceDto' is not null or undefined
      assertParamExists(
        "attendanceControllerCreate",
        "createAttendanceDto",
        createAttendanceDto
      );
      const localVarPath = `/api/v1/attendance/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAttendanceDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteAttendanceDto} deleteAttendanceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attendanceControllerDelete: async (
      deleteAttendanceDto: DeleteAttendanceDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteAttendanceDto' is not null or undefined
      assertParamExists(
        "attendanceControllerDelete",
        "deleteAttendanceDto",
        deleteAttendanceDto
      );
      const localVarPath = `/api/v1/attendance/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteAttendanceDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attendanceControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/attendance/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AttendanceApi - functional programming interface
 * @export
 */
export const AttendanceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AttendanceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateAttendanceDto} createAttendanceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attendanceControllerCreate(
      createAttendanceDto: CreateAttendanceDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.attendanceControllerCreate(
          createAttendanceDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AttendanceApi.attendanceControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteAttendanceDto} deleteAttendanceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attendanceControllerDelete(
      deleteAttendanceDto: DeleteAttendanceDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.attendanceControllerDelete(
          deleteAttendanceDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AttendanceApi.attendanceControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attendanceControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AttendanceControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.attendanceControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AttendanceApi.attendanceControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AttendanceApi - factory interface
 * @export
 */
export const AttendanceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AttendanceApiFp(configuration);
  return {
    /**
     *
     * @param {CreateAttendanceDto} createAttendanceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attendanceControllerCreate(
      createAttendanceDto: CreateAttendanceDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .attendanceControllerCreate(createAttendanceDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteAttendanceDto} deleteAttendanceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attendanceControllerDelete(
      deleteAttendanceDto: DeleteAttendanceDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .attendanceControllerDelete(deleteAttendanceDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attendanceControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<AttendanceControllerGet200Response> {
      return localVarFp
        .attendanceControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AttendanceApi - object-oriented interface
 * @export
 * @class AttendanceApi
 * @extends {BaseAPI}
 */
export class AttendanceApi extends BaseAPI {
  /**
   *
   * @param {CreateAttendanceDto} createAttendanceDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AttendanceApi
   */
  public attendanceControllerCreate(
    createAttendanceDto: CreateAttendanceDto,
    options?: RawAxiosRequestConfig
  ) {
    return AttendanceApiFp(this.configuration)
      .attendanceControllerCreate(createAttendanceDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteAttendanceDto} deleteAttendanceDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AttendanceApi
   */
  public attendanceControllerDelete(
    deleteAttendanceDto: DeleteAttendanceDto,
    options?: RawAxiosRequestConfig
  ) {
    return AttendanceApiFp(this.configuration)
      .attendanceControllerDelete(deleteAttendanceDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AttendanceApi
   */
  public attendanceControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return AttendanceApiFp(this.configuration)
      .attendanceControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {SigninUserRequestDto} signinUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerAuthenticate: async (
      signinUserRequestDto: SigninUserRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'signinUserRequestDto' is not null or undefined
      assertParamExists(
        "authenticationControllerAuthenticate",
        "signinUserRequestDto",
        signinUserRequestDto
      );
      const localVarPath = `/api/v1/authentication/signin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        signinUserRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerIntrospect: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/authentication/instrospect`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerRefresh: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/authentication/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerRefreshRenew: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/authentication/refresh/renew`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AuthenticationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SigninUserRequestDto} signinUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authenticationControllerAuthenticate(
      signinUserRequestDto: SigninUserRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AuthenticationControllerAuthenticate201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authenticationControllerAuthenticate(
          signinUserRequestDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AuthenticationApi.authenticationControllerAuthenticate"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authenticationControllerIntrospect(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authenticationControllerIntrospect(
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AuthenticationApi.authenticationControllerIntrospect"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authenticationControllerRefresh(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AuthenticationControllerAuthenticate201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authenticationControllerRefresh(
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AuthenticationApi.authenticationControllerRefresh"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authenticationControllerRefreshRenew(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AuthenticationControllerRefreshRenew201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authenticationControllerRefreshRenew(
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AuthenticationApi.authenticationControllerRefreshRenew"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthenticationApiFp(configuration);
  return {
    /**
     *
     * @param {SigninUserRequestDto} signinUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerAuthenticate(
      signinUserRequestDto: SigninUserRequestDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<AuthenticationControllerAuthenticate201Response> {
      return localVarFp
        .authenticationControllerAuthenticate(signinUserRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerIntrospect(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<boolean> {
      return localVarFp
        .authenticationControllerIntrospect(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerRefresh(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<AuthenticationControllerAuthenticate201Response> {
      return localVarFp
        .authenticationControllerRefresh(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticationControllerRefreshRenew(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<AuthenticationControllerRefreshRenew201Response> {
      return localVarFp
        .authenticationControllerRefreshRenew(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
  /**
   *
   * @param {SigninUserRequestDto} signinUserRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public authenticationControllerAuthenticate(
    signinUserRequestDto: SigninUserRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return AuthenticationApiFp(this.configuration)
      .authenticationControllerAuthenticate(signinUserRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public authenticationControllerIntrospect(options?: RawAxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .authenticationControllerIntrospect(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public authenticationControllerRefresh(options?: RawAxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .authenticationControllerRefresh(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public authenticationControllerRefreshRenew(options?: RawAxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .authenticationControllerRefreshRenew(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerAgeRange: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dashboard/age-range`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerInhabitantPurok: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dashboard/inhabitants-per-purok`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerHouseholdPurok: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dashboard/households-per-purok`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerGenders: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dashboard/genders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerVoters: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dashboard/voters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerHouseholds: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dashboard/households`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerInhabitants: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dashboard/inhabitants`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerDeceased: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/dashboard/deceased`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DashboardApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardControllerAgeRange(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DashboardControllerAgeRange200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dashboardControllerAgeRange(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DashboardApi.dashboardControllerAgeRange"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardControllerInhabitantPurok(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DashboardControllerAgeRange200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dashboardControllerInhabitantPurok(
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DashboardApi.dashboardControllerInhabitantPurok"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardControllerHouseholdPurok(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DashboardControllerAgeRange200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dashboardControllerHouseholdPurok(
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DashboardApi.dashboardControllerHouseholdPurok"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardControllerGenders(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DashboardControllerAgeRange200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dashboardControllerGenders(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DashboardApi.dashboardControllerGenders"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardControllerVoters(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DashboardControllerAgeRange200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dashboardControllerVoters(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DashboardApi.dashboardControllerVoters"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardControllerHouseholds(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DashboardControllerAgeRange200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dashboardControllerHouseholds(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DashboardApi.dashboardControllerHouseholds"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardControllerInhabitants(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DashboardControllerAgeRange200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dashboardControllerInhabitants(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DashboardApi.dashboardControllerInhabitants"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardControllerDeceased(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DashboardControllerAgeRange200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dashboardControllerDeceased(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["DashboardApi.dashboardControllerDeceased"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DashboardApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerAgeRange(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<DashboardControllerAgeRange200Response> {
      return localVarFp
        .dashboardControllerAgeRange(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerGenders(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<DashboardControllerAgeRange200Response> {
      return localVarFp
        .dashboardControllerGenders(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerVoters(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<DashboardControllerAgeRange200Response> {
      return localVarFp
        .dashboardControllerVoters(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerHouseholds(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<DashboardControllerAgeRange200Response> {
      return localVarFp
        .dashboardControllerHouseholds(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardControllerInhabitants(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<DashboardControllerAgeRange200Response> {
      return localVarFp
        .dashboardControllerInhabitants(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApi
   */
  public dashboardControllerAgeRange(options?: RawAxiosRequestConfig) {
    return DashboardApiFp(this.configuration)
      .dashboardControllerAgeRange(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApi
   */
  public dashboardControllerGenders(options?: RawAxiosRequestConfig) {
    return DashboardApiFp(this.configuration)
      .dashboardControllerGenders(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApi
   */
  public dashboardControllerVoters(options?: RawAxiosRequestConfig) {
    return DashboardApiFp(this.configuration)
      .dashboardControllerVoters(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApi
   */
  public dashboardControllerHouseholds(options?: RawAxiosRequestConfig) {
    return DashboardApiFp(this.configuration)
      .dashboardControllerHouseholds(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApi
   */
  public dashboardControllerInhabitants(options?: RawAxiosRequestConfig) {
    return DashboardApiFp(this.configuration)
      .dashboardControllerInhabitants(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DeviceAuthorizationApi - axios parameter creator
 * @export
 */
export const DeviceAuthorizationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {DeviceAuthorization} deviceAuthorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deviceRegistrationControllerRegister: async (
      deviceAuthorization: DeviceAuthorization,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceAuthorization' is not null or undefined
      assertParamExists(
        "deviceRegistrationControllerRegister",
        "deviceAuthorization",
        deviceAuthorization
      );
      const localVarPath = `/device/authorization/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deviceAuthorization,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeviceAuthorizationApi - functional programming interface
 * @export
 */
export const DeviceAuthorizationApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    DeviceAuthorizationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {DeviceAuthorization} deviceAuthorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deviceRegistrationControllerRegister(
      deviceAuthorization: DeviceAuthorization,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DeviceRegistrationControllerRegister201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deviceRegistrationControllerRegister(
          deviceAuthorization,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "DeviceAuthorizationApi.deviceRegistrationControllerRegister"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DeviceAuthorizationApi - factory interface
 * @export
 */
export const DeviceAuthorizationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DeviceAuthorizationApiFp(configuration);
  return {
    /**
     *
     * @param {DeviceAuthorization} deviceAuthorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deviceRegistrationControllerRegister(
      deviceAuthorization: DeviceAuthorization,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<DeviceRegistrationControllerRegister201Response> {
      return localVarFp
        .deviceRegistrationControllerRegister(deviceAuthorization, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DeviceAuthorizationApi - object-oriented interface
 * @export
 * @class DeviceAuthorizationApi
 * @extends {BaseAPI}
 */
export class DeviceAuthorizationApi extends BaseAPI {
  /**
   *
   * @param {DeviceAuthorization} deviceAuthorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceAuthorizationApi
   */
  public deviceRegistrationControllerRegister(
    deviceAuthorization: DeviceAuthorization,
    options?: RawAxiosRequestConfig
  ) {
    return DeviceAuthorizationApiFp(this.configuration)
      .deviceRegistrationControllerRegister(deviceAuthorization, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GoogleCloudStorageApi - axios parameter creator
 * @export
 */
export const GoogleCloudStorageApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} filepath
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloudStorageControllerGetImage: async (
      filepath: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'filepath' is not null or undefined
      assertParamExists("cloudStorageControllerGetImage", "filepath", filepath);
      const localVarPath = `/api/v1/cloud/storage/asset/{filepath}`.replace(
        `{${"filepath"}}`,
        encodeURIComponent(String(filepath))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloudStorageControllerGetSampleAssets: async (
      params: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'params' is not null or undefined
      assertParamExists(
        "cloudStorageControllerGetSampleAssets",
        "params",
        params
      );
      const localVarPath = `/api/v1/cloud/storage/sample/{params}`.replace(
        `{${"params"}}`,
        encodeURIComponent(String(params))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloudStorageControllerUploadMedia: async (
      file: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("cloudStorageControllerUploadMedia", "file", file);
      const localVarPath = `/api/v1/cloud/storage/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GoogleCloudStorageApi - functional programming interface
 * @export
 */
export const GoogleCloudStorageApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    GoogleCloudStorageApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} filepath
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cloudStorageControllerGetImage(
      filepath: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cloudStorageControllerGetImage(
          filepath,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "GoogleCloudStorageApi.cloudStorageControllerGetImage"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cloudStorageControllerGetSampleAssets(
      params: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cloudStorageControllerGetSampleAssets(
          params,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "GoogleCloudStorageApi.cloudStorageControllerGetSampleAssets"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cloudStorageControllerUploadMedia(
      file: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cloudStorageControllerUploadMedia(
          file,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "GoogleCloudStorageApi.cloudStorageControllerUploadMedia"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * GoogleCloudStorageApi - factory interface
 * @export
 */
export const GoogleCloudStorageApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GoogleCloudStorageApiFp(configuration);
  return {
    /**
     *
     * @param {string} filepath
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloudStorageControllerGetImage(
      filepath: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<File> {
      return localVarFp
        .cloudStorageControllerGetImage(filepath, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloudStorageControllerGetSampleAssets(
      params: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .cloudStorageControllerGetSampleAssets(params, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cloudStorageControllerUploadMedia(
      file: File,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .cloudStorageControllerUploadMedia(file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GoogleCloudStorageApi - object-oriented interface
 * @export
 * @class GoogleCloudStorageApi
 * @extends {BaseAPI}
 */
export class GoogleCloudStorageApi extends BaseAPI {
  /**
   *
   * @param {string} filepath
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleCloudStorageApi
   */
  public cloudStorageControllerGetImage(
    filepath: string,
    options?: RawAxiosRequestConfig
  ) {
    return GoogleCloudStorageApiFp(this.configuration)
      .cloudStorageControllerGetImage(filepath, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} params
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleCloudStorageApi
   */
  public cloudStorageControllerGetSampleAssets(
    params: string,
    options?: RawAxiosRequestConfig
  ) {
    return GoogleCloudStorageApiFp(this.configuration)
      .cloudStorageControllerGetSampleAssets(params, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {File} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogleCloudStorageApi
   */
  public cloudStorageControllerUploadMedia(
    file: File,
    options?: RawAxiosRequestConfig
  ) {
    return GoogleCloudStorageApiFp(this.configuration)
      .cloudStorageControllerUploadMedia(file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HouseholdApi - axios parameter creator
 * @export
 */
export const HouseholdApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateHouseholBasedDto} household
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    householdControllerCreate: async (
      household: CreateHouseholBasedDto,
      file?: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'household' is not null or undefined
      assertParamExists("householdControllerCreate", "household", household);
      const localVarPath = `/api/v1/household/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (household !== undefined) {
        localVarFormParams.append("household", JSON.stringify(household));
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteHouseholdDto} deleteHouseholdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    householdControllerDelete: async (
      deleteHouseholdDto: DeleteHouseholdDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteHouseholdDto' is not null or undefined
      assertParamExists(
        "householdControllerDelete",
        "deleteHouseholdDto",
        deleteHouseholdDto
      );
      const localVarPath = `/api/v1/household/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteHouseholdDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditHouseholdBaseDto} [household]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    householdControllerEdit: async (
      household?: EditHouseholdBaseDto,
      file?: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/household/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (household !== undefined) {
        localVarFormParams.append("household", JSON.stringify(household));
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    householdControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/household/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HouseholdApi - functional programming interface
 * @export
 */
export const HouseholdApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    HouseholdApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateHouseholBasedDto} household
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async householdControllerCreate(
      household: CreateHouseholBasedDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.householdControllerCreate(
          household,
          file,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["HouseholdApi.householdControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteHouseholdDto} deleteHouseholdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async householdControllerDelete(
      deleteHouseholdDto: DeleteHouseholdDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.householdControllerDelete(
          deleteHouseholdDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["HouseholdApi.householdControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditHouseholdBaseDto} [household]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async householdControllerEdit(
      household?: EditHouseholdBaseDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.householdControllerEdit(
          household,
          file,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["HouseholdApi.householdControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async householdControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<HouseholdControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.householdControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["HouseholdApi.householdControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HouseholdApi - factory interface
 * @export
 */
export const HouseholdApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HouseholdApiFp(configuration);
  return {
    /**
     *
     * @param {CreateHouseholBasedDto} household
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    householdControllerCreate(
      household: CreateHouseholBasedDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .householdControllerCreate(household, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteHouseholdDto} deleteHouseholdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    householdControllerDelete(
      deleteHouseholdDto: DeleteHouseholdDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .householdControllerDelete(deleteHouseholdDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditHouseholdBaseDto} [household]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    householdControllerEdit(
      household?: EditHouseholdBaseDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .householdControllerEdit(household, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    householdControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<HouseholdControllerGet200Response> {
      return localVarFp
        .householdControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HouseholdApi - object-oriented interface
 * @export
 * @class HouseholdApi
 * @extends {BaseAPI}
 */
export class HouseholdApi extends BaseAPI {
  /**
   *
   * @param {CreateHouseholBasedDto} household
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseholdApi
   */
  public householdControllerCreate(
    household: CreateHouseholBasedDto,
    file?: File,
    options?: RawAxiosRequestConfig
  ) {
    return HouseholdApiFp(this.configuration)
      .householdControllerCreate(household, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteHouseholdDto} deleteHouseholdDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseholdApi
   */
  public householdControllerDelete(
    deleteHouseholdDto: DeleteHouseholdDto,
    options?: RawAxiosRequestConfig
  ) {
    return HouseholdApiFp(this.configuration)
      .householdControllerDelete(deleteHouseholdDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditHouseholdBaseDto} [household]
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseholdApi
   */
  public householdControllerEdit(
    household?: EditHouseholdBaseDto,
    file?: File,
    options?: RawAxiosRequestConfig
  ) {
    return HouseholdApiFp(this.configuration)
      .householdControllerEdit(household, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HouseholdApi
   */
  public householdControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return HouseholdApiFp(this.configuration)
      .householdControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InhabitantApi - axios parameter creator
 * @export
 */
export const InhabitantApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateInhabitantBaseDto} inhabitant
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inhabitantControllerCreate: async (
      inhabitant: CreateInhabitantBaseDto,
      file?: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'inhabitant' is not null or undefined
      assertParamExists("inhabitantControllerCreate", "inhabitant", inhabitant);
      const localVarPath = `/api/v1/inhabitant/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (inhabitant !== undefined) {
        localVarFormParams.append("inhabitant", JSON.stringify(inhabitant));
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteInhabitantDto} deleteInhabitantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inhabitantControllerDelete: async (
      deleteInhabitantDto: DeleteInhabitantDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteInhabitantDto' is not null or undefined
      assertParamExists(
        "inhabitantControllerDelete",
        "deleteInhabitantDto",
        deleteInhabitantDto
      );
      const localVarPath = `/api/v1/inhabitant/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteInhabitantDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditInhabitantBaseDto} inhabitant
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inhabitantControllerEdit: async (
      inhabitant: EditInhabitantBaseDto,
      file?: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'inhabitant' is not null or undefined
      assertParamExists("inhabitantControllerEdit", "inhabitant", inhabitant);
      const localVarPath = `/api/v1/inhabitant/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (inhabitant !== undefined) {
        localVarFormParams.append("inhabitant", JSON.stringify(inhabitant));
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inhabitantControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/inhabitant/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InhabitantApi - functional programming interface
 * @export
 */
export const InhabitantApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InhabitantApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateInhabitantBaseDto} inhabitant
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inhabitantControllerCreate(
      inhabitant: CreateInhabitantBaseDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.inhabitantControllerCreate(
          inhabitant,
          file,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["InhabitantApi.inhabitantControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteInhabitantDto} deleteInhabitantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inhabitantControllerDelete(
      deleteInhabitantDto: DeleteInhabitantDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.inhabitantControllerDelete(
          deleteInhabitantDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["InhabitantApi.inhabitantControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditInhabitantBaseDto} inhabitant
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inhabitantControllerEdit(
      inhabitant: EditInhabitantBaseDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.inhabitantControllerEdit(
          inhabitant,
          file,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["InhabitantApi.inhabitantControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inhabitantControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InhabitantControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.inhabitantControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["InhabitantApi.inhabitantControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * InhabitantApi - factory interface
 * @export
 */
export const InhabitantApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InhabitantApiFp(configuration);
  return {
    /**
     *
     * @param {CreateInhabitantBaseDto} inhabitant
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inhabitantControllerCreate(
      inhabitant: CreateInhabitantBaseDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .inhabitantControllerCreate(inhabitant, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteInhabitantDto} deleteInhabitantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inhabitantControllerDelete(
      deleteInhabitantDto: DeleteInhabitantDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .inhabitantControllerDelete(deleteInhabitantDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditInhabitantBaseDto} inhabitant
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inhabitantControllerEdit(
      inhabitant: EditInhabitantBaseDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .inhabitantControllerEdit(inhabitant, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inhabitantControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<InhabitantControllerGet200Response> {
      return localVarFp
        .inhabitantControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InhabitantApi - object-oriented interface
 * @export
 * @class InhabitantApi
 * @extends {BaseAPI}
 */
export class InhabitantApi extends BaseAPI {
  /**
   *
   * @param {CreateInhabitantBaseDto} inhabitant
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InhabitantApi
   */
  public inhabitantControllerCreate(
    inhabitant: CreateInhabitantBaseDto,
    file?: File,
    options?: RawAxiosRequestConfig
  ) {
    return InhabitantApiFp(this.configuration)
      .inhabitantControllerCreate(inhabitant, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteInhabitantDto} deleteInhabitantDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InhabitantApi
   */
  public inhabitantControllerDelete(
    deleteInhabitantDto: DeleteInhabitantDto,
    options?: RawAxiosRequestConfig
  ) {
    return InhabitantApiFp(this.configuration)
      .inhabitantControllerDelete(deleteInhabitantDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditInhabitantBaseDto} inhabitant
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InhabitantApi
   */
  public inhabitantControllerEdit(
    inhabitant: EditInhabitantBaseDto,
    file?: File,
    options?: RawAxiosRequestConfig
  ) {
    return InhabitantApiFp(this.configuration)
      .inhabitantControllerEdit(inhabitant, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InhabitantApi
   */
  public inhabitantControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return InhabitantApiFp(this.configuration)
      .inhabitantControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MeetingApi - axios parameter creator
 * @export
 */
export const MeetingApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateMeetingDto} createMeetingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meetingControllerCreate: async (
      createMeetingDto: CreateMeetingDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createMeetingDto' is not null or undefined
      assertParamExists(
        "meetingControllerCreate",
        "createMeetingDto",
        createMeetingDto
      );
      const localVarPath = `/api/v1/meeting/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createMeetingDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteMeetingDto} deleteMeetingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meetingControllerDelete: async (
      deleteMeetingDto: DeleteMeetingDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteMeetingDto' is not null or undefined
      assertParamExists(
        "meetingControllerDelete",
        "deleteMeetingDto",
        deleteMeetingDto
      );
      const localVarPath = `/api/v1/meeting/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteMeetingDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditMeetingDto} editMeetingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meetingControllerEdit: async (
      editMeetingDto: EditMeetingDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'editMeetingDto' is not null or undefined
      assertParamExists(
        "meetingControllerEdit",
        "editMeetingDto",
        editMeetingDto
      );
      const localVarPath = `/api/v1/meeting/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editMeetingDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meetingControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/meeting/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MeetingApi - functional programming interface
 * @export
 */
export const MeetingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MeetingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateMeetingDto} createMeetingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meetingControllerCreate(
      createMeetingDto: CreateMeetingDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meetingControllerCreate(
          createMeetingDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeetingApi.meetingControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteMeetingDto} deleteMeetingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meetingControllerDelete(
      deleteMeetingDto: DeleteMeetingDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meetingControllerDelete(
          deleteMeetingDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeetingApi.meetingControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditMeetingDto} editMeetingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meetingControllerEdit(
      editMeetingDto: EditMeetingDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meetingControllerEdit(
          editMeetingDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeetingApi.meetingControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meetingControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MeetingControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meetingControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MeetingApi.meetingControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * MeetingApi - factory interface
 * @export
 */
export const MeetingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MeetingApiFp(configuration);
  return {
    /**
     *
     * @param {CreateMeetingDto} createMeetingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meetingControllerCreate(
      createMeetingDto: CreateMeetingDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .meetingControllerCreate(createMeetingDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteMeetingDto} deleteMeetingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meetingControllerDelete(
      deleteMeetingDto: DeleteMeetingDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .meetingControllerDelete(deleteMeetingDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditMeetingDto} editMeetingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meetingControllerEdit(
      editMeetingDto: EditMeetingDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .meetingControllerEdit(editMeetingDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meetingControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MeetingControllerGet200Response> {
      return localVarFp
        .meetingControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MeetingApi - object-oriented interface
 * @export
 * @class MeetingApi
 * @extends {BaseAPI}
 */
export class MeetingApi extends BaseAPI {
  /**
   *
   * @param {CreateMeetingDto} createMeetingDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeetingApi
   */
  public meetingControllerCreate(
    createMeetingDto: CreateMeetingDto,
    options?: RawAxiosRequestConfig
  ) {
    return MeetingApiFp(this.configuration)
      .meetingControllerCreate(createMeetingDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteMeetingDto} deleteMeetingDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeetingApi
   */
  public meetingControllerDelete(
    deleteMeetingDto: DeleteMeetingDto,
    options?: RawAxiosRequestConfig
  ) {
    return MeetingApiFp(this.configuration)
      .meetingControllerDelete(deleteMeetingDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditMeetingDto} editMeetingDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeetingApi
   */
  public meetingControllerEdit(
    editMeetingDto: EditMeetingDto,
    options?: RawAxiosRequestConfig
  ) {
    return MeetingApiFp(this.configuration)
      .meetingControllerEdit(editMeetingDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeetingApi
   */
  public meetingControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return MeetingApiFp(this.configuration)
      .meetingControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PersonApi - axios parameter creator
 * @export
 */
export const PersonApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} code
     * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerAttachFile: async (
      code: string,
      attachmentType: string,
      file?: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("personControllerAttachFile", "code", code);
      // verify required parameter 'attachmentType' is not null or undefined
      assertParamExists(
        "personControllerAttachFile",
        "attachmentType",
        attachmentType
      );
      const localVarPath = `/api/v1/person/attach-file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarFormParams.append("code", code as any);
      }

      if (attachmentType !== undefined) {
        localVarFormParams.append("attachment_type", attachmentType as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {BlockPersonDto} blockPersonDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerBlock: async (
      blockPersonDto: BlockPersonDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'blockPersonDto' is not null or undefined
      assertParamExists(
        "personControllerBlock",
        "blockPersonDto",
        blockPersonDto
      );
      const localVarPath = `/api/v1/person/block`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        blockPersonDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreatePersonExtendedDto} person
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerCreate: async (
      person: CreatePersonExtendedDto,
      file?: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("personControllerCreate", "person", person);
      const localVarPath = `/api/v1/person/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (person !== undefined) {
        localVarFormParams.append(
          "person",
          JSON.stringify(person)
        );
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeletePersonDto} deletePersonDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerDelete: async (
      deletePersonDto: DeletePersonDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deletePersonDto' is not null or undefined
      assertParamExists(
        "personControllerDelete",
        "deletePersonDto",
        deletePersonDto
      );
      const localVarPath = `/api/v1/person/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deletePersonDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditPersonExtendedDto} person
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerEdit: async (
      person: EditPersonExtendedDto,
      file?: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("personControllerEdit", "person", person);
      const localVarPath = `/api/v1/person/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (person !== undefined) {
        localVarFormParams.append(
          "person",
          JSON.stringify(person)
        );
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/person/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PersonApi - functional programming interface
 * @export
 */
export const PersonApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PersonApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerAttachFile(
      code: string,
      attachmentType: string,
      file?: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personControllerAttachFile(
          code,
          attachmentType,
          file,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonApi.personControllerAttachFile"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {BlockPersonDto} blockPersonDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerBlock(
      blockPersonDto: BlockPersonDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personControllerBlock(
          blockPersonDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonApi.personControllerBlock"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {CreatePersonExtendedDto} person
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerCreate(
      person: CreatePersonExtendedDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personControllerCreate(
          person,
          file,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonApi.personControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeletePersonDto} deletePersonDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerDelete(
      deletePersonDto: DeletePersonDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personControllerDelete(
          deletePersonDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonApi.personControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditPersonExtendedDto} person
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerEdit(
      person: EditPersonExtendedDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personControllerEdit(
          person,
          file,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonApi.personControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PersonControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonApi.personControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PersonApi - factory interface
 * @export
 */
export const PersonApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PersonApiFp(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerAttachFile(
      code: string,
      attachmentType: string,
      file?: File,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .personControllerAttachFile(code, attachmentType, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {BlockPersonDto} blockPersonDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerBlock(
      blockPersonDto: BlockPersonDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .personControllerBlock(blockPersonDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreatePersonExtendedDto} person
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerCreate(
      person: CreatePersonExtendedDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .personControllerCreate(person, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeletePersonDto} deletePersonDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerDelete(
      deletePersonDto: DeletePersonDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .personControllerDelete(deletePersonDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditPersonExtendedDto} person
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerEdit(
      person: EditPersonExtendedDto,
      file?: File,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .personControllerEdit(person, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<PersonControllerGet200Response> {
      return localVarFp
        .personControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PersonApi - object-oriented interface
 * @export
 * @class PersonApi
 * @extends {BaseAPI}
 */
export class PersonApi extends BaseAPI {
  /**
   *
   * @param {string} code
   * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public personControllerAttachFile(
    code: string,
    attachmentType: string,
    file?: File,
    options?: RawAxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .personControllerAttachFile(code, attachmentType, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BlockPersonDto} blockPersonDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public personControllerBlock(
    blockPersonDto: BlockPersonDto,
    options?: RawAxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .personControllerBlock(blockPersonDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreatePersonExtendedDto} person
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public personControllerCreate(
    person: CreatePersonExtendedDto,
    file?: File,
    options?: RawAxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .personControllerCreate(person, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeletePersonDto} deletePersonDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public personControllerDelete(
    deletePersonDto: DeletePersonDto,
    options?: RawAxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .personControllerDelete(deletePersonDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditPersonExtendedDto} person
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public personControllerEdit(
    person: EditPersonExtendedDto,
    file?: File,
    options?: RawAxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .personControllerEdit(person, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public personControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .personControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PersonAddressApi - axios parameter creator
 * @export
 */
export const PersonAddressApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreatePersonAddressDto} createPersonAddressDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personAddressControllerCreate: async (
      createPersonAddressDto: CreatePersonAddressDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPersonAddressDto' is not null or undefined
      assertParamExists(
        "personAddressControllerCreate",
        "createPersonAddressDto",
        createPersonAddressDto
      );
      const localVarPath = `/api/v1/person-address/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPersonAddressDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeletePersonAddressDto} deletePersonAddressDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personAddressControllerDelete: async (
      deletePersonAddressDto: DeletePersonAddressDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deletePersonAddressDto' is not null or undefined
      assertParamExists(
        "personAddressControllerDelete",
        "deletePersonAddressDto",
        deletePersonAddressDto
      );
      const localVarPath = `/api/v1/person-address/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deletePersonAddressDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditPersonAddressDto} editPersonAddressDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personAddressControllerEdit: async (
      editPersonAddressDto: EditPersonAddressDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'editPersonAddressDto' is not null or undefined
      assertParamExists(
        "personAddressControllerEdit",
        "editPersonAddressDto",
        editPersonAddressDto
      );
      const localVarPath = `/api/v1/person-address/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editPersonAddressDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personAddressControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/person-address/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PersonAddressApi - functional programming interface
 * @export
 */
export const PersonAddressApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PersonAddressApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreatePersonAddressDto} createPersonAddressDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personAddressControllerCreate(
      createPersonAddressDto: CreatePersonAddressDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personAddressControllerCreate(
          createPersonAddressDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonAddressApi.personAddressControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeletePersonAddressDto} deletePersonAddressDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personAddressControllerDelete(
      deletePersonAddressDto: DeletePersonAddressDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personAddressControllerDelete(
          deletePersonAddressDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonAddressApi.personAddressControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditPersonAddressDto} editPersonAddressDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personAddressControllerEdit(
      editPersonAddressDto: EditPersonAddressDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personAddressControllerEdit(
          editPersonAddressDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonAddressApi.personAddressControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personAddressControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PersonAddressControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.personAddressControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["PersonAddressApi.personAddressControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PersonAddressApi - factory interface
 * @export
 */
export const PersonAddressApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PersonAddressApiFp(configuration);
  return {
    /**
     *
     * @param {CreatePersonAddressDto} createPersonAddressDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personAddressControllerCreate(
      createPersonAddressDto: CreatePersonAddressDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .personAddressControllerCreate(createPersonAddressDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeletePersonAddressDto} deletePersonAddressDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personAddressControllerDelete(
      deletePersonAddressDto: DeletePersonAddressDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .personAddressControllerDelete(deletePersonAddressDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditPersonAddressDto} editPersonAddressDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personAddressControllerEdit(
      editPersonAddressDto: EditPersonAddressDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .personAddressControllerEdit(editPersonAddressDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personAddressControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<PersonAddressControllerGet200Response> {
      return localVarFp
        .personAddressControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PersonAddressApi - object-oriented interface
 * @export
 * @class PersonAddressApi
 * @extends {BaseAPI}
 */
export class PersonAddressApi extends BaseAPI {
  /**
   *
   * @param {CreatePersonAddressDto} createPersonAddressDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonAddressApi
   */
  public personAddressControllerCreate(
    createPersonAddressDto: CreatePersonAddressDto,
    options?: RawAxiosRequestConfig
  ) {
    return PersonAddressApiFp(this.configuration)
      .personAddressControllerCreate(createPersonAddressDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeletePersonAddressDto} deletePersonAddressDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonAddressApi
   */
  public personAddressControllerDelete(
    deletePersonAddressDto: DeletePersonAddressDto,
    options?: RawAxiosRequestConfig
  ) {
    return PersonAddressApiFp(this.configuration)
      .personAddressControllerDelete(deletePersonAddressDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditPersonAddressDto} editPersonAddressDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonAddressApi
   */
  public personAddressControllerEdit(
    editPersonAddressDto: EditPersonAddressDto,
    options?: RawAxiosRequestConfig
  ) {
    return PersonAddressApiFp(this.configuration)
      .personAddressControllerEdit(editPersonAddressDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonAddressApi
   */
  public personAddressControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return PersonAddressApiFp(this.configuration)
      .personAddressControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SchedulerApi - axios parameter creator
 * @export
 */
export const SchedulerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulerControllerNoClockout: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/scheduler/default`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SchedulerApi - functional programming interface
 * @export
 */
export const SchedulerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SchedulerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schedulerControllerNoClockout(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.schedulerControllerNoClockout(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["SchedulerApi.schedulerControllerNoClockout"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SchedulerApi - factory interface
 * @export
 */
export const SchedulerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SchedulerApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulerControllerNoClockout(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .schedulerControllerNoClockout(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SchedulerApi - object-oriented interface
 * @export
 * @class SchedulerApi
 * @extends {BaseAPI}
 */
export class SchedulerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchedulerApi
   */
  public schedulerControllerNoClockout(options?: RawAxiosRequestConfig) {
    return SchedulerApiFp(this.configuration)
      .schedulerControllerNoClockout(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} code
     * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerAttachFile: async (
      code: string,
      attachmentType: string,
      file?: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists("tenantControllerAttachFile", "code", code);
      // verify required parameter 'attachmentType' is not null or undefined
      assertParamExists(
        "tenantControllerAttachFile",
        "attachmentType",
        attachmentType
      );
      const localVarPath = `/api/v1/tenant/attach-file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarFormParams.append("code", code as any);
      }

      if (attachmentType !== undefined) {
        localVarFormParams.append("attachment_type", attachmentType as any);
      }

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateTenantDto} createTenantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerCreate: async (
      createTenantDto: CreateTenantDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTenantDto' is not null or undefined
      assertParamExists(
        "tenantControllerCreate",
        "createTenantDto",
        createTenantDto
      );
      const localVarPath = `/api/v1/tenant/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTenantDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteTenantDto} deleteTenantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerDelete: async (
      deleteTenantDto: DeleteTenantDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteTenantDto' is not null or undefined
      assertParamExists(
        "tenantControllerDelete",
        "deleteTenantDto",
        deleteTenantDto
      );
      const localVarPath = `/api/v1/tenant/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteTenantDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditTenantDto} editTenantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerEdit: async (
      editTenantDto: EditTenantDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'editTenantDto' is not null or undefined
      assertParamExists("tenantControllerEdit", "editTenantDto", editTenantDto);
      const localVarPath = `/api/v1/tenant/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editTenantDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tenant/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantControllerAttachFile(
      code: string,
      attachmentType: string,
      file?: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantControllerAttachFile(
          code,
          attachmentType,
          file,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantControllerAttachFile"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {CreateTenantDto} createTenantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantControllerCreate(
      createTenantDto: CreateTenantDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantControllerCreate(
          createTenantDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteTenantDto} deleteTenantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantControllerDelete(
      deleteTenantDto: DeleteTenantDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantControllerDelete(
          deleteTenantDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditTenantDto} editTenantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantControllerEdit(
      editTenantDto: EditTenantDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantControllerEdit(
          editTenantDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tenantControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<TenantControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tenantControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantApi.tenantControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TenantApiFp(configuration);
  return {
    /**
     *
     * @param {string} code
     * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerAttachFile(
      code: string,
      attachmentType: string,
      file?: File,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .tenantControllerAttachFile(code, attachmentType, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateTenantDto} createTenantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerCreate(
      createTenantDto: CreateTenantDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .tenantControllerCreate(createTenantDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteTenantDto} deleteTenantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerDelete(
      deleteTenantDto: DeleteTenantDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .tenantControllerDelete(deleteTenantDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditTenantDto} editTenantDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerEdit(
      editTenantDto: EditTenantDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .tenantControllerEdit(editTenantDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tenantControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<TenantControllerGet200Response> {
      return localVarFp
        .tenantControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
  /**
   *
   * @param {string} code
   * @param {string} attachmentType curriculum_vitae, supporting_doc, intent_letter, application_letter, avatar
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantControllerAttachFile(
    code: string,
    attachmentType: string,
    file?: File,
    options?: RawAxiosRequestConfig
  ) {
    return TenantApiFp(this.configuration)
      .tenantControllerAttachFile(code, attachmentType, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateTenantDto} createTenantDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantControllerCreate(
    createTenantDto: CreateTenantDto,
    options?: RawAxiosRequestConfig
  ) {
    return TenantApiFp(this.configuration)
      .tenantControllerCreate(createTenantDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteTenantDto} deleteTenantDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantControllerDelete(
    deleteTenantDto: DeleteTenantDto,
    options?: RawAxiosRequestConfig
  ) {
    return TenantApiFp(this.configuration)
      .tenantControllerDelete(deleteTenantDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditTenantDto} editTenantDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantControllerEdit(
    editTenantDto: EditTenantDto,
    options?: RawAxiosRequestConfig
  ) {
    return TenantApiFp(this.configuration)
      .tenantControllerEdit(editTenantDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public tenantControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return TenantApiFp(this.configuration)
      .tenantControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UtilitiesApi - axios parameter creator
 * @export
 */
export const UtilitiesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} region
     * @param {string} province
     * @param {string} city
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerBarangay: async (
      region: string,
      province: string,
      city: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'region' is not null or undefined
      assertParamExists("utilitiesControllerBarangay", "region", region);
      // verify required parameter 'province' is not null or undefined
      assertParamExists("utilitiesControllerBarangay", "province", province);
      // verify required parameter 'city' is not null or undefined
      assertParamExists("utilitiesControllerBarangay", "city", city);
      const localVarPath =
        `/api/v1/utilities/barangay/{region}/{province}/{city}`
          .replace(`{${"region"}}`, encodeURIComponent(String(region)))
          .replace(`{${"province"}}`, encodeURIComponent(String(province)))
          .replace(`{${"city"}}`, encodeURIComponent(String(city)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} region
     * @param {string} province
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerCities: async (
      region: string,
      province: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'region' is not null or undefined
      assertParamExists("utilitiesControllerCities", "region", region);
      // verify required parameter 'province' is not null or undefined
      assertParamExists("utilitiesControllerCities", "province", province);
      const localVarPath = `/api/v1/utilities/cities/{province}/{region}`
        .replace(`{${"region"}}`, encodeURIComponent(String(region)))
        .replace(`{${"province"}}`, encodeURIComponent(String(province)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerCivilStatus: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/utilities/civil-status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerGender: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/utilities/gender`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerGetRegions: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/utilities/regions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerProvinces: async (
      region: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'region' is not null or undefined
      assertParamExists("utilitiesControllerProvinces", "region", region);
      const localVarPath = `/api/v1/utilities/provinces/{region}`.replace(
        `{${"region"}}`,
        encodeURIComponent(String(region))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerPurok: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/utilities/purok`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UtilitiesApi - functional programming interface
 * @export
 */
export const UtilitiesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UtilitiesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} region
     * @param {string} province
     * @param {string} city
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilitiesControllerBarangay(
      region: string,
      province: string,
      city: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilitiesControllerBarangay(
          region,
          province,
          city,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilitiesApi.utilitiesControllerBarangay"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} region
     * @param {string} province
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilitiesControllerCities(
      region: string,
      province: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilitiesControllerCities(
          region,
          province,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilitiesApi.utilitiesControllerCities"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilitiesControllerCivilStatus(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilitiesControllerCivilStatus(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilitiesApi.utilitiesControllerCivilStatus"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilitiesControllerGender(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilitiesControllerGender(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilitiesApi.utilitiesControllerGender"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilitiesControllerGetRegions(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilitiesControllerGetRegions(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilitiesApi.utilitiesControllerGetRegions"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilitiesControllerProvinces(
      region: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilitiesControllerProvinces(
          region,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilitiesApi.utilitiesControllerProvinces"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilitiesControllerPurok(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilitiesControllerPurok(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilitiesApi.utilitiesControllerPurok"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UtilitiesApi - factory interface
 * @export
 */
export const UtilitiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UtilitiesApiFp(configuration);
  return {
    /**
     *
     * @param {string} region
     * @param {string} province
     * @param {string} city
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerBarangay(
      region: string,
      province: string,
      city: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilitiesControllerBarangay(region, province, city, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} region
     * @param {string} province
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerCities(
      region: string,
      province: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilitiesControllerCities(region, province, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerCivilStatus(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilitiesControllerCivilStatus(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerGender(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilitiesControllerGender(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerGetRegions(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilitiesControllerGetRegions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerProvinces(
      region: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilitiesControllerProvinces(region, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilitiesControllerPurok(
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilitiesControllerPurok(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UtilitiesApi - object-oriented interface
 * @export
 * @class UtilitiesApi
 * @extends {BaseAPI}
 */
export class UtilitiesApi extends BaseAPI {
  /**
   *
   * @param {string} region
   * @param {string} province
   * @param {string} city
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilitiesApi
   */
  public utilitiesControllerBarangay(
    region: string,
    province: string,
    city: string,
    options?: RawAxiosRequestConfig
  ) {
    return UtilitiesApiFp(this.configuration)
      .utilitiesControllerBarangay(region, province, city, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} region
   * @param {string} province
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilitiesApi
   */
  public utilitiesControllerCities(
    region: string,
    province: string,
    options?: RawAxiosRequestConfig
  ) {
    return UtilitiesApiFp(this.configuration)
      .utilitiesControllerCities(region, province, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilitiesApi
   */
  public utilitiesControllerCivilStatus(options?: RawAxiosRequestConfig) {
    return UtilitiesApiFp(this.configuration)
      .utilitiesControllerCivilStatus(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilitiesApi
   */
  public utilitiesControllerGender(options?: RawAxiosRequestConfig) {
    return UtilitiesApiFp(this.configuration)
      .utilitiesControllerGender(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilitiesApi
   */
  public utilitiesControllerGetRegions(options?: RawAxiosRequestConfig) {
    return UtilitiesApiFp(this.configuration)
      .utilitiesControllerGetRegions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} region
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilitiesApi
   */
  public utilitiesControllerProvinces(
    region: string,
    options?: RawAxiosRequestConfig
  ) {
    return UtilitiesApiFp(this.configuration)
      .utilitiesControllerProvinces(region, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilitiesApi
   */
  public utilitiesControllerPurok(options?: RawAxiosRequestConfig) {
    return UtilitiesApiFp(this.configuration)
      .utilitiesControllerPurok(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UtilityAttachmentTypeApi - axios parameter creator
 * @export
 */
export const UtilityAttachmentTypeApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateAttachmentTypeDto} createAttachmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilAttachmentTypeControllerCreate: async (
      createAttachmentTypeDto: CreateAttachmentTypeDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAttachmentTypeDto' is not null or undefined
      assertParamExists(
        "utilAttachmentTypeControllerCreate",
        "createAttachmentTypeDto",
        createAttachmentTypeDto
      );
      const localVarPath = `/api/v1/util-attachment-type/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAttachmentTypeDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteAttachmentTypeDto} deleteAttachmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilAttachmentTypeControllerDelete: async (
      deleteAttachmentTypeDto: DeleteAttachmentTypeDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteAttachmentTypeDto' is not null or undefined
      assertParamExists(
        "utilAttachmentTypeControllerDelete",
        "deleteAttachmentTypeDto",
        deleteAttachmentTypeDto
      );
      const localVarPath = `/api/v1/util-attachment-type/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteAttachmentTypeDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditAttachmentTypeDto} editAttachmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilAttachmentTypeControllerEdit: async (
      editAttachmentTypeDto: EditAttachmentTypeDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'editAttachmentTypeDto' is not null or undefined
      assertParamExists(
        "utilAttachmentTypeControllerEdit",
        "editAttachmentTypeDto",
        editAttachmentTypeDto
      );
      const localVarPath = `/api/v1/util-attachment-type/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editAttachmentTypeDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilAttachmentTypeControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/util-attachment-type/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UtilityAttachmentTypeApi - functional programming interface
 * @export
 */
export const UtilityAttachmentTypeApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    UtilityAttachmentTypeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateAttachmentTypeDto} createAttachmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilAttachmentTypeControllerCreate(
      createAttachmentTypeDto: CreateAttachmentTypeDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilAttachmentTypeControllerCreate(
          createAttachmentTypeDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "UtilityAttachmentTypeApi.utilAttachmentTypeControllerCreate"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteAttachmentTypeDto} deleteAttachmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilAttachmentTypeControllerDelete(
      deleteAttachmentTypeDto: DeleteAttachmentTypeDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilAttachmentTypeControllerDelete(
          deleteAttachmentTypeDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "UtilityAttachmentTypeApi.utilAttachmentTypeControllerDelete"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditAttachmentTypeDto} editAttachmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilAttachmentTypeControllerEdit(
      editAttachmentTypeDto: EditAttachmentTypeDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilAttachmentTypeControllerEdit(
          editAttachmentTypeDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "UtilityAttachmentTypeApi.utilAttachmentTypeControllerEdit"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilAttachmentTypeControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UtilAttachmentTypeControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilAttachmentTypeControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "UtilityAttachmentTypeApi.utilAttachmentTypeControllerGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UtilityAttachmentTypeApi - factory interface
 * @export
 */
export const UtilityAttachmentTypeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UtilityAttachmentTypeApiFp(configuration);
  return {
    /**
     *
     * @param {CreateAttachmentTypeDto} createAttachmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilAttachmentTypeControllerCreate(
      createAttachmentTypeDto: CreateAttachmentTypeDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilAttachmentTypeControllerCreate(createAttachmentTypeDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteAttachmentTypeDto} deleteAttachmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilAttachmentTypeControllerDelete(
      deleteAttachmentTypeDto: DeleteAttachmentTypeDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilAttachmentTypeControllerDelete(deleteAttachmentTypeDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditAttachmentTypeDto} editAttachmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilAttachmentTypeControllerEdit(
      editAttachmentTypeDto: EditAttachmentTypeDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilAttachmentTypeControllerEdit(editAttachmentTypeDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilAttachmentTypeControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<UtilAttachmentTypeControllerGet200Response> {
      return localVarFp
        .utilAttachmentTypeControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UtilityAttachmentTypeApi - object-oriented interface
 * @export
 * @class UtilityAttachmentTypeApi
 * @extends {BaseAPI}
 */
export class UtilityAttachmentTypeApi extends BaseAPI {
  /**
   *
   * @param {CreateAttachmentTypeDto} createAttachmentTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityAttachmentTypeApi
   */
  public utilAttachmentTypeControllerCreate(
    createAttachmentTypeDto: CreateAttachmentTypeDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityAttachmentTypeApiFp(this.configuration)
      .utilAttachmentTypeControllerCreate(createAttachmentTypeDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteAttachmentTypeDto} deleteAttachmentTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityAttachmentTypeApi
   */
  public utilAttachmentTypeControllerDelete(
    deleteAttachmentTypeDto: DeleteAttachmentTypeDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityAttachmentTypeApiFp(this.configuration)
      .utilAttachmentTypeControllerDelete(deleteAttachmentTypeDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditAttachmentTypeDto} editAttachmentTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityAttachmentTypeApi
   */
  public utilAttachmentTypeControllerEdit(
    editAttachmentTypeDto: EditAttachmentTypeDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityAttachmentTypeApiFp(this.configuration)
      .utilAttachmentTypeControllerEdit(editAttachmentTypeDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityAttachmentTypeApi
   */
  public utilAttachmentTypeControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityAttachmentTypeApiFp(this.configuration)
      .utilAttachmentTypeControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UtilityBranchApi - axios parameter creator
 * @export
 */
export const UtilityBranchApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateBranchDto} createBranchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilBranchControllerCreate: async (
      createBranchDto: CreateBranchDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createBranchDto' is not null or undefined
      assertParamExists(
        "utilBranchControllerCreate",
        "createBranchDto",
        createBranchDto
      );
      const localVarPath = `/api/v1/util-branch/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createBranchDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteBranchDto} deleteBranchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilBranchControllerDelete: async (
      deleteBranchDto: DeleteBranchDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteBranchDto' is not null or undefined
      assertParamExists(
        "utilBranchControllerDelete",
        "deleteBranchDto",
        deleteBranchDto
      );
      const localVarPath = `/api/v1/util-branch/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteBranchDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditBranchDto} editBranchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilBranchControllerEdit: async (
      editBranchDto: EditBranchDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'editBranchDto' is not null or undefined
      assertParamExists(
        "utilBranchControllerEdit",
        "editBranchDto",
        editBranchDto
      );
      const localVarPath = `/api/v1/util-branch/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editBranchDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilBranchControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/util-branch/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UtilityBranchApi - functional programming interface
 * @export
 */
export const UtilityBranchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UtilityBranchApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateBranchDto} createBranchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilBranchControllerCreate(
      createBranchDto: CreateBranchDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilBranchControllerCreate(
          createBranchDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityBranchApi.utilBranchControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteBranchDto} deleteBranchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilBranchControllerDelete(
      deleteBranchDto: DeleteBranchDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilBranchControllerDelete(
          deleteBranchDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityBranchApi.utilBranchControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditBranchDto} editBranchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilBranchControllerEdit(
      editBranchDto: EditBranchDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilBranchControllerEdit(
          editBranchDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityBranchApi.utilBranchControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilBranchControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UtilBranchControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilBranchControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityBranchApi.utilBranchControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UtilityBranchApi - factory interface
 * @export
 */
export const UtilityBranchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UtilityBranchApiFp(configuration);
  return {
    /**
     *
     * @param {CreateBranchDto} createBranchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilBranchControllerCreate(
      createBranchDto: CreateBranchDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilBranchControllerCreate(createBranchDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteBranchDto} deleteBranchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilBranchControllerDelete(
      deleteBranchDto: DeleteBranchDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilBranchControllerDelete(deleteBranchDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditBranchDto} editBranchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilBranchControllerEdit(
      editBranchDto: EditBranchDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilBranchControllerEdit(editBranchDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilBranchControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<UtilBranchControllerGet200Response> {
      return localVarFp
        .utilBranchControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UtilityBranchApi - object-oriented interface
 * @export
 * @class UtilityBranchApi
 * @extends {BaseAPI}
 */
export class UtilityBranchApi extends BaseAPI {
  /**
   *
   * @param {CreateBranchDto} createBranchDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityBranchApi
   */
  public utilBranchControllerCreate(
    createBranchDto: CreateBranchDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityBranchApiFp(this.configuration)
      .utilBranchControllerCreate(createBranchDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteBranchDto} deleteBranchDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityBranchApi
   */
  public utilBranchControllerDelete(
    deleteBranchDto: DeleteBranchDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityBranchApiFp(this.configuration)
      .utilBranchControllerDelete(deleteBranchDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditBranchDto} editBranchDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityBranchApi
   */
  public utilBranchControllerEdit(
    editBranchDto: EditBranchDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityBranchApiFp(this.configuration)
      .utilBranchControllerEdit(editBranchDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityBranchApi
   */
  public utilBranchControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityBranchApiFp(this.configuration)
      .utilBranchControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UtilityConfigApi - axios parameter creator
 * @export
 */
export const UtilityConfigApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateConfigDto} createConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilConfigControllerCreate: async (
      createConfigDto: CreateConfigDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createConfigDto' is not null or undefined
      assertParamExists(
        "utilConfigControllerCreate",
        "createConfigDto",
        createConfigDto
      );
      const localVarPath = `/api/v1/util-config/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createConfigDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteConfigDto} deleteConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilConfigControllerDelete: async (
      deleteConfigDto: DeleteConfigDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteConfigDto' is not null or undefined
      assertParamExists(
        "utilConfigControllerDelete",
        "deleteConfigDto",
        deleteConfigDto
      );
      const localVarPath = `/api/v1/util-config/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteConfigDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditConfigDto} editConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilConfigControllerEdit: async (
      editConfigDto: EditConfigDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'editConfigDto' is not null or undefined
      assertParamExists(
        "utilConfigControllerEdit",
        "editConfigDto",
        editConfigDto
      );
      const localVarPath = `/api/v1/util-config/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editConfigDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilConfigControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/util-config/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UtilityConfigApi - functional programming interface
 * @export
 */
export const UtilityConfigApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UtilityConfigApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateConfigDto} createConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilConfigControllerCreate(
      createConfigDto: CreateConfigDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilConfigControllerCreate(
          createConfigDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityConfigApi.utilConfigControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteConfigDto} deleteConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilConfigControllerDelete(
      deleteConfigDto: DeleteConfigDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilConfigControllerDelete(
          deleteConfigDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityConfigApi.utilConfigControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditConfigDto} editConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilConfigControllerEdit(
      editConfigDto: EditConfigDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilConfigControllerEdit(
          editConfigDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityConfigApi.utilConfigControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilConfigControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UtilConfigControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilConfigControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityConfigApi.utilConfigControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UtilityConfigApi - factory interface
 * @export
 */
export const UtilityConfigApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UtilityConfigApiFp(configuration);
  return {
    /**
     *
     * @param {CreateConfigDto} createConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilConfigControllerCreate(
      createConfigDto: CreateConfigDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilConfigControllerCreate(createConfigDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteConfigDto} deleteConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilConfigControllerDelete(
      deleteConfigDto: DeleteConfigDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilConfigControllerDelete(deleteConfigDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditConfigDto} editConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilConfigControllerEdit(
      editConfigDto: EditConfigDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilConfigControllerEdit(editConfigDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilConfigControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<UtilConfigControllerGet200Response> {
      return localVarFp
        .utilConfigControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UtilityConfigApi - object-oriented interface
 * @export
 * @class UtilityConfigApi
 * @extends {BaseAPI}
 */
export class UtilityConfigApi extends BaseAPI {
  /**
   *
   * @param {CreateConfigDto} createConfigDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityConfigApi
   */
  public utilConfigControllerCreate(
    createConfigDto: CreateConfigDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityConfigApiFp(this.configuration)
      .utilConfigControllerCreate(createConfigDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteConfigDto} deleteConfigDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityConfigApi
   */
  public utilConfigControllerDelete(
    deleteConfigDto: DeleteConfigDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityConfigApiFp(this.configuration)
      .utilConfigControllerDelete(deleteConfigDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditConfigDto} editConfigDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityConfigApi
   */
  public utilConfigControllerEdit(
    editConfigDto: EditConfigDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityConfigApiFp(this.configuration)
      .utilConfigControllerEdit(editConfigDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityConfigApi
   */
  public utilConfigControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityConfigApiFp(this.configuration)
      .utilConfigControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UtilityRequestTypeApi - axios parameter creator
 * @export
 */
export const UtilityRequestTypeApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateRequestTypeDto} createRequestTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRequestTypeControllerCreate: async (
      createRequestTypeDto: CreateRequestTypeDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRequestTypeDto' is not null or undefined
      assertParamExists(
        "utilRequestTypeControllerCreate",
        "createRequestTypeDto",
        createRequestTypeDto
      );
      const localVarPath = `/api/v1/util-request-type/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRequestTypeDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteRequestTypeDto} deleteRequestTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRequestTypeControllerDelete: async (
      deleteRequestTypeDto: DeleteRequestTypeDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteRequestTypeDto' is not null or undefined
      assertParamExists(
        "utilRequestTypeControllerDelete",
        "deleteRequestTypeDto",
        deleteRequestTypeDto
      );
      const localVarPath = `/api/v1/util-request-type/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteRequestTypeDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditRequestTypeDto} editRequestTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRequestTypeControllerEdit: async (
      editRequestTypeDto: EditRequestTypeDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'editRequestTypeDto' is not null or undefined
      assertParamExists(
        "utilRequestTypeControllerEdit",
        "editRequestTypeDto",
        editRequestTypeDto
      );
      const localVarPath = `/api/v1/util-request-type/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editRequestTypeDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRequestTypeControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/util-request-type/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UtilityRequestTypeApi - functional programming interface
 * @export
 */
export const UtilityRequestTypeApiFp = function (
  configuration?: Configuration
) {
  const localVarAxiosParamCreator =
    UtilityRequestTypeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateRequestTypeDto} createRequestTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilRequestTypeControllerCreate(
      createRequestTypeDto: CreateRequestTypeDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilRequestTypeControllerCreate(
          createRequestTypeDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "UtilityRequestTypeApi.utilRequestTypeControllerCreate"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteRequestTypeDto} deleteRequestTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilRequestTypeControllerDelete(
      deleteRequestTypeDto: DeleteRequestTypeDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilRequestTypeControllerDelete(
          deleteRequestTypeDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "UtilityRequestTypeApi.utilRequestTypeControllerDelete"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditRequestTypeDto} editRequestTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilRequestTypeControllerEdit(
      editRequestTypeDto: EditRequestTypeDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilRequestTypeControllerEdit(
          editRequestTypeDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "UtilityRequestTypeApi.utilRequestTypeControllerEdit"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilRequestTypeControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UtilRequestTypeControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilRequestTypeControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "UtilityRequestTypeApi.utilRequestTypeControllerGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UtilityRequestTypeApi - factory interface
 * @export
 */
export const UtilityRequestTypeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UtilityRequestTypeApiFp(configuration);
  return {
    /**
     *
     * @param {CreateRequestTypeDto} createRequestTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRequestTypeControllerCreate(
      createRequestTypeDto: CreateRequestTypeDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilRequestTypeControllerCreate(createRequestTypeDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteRequestTypeDto} deleteRequestTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRequestTypeControllerDelete(
      deleteRequestTypeDto: DeleteRequestTypeDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilRequestTypeControllerDelete(deleteRequestTypeDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditRequestTypeDto} editRequestTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRequestTypeControllerEdit(
      editRequestTypeDto: EditRequestTypeDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilRequestTypeControllerEdit(editRequestTypeDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRequestTypeControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<UtilRequestTypeControllerGet200Response> {
      return localVarFp
        .utilRequestTypeControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UtilityRequestTypeApi - object-oriented interface
 * @export
 * @class UtilityRequestTypeApi
 * @extends {BaseAPI}
 */
export class UtilityRequestTypeApi extends BaseAPI {
  /**
   *
   * @param {CreateRequestTypeDto} createRequestTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityRequestTypeApi
   */
  public utilRequestTypeControllerCreate(
    createRequestTypeDto: CreateRequestTypeDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityRequestTypeApiFp(this.configuration)
      .utilRequestTypeControllerCreate(createRequestTypeDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteRequestTypeDto} deleteRequestTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityRequestTypeApi
   */
  public utilRequestTypeControllerDelete(
    deleteRequestTypeDto: DeleteRequestTypeDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityRequestTypeApiFp(this.configuration)
      .utilRequestTypeControllerDelete(deleteRequestTypeDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditRequestTypeDto} editRequestTypeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityRequestTypeApi
   */
  public utilRequestTypeControllerEdit(
    editRequestTypeDto: EditRequestTypeDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityRequestTypeApiFp(this.configuration)
      .utilRequestTypeControllerEdit(editRequestTypeDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityRequestTypeApi
   */
  public utilRequestTypeControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityRequestTypeApiFp(this.configuration)
      .utilRequestTypeControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UtilityRoleApi - axios parameter creator
 * @export
 */
export const UtilityRoleApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateRoleDto} createRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRoleControllerCreate: async (
      createRoleDto: CreateRoleDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRoleDto' is not null or undefined
      assertParamExists(
        "utilRoleControllerCreate",
        "createRoleDto",
        createRoleDto
      );
      const localVarPath = `/api/v1/util-role/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRoleDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteRoleDto} deleteRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRoleControllerDelete: async (
      deleteRoleDto: DeleteRoleDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteRoleDto' is not null or undefined
      assertParamExists(
        "utilRoleControllerDelete",
        "deleteRoleDto",
        deleteRoleDto
      );
      const localVarPath = `/api/v1/util-role/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteRoleDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditRoleDto} editRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRoleControllerEdit: async (
      editRoleDto: EditRoleDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'editRoleDto' is not null or undefined
      assertParamExists("utilRoleControllerEdit", "editRoleDto", editRoleDto);
      const localVarPath = `/api/v1/util-role/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editRoleDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRoleControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/util-role/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UtilityRoleApi - functional programming interface
 * @export
 */
export const UtilityRoleApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UtilityRoleApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateRoleDto} createRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilRoleControllerCreate(
      createRoleDto: CreateRoleDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilRoleControllerCreate(
          createRoleDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityRoleApi.utilRoleControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteRoleDto} deleteRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilRoleControllerDelete(
      deleteRoleDto: DeleteRoleDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilRoleControllerDelete(
          deleteRoleDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityRoleApi.utilRoleControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditRoleDto} editRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilRoleControllerEdit(
      editRoleDto: EditRoleDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilRoleControllerEdit(
          editRoleDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityRoleApi.utilRoleControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilRoleControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UtilRoleControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilRoleControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityRoleApi.utilRoleControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UtilityRoleApi - factory interface
 * @export
 */
export const UtilityRoleApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UtilityRoleApiFp(configuration);
  return {
    /**
     *
     * @param {CreateRoleDto} createRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRoleControllerCreate(
      createRoleDto: CreateRoleDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilRoleControllerCreate(createRoleDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteRoleDto} deleteRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRoleControllerDelete(
      deleteRoleDto: DeleteRoleDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilRoleControllerDelete(deleteRoleDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditRoleDto} editRoleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRoleControllerEdit(
      editRoleDto: EditRoleDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilRoleControllerEdit(editRoleDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilRoleControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<UtilRoleControllerGet200Response> {
      return localVarFp
        .utilRoleControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UtilityRoleApi - object-oriented interface
 * @export
 * @class UtilityRoleApi
 * @extends {BaseAPI}
 */
export class UtilityRoleApi extends BaseAPI {
  /**
   *
   * @param {CreateRoleDto} createRoleDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityRoleApi
   */
  public utilRoleControllerCreate(
    createRoleDto: CreateRoleDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityRoleApiFp(this.configuration)
      .utilRoleControllerCreate(createRoleDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteRoleDto} deleteRoleDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityRoleApi
   */
  public utilRoleControllerDelete(
    deleteRoleDto: DeleteRoleDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityRoleApiFp(this.configuration)
      .utilRoleControllerDelete(deleteRoleDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditRoleDto} editRoleDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityRoleApi
   */
  public utilRoleControllerEdit(
    editRoleDto: EditRoleDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityRoleApiFp(this.configuration)
      .utilRoleControllerEdit(editRoleDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityRoleApi
   */
  public utilRoleControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityRoleApiFp(this.configuration)
      .utilRoleControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UtilityStatusApi - axios parameter creator
 * @export
 */
export const UtilityStatusApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {CreateStatusDto} createStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilStatusControllerCreate: async (
      createStatusDto: CreateStatusDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createStatusDto' is not null or undefined
      assertParamExists(
        "utilStatusControllerCreate",
        "createStatusDto",
        createStatusDto
      );
      const localVarPath = `/api/v1/util-status/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createStatusDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteStatusDto} deleteStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilStatusControllerDelete: async (
      deleteStatusDto: DeleteStatusDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteStatusDto' is not null or undefined
      assertParamExists(
        "utilStatusControllerDelete",
        "deleteStatusDto",
        deleteStatusDto
      );
      const localVarPath = `/api/v1/util-status/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteStatusDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EditStatusDto} editStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilStatusControllerEdit: async (
      editStatusDto: EditStatusDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'editStatusDto' is not null or undefined
      assertParamExists(
        "utilStatusControllerEdit",
        "editStatusDto",
        editStatusDto
      );
      const localVarPath = `/api/v1/util-status/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        editStatusDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilStatusControllerGet: async (
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/util-status/get`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (order !== undefined) {
        for (const [key, value] of Object.entries(order)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (filter !== undefined) {
        for (const [key, value] of Object.entries(filter)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (dateFilter !== undefined) {
        localVarQueryParameter["date_filter"] = dateFilter;
      }

      if (startDate !== undefined) {
        for (const [key, value] of Object.entries(startDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (endDate !== undefined) {
        for (const [key, value] of Object.entries(endDate)) {
          localVarQueryParameter[key] = value;
        }
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UtilityStatusApi - functional programming interface
 * @export
 */
export const UtilityStatusApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UtilityStatusApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateStatusDto} createStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilStatusControllerCreate(
      createStatusDto: CreateStatusDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilStatusControllerCreate(
          createStatusDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityStatusApi.utilStatusControllerCreate"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {DeleteStatusDto} deleteStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilStatusControllerDelete(
      deleteStatusDto: DeleteStatusDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilStatusControllerDelete(
          deleteStatusDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityStatusApi.utilStatusControllerDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {EditStatusDto} editStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilStatusControllerEdit(
      editStatusDto: EditStatusDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<MessageResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilStatusControllerEdit(
          editStatusDto,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityStatusApi.utilStatusControllerEdit"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async utilStatusControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UtilStatusControllerGet200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.utilStatusControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UtilityStatusApi.utilStatusControllerGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UtilityStatusApi - factory interface
 * @export
 */
export const UtilityStatusApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UtilityStatusApiFp(configuration);
  return {
    /**
     *
     * @param {CreateStatusDto} createStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilStatusControllerCreate(
      createStatusDto: CreateStatusDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilStatusControllerCreate(createStatusDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteStatusDto} deleteStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilStatusControllerDelete(
      deleteStatusDto: DeleteStatusDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilStatusControllerDelete(deleteStatusDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EditStatusDto} editStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilStatusControllerEdit(
      editStatusDto: EditStatusDto,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<MessageResponseDto> {
      return localVarFp
        .utilStatusControllerEdit(editStatusDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [order]
     * @param {object} [filter]
     * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
     * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    utilStatusControllerGet(
      search?: string,
      limit?: number,
      offset?: number,
      order?: object,
      filter?: object,
      dateFilter?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig
    ): AxiosPromise<UtilStatusControllerGet200Response> {
      return localVarFp
        .utilStatusControllerGet(
          search,
          limit,
          offset,
          order,
          filter,
          dateFilter,
          startDate,
          endDate,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UtilityStatusApi - object-oriented interface
 * @export
 * @class UtilityStatusApi
 * @extends {BaseAPI}
 */
export class UtilityStatusApi extends BaseAPI {
  /**
   *
   * @param {CreateStatusDto} createStatusDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityStatusApi
   */
  public utilStatusControllerCreate(
    createStatusDto: CreateStatusDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityStatusApiFp(this.configuration)
      .utilStatusControllerCreate(createStatusDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteStatusDto} deleteStatusDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityStatusApi
   */
  public utilStatusControllerDelete(
    deleteStatusDto: DeleteStatusDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityStatusApiFp(this.configuration)
      .utilStatusControllerDelete(deleteStatusDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EditStatusDto} editStatusDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityStatusApi
   */
  public utilStatusControllerEdit(
    editStatusDto: EditStatusDto,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityStatusApiFp(this.configuration)
      .utilStatusControllerEdit(editStatusDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {object} [order]
   * @param {object} [filter]
   * @param {string} [dateFilter] TODAY | YESTERDAY | WEEK | MONTH
   * @param {string} [startDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {string} [endDate] The date and time of the event. Format: YYYY-MM-DD
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UtilityStatusApi
   */
  public utilStatusControllerGet(
    search?: string,
    limit?: number,
    offset?: number,
    order?: object,
    filter?: object,
    dateFilter?: string,
    startDate?: string,
    endDate?: string,
    options?: RawAxiosRequestConfig
  ) {
    return UtilityStatusApiFp(this.configuration)
      .utilStatusControllerGet(
        search,
        limit,
        offset,
        order,
        filter,
        dateFilter,
        startDate,
        endDate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
