import "./edit-inhabitant.scss";
import { FaChevronLeft } from "react-icons/fa6";
import { FormProvider } from "react-hook-form";
import { ConfirmDialog } from "primereact/confirmdialog";

import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import HeaderContent from "@shared/ui/header-content/header-content";
import HeaderButton from "@shared/ui/header-button/header-button";

import FormInhabitant from "../form-inhabitant/form-inhabitant";
import { useEditInhabitant } from "./edit-inhabitant.hook";
import PrintQr from "../print-qr/print-qr";

export function EditInhabitant() {
  const {
    inhabitantData,
    isLoading,
    inhabitantError,
    formMethod,
    actions,
    componentRef,
    handleBack,
    handleAction,
  } = useEditInhabitant();

  const displayLoading = (
    <div className="card-loading">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection
        title="No Data"
        message="No data found in selected record. Please try again."
      />
    </div>
  );
  const mainContent = (
    <div>
      <section className="grid grid-cols-1 gap-2 mb-4 bg-white">
        <FormInhabitant />
      </section>
    </div>
  );
  const printSection = () => (
    <div style={{ display: "none" }}>
      <div ref={componentRef}>
        <PrintQr
          type="inhabitant"
          name={`${inhabitantData?.first_name} ${inhabitantData?.last_name}`}
          code={inhabitantData?.code || "-"}
        />
      </div>
    </div>
  );

  return (
    <div id="Inhabitant" className="inhabitant">
      <HeaderContent
        title={
          inhabitantData?.first_name + " " + inhabitantData?.last_name ||
          "Edit Inhabitant"
        }
        backIcon={<FaChevronLeft />}
        onBack={() => handleBack()}
      >
        <HeaderButton actions={actions} onAction={handleAction} />
      </HeaderContent>
      {printSection()}

      <section className="p-7">
        <ConfirmDialog />
        <FormProvider {...formMethod}>
          {isLoading && displayLoading}
          {inhabitantError && !isLoading && displayError}
          {!isLoading ? mainContent : null}
        </FormProvider>
      </section>
    </div>
  );
}

export default EditInhabitant;
