import "./print-qr.scss";
import QRCode from "react-qr-code";

export interface PrintQrProps {
  type: string;
  name: string;
  code: string;
}

export function PrintQr({ type, name, code }: PrintQrProps) {
  const qrValue = `${code}:${type}:${name}`;
  return (
    <div id="PrintQr" className="print-qr">
      <style type="text/css" media="print">
        {
          "\
        @page { size: 2.83in 4.02in; }\
      "
        }
      </style>
      <section className=" flex justify-center items-center flex-col gap-4 h-screen">
        <QRCode value={qrValue} size={200} />
        <div className="flex flex-col items-center">
          <h1>{name}</h1>
        </div>
      </section>
    </div>
  );
}

export default PrintQr;
