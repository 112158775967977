import { QueryClient, QueryClientProvider } from "react-query";
import { DateTimeProvider } from "@shared/utility/date-time-service/date-time.context";
import { NotificationProvider } from "@shared/ui/notification/notification.context";
import { AppRoute } from "./app.route";
import { PrimeReactProvider } from "primereact/api";
import { appConfig } from "./app.config";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

function App() {
  const primeReactValue = {
    ripple: true,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <PrimeReactProvider value={primeReactValue}>
        <DateTimeProvider
          dateFormat={appConfig.dateFormat}
          dateTimeFormat={appConfig.dateTimeFormat}
        >
          <NotificationProvider>
            <AppRoute />
          </NotificationProvider>
        </DateTimeProvider>
      </PrimeReactProvider>
    </QueryClientProvider>
  );
}

export default App;
