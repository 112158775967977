import { Outlet } from "react-router-dom";

import { FilterInhabitantProvider } from "./filter-inhabitant/filter-inhabitant.context";
import './inhabitant.scss';

export function Inhabitant() {
  return (
    <div id="Inhabitant" className="inhabitant">
       <FilterInhabitantProvider>
        <Outlet />
      </FilterInhabitantProvider>
    </div>
  );
}

export default Inhabitant;
