import axios, { AxiosError } from "axios";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useMutation, useQuery } from "react-query";
import { getApiErrorMessage } from "@core/utility/get-error-message";
import { apiConfig } from "./config.query";
import { authHeaders } from "@factory/authentication/auth-header";
import { useSessionContext } from "@factory/authentication/session.context";
import { QueryKeyGoogleCloudStorage } from "@factory/model/query-model-google-cloud-storage";

export const viewAsset = async (filepath: string) => {
  const operation = await axios.get(
    `${apiConfig.basePath}/api/v1/cloud/storage/asset/${filepath}`,
    { ...authHeaders(), responseType: "blob" }
  );
  return operation.data;
};

export function useQyGetGoogleCloudStorage(
  filepath: string,
  enabled?: boolean,
  onSuccess?: ((data: File) => void | Promise<unknown>) | undefined,
  onError?: ((error: AxiosError) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (filepath: string) => {
    await checkSession();

    showProgress();

    const operation = await axios.get(
      `${apiConfig.basePath}/api/v1/cloud/storage/asset/${filepath}`,
      { ...authHeaders(), responseType: "blob" }
    );
    return operation.data;
  };

  return useQuery({
    enabled,
    queryKey: [QueryKeyGoogleCloudStorage, filepath],
    queryFn: () => apiFn(filepath),
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}

export function useQyGetGoogleCloudAssetPreview(
  onSuccess?: ((data: any) => void | Promise<unknown>) | undefined,
  onError?: ((error: unknown) => void | Promise<unknown>) | undefined
) {
  const { checkSession } = useSessionContext();
  const { showProgress, hideProgress, showError } = useNotificationContext();

  const apiFn = async (filepath: string) => {
    await checkSession();

    showProgress();
    const operation = await axios.get(
      `${apiConfig.basePath}/api/v1/cloud/storage/asset/${filepath}`,
      { ...authHeaders(), responseType: "blob" }
    );
    return operation.data;
  };

  return useMutation({
    mutationFn: apiFn,
    onSuccess: (response) => {
      hideProgress();
      if (onSuccess) {
        onSuccess(response);
      }
    },
    onError: (err: AxiosError) => {
      hideProgress();
      const message = getApiErrorMessage(err);
      showError(message);

      if (onError) {
        onError(err);
      }
    },
    onSettled() {
      hideProgress();
    },
  });
}
