export enum QueryKey {
  Authentication = "Authentication",
  Household = "Household",
  Inhabitant = "Inhabitant",
  GoogleCloudStorage = "GoogleCloudStorage",
  Meeting = "Meeting",
  Attendance = "Attendance",
  DashboardAge = "DashboardAge",
  DashboardGender = "DashboardGender",
  DashboardVoter = "DashboardVoter",
  DashboardHousehold = "DashboardHousehold",
  DashboardInhabitant = "DashboardInhabitant",
  Person = "Person",
  PersonAddress = "PersonAddress",
  DashboardDeceased = "DashboardDeceased",
  DashboardInhabitantPurok = "DashboardInhabitatntPurok",
  DashboardHouseholdPurok = "DashboardHosueholdPurok",
  Role = "Role",
  // KEY ADD
}
