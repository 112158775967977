import { useLoadScript } from "@react-google-maps/api";
import { ReactNode, memo } from "react";
import { appFeature } from "../../../app.feature";

export interface GmapProps {
  children: ReactNode;
}

export function Gmap({ children }: GmapProps) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: appFeature.config.googleMap.mapApi,
  });

  if (loadError) {
    return (
      <div>
        Map cannot be loaded right now. Check your internet connection and try
        again.
      </div>
    );
  }

  if (!isLoaded) return <div>Map Loading...</div>;

  return <div>{children}</div>;
}

export default memo(Gmap);
