import "./new-person-address.scss";

import { FormProvider } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";

import HeaderContent from "@shared/ui/header-content/header-content";
import FormPersonAddress from "../form-person-address/form-person-address";
import { useNewPersonAddress } from "./new-person-address.hook";

export function NewPersonAddress() {
  const {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
  } = useNewPersonAddress();

  return (
    <div id="PersonAddress" className="person-address">
      <HeaderContent
        title="New Person Address"
        backIcon={<FaChevronLeft />}
        onBack={() => navigate("../")}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={isCreating}
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <section className="grid grid-cols-1 gap-2 mb-4 bg-white">
            <FormPersonAddress />
          </section>
        </FormProvider>
      </div>
    </div>
  );
}

export default NewPersonAddress;
