import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, useForm } from "react-hook-form";

import { MessageResponseDto } from "@api/api";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { useQyCreateMeeting } from "@factory/query/meeting.query";
import { MeetingFormRule, MeetingFormSchema } from "@factory/form/form.rule";
import { meetingFormDefault } from "@factory/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";

export function useNewMeeting() {
 // LOCAL DECLARATION
  const navigate = useNavigate();
  const { mapCreateMeeting } = useFormApiMapper();
  const { showError, showSuccess, showWarning } = useNotificationContext();

  // QUERIES
  const handleApiSuccess = (response: MessageResponseDto) => {
    const data = response.data as any;

    showSuccess(`New Meeting is created`);
    navigate(`../${data.code}`);
  };
  const { mutate: addMeeting, isLoading: isCreating } =
    useQyCreateMeeting(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<MeetingFormSchema>({
    defaultValues: meetingFormDefault,
    resolver: zodResolver(MeetingFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: MeetingFormSchema) => {
    const formData = mapCreateMeeting(form);
    addMeeting(formData);
  };
  const handleValidateError = (err: FieldErrors<MeetingFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  return {
    isCreating,
    formMethod,
    navigate,
    handleSubmit,
    handleValidate,
    handleValidateError,
  };
}
