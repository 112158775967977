import { 
    MessageResponseDto, 
    AuthenticationApiFp,
    AuthenticationControllerAuthenticate201Response,
    SigninUserRequestDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type SigninResponseAuthenticationModel = AuthenticationControllerAuthenticate201Response;
// export type GetAuthenticationModel = GetAuthenticationDto;
export type MutateResponseAuthenticationModel = MessageResponseDto;
export type SigninAuthenticationModel = SigninUserRequestDto;
// export type UpdateAuthenticationModel = EditAuthenticationDto;
// export type DeleteAuthenticationModel = DeleteAuthenticationDto;

export const apiAuthenticationFp = AuthenticationApiFp(apiConfig);
export const signinAuthenticationController = apiAuthenticationFp.authenticationControllerAuthenticate;
export const introspectAuthenticationController = apiAuthenticationFp.authenticationControllerIntrospect;
export const refreshAuthenticationController = apiAuthenticationFp.authenticationControllerRefresh;
export const renewAuthenticationController = apiAuthenticationFp.authenticationControllerRefreshRenew;

export const QueryKeyAuthentication = QueryKey.Authentication;