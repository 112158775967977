import { useLocation, useNavigate } from "react-router-dom";
import { getVisibleMenu } from "@shared/utility/menu-visibility";
import { useState } from "react";

function getMenuIndex(path: string) {
  const locationNames = path.split("/");
  if (locationNames.length === 3) {
    return 0;
  }

  return 1;
}

export function useEditHouseholdMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(
    getMenuIndex(location.pathname)
  );

  const initialMenu = [
    {
      label: "Details",
      icon: "pi pi-file",
      command: () => navigate(""),
    },
    {
      label: "Inhabitants",
      icon: "pi pi-file",
      command: () => navigate(`inhabitant`),
    },
  ];

  const menu = getVisibleMenu(initialMenu);

  return { menu, activeIndex, setActiveIndex };
}
