import {
  MessageResponseDto,
  GetInhabitantDto,
  InhabitantControllerGet200Response,
  InhabitantApiFp,
  DeleteInhabitantDto,
  CreateInhabitantBaseDto,
  EditInhabitantBaseDto,
} from "@api/api";
import { apiConfig } from "@factory/query/config.query";
import { QueryKey } from "@factory/query/query-key.enum";

export type GetResponseInhabitantModel = InhabitantControllerGet200Response;
export type GetInhabitantModel = GetInhabitantDto;
export type MutateResponseInhabitantModel = MessageResponseDto;
export type CreateInhabitantModel = CreateInhabitantBaseDto;
export type UpdateInhabitantModel = EditInhabitantBaseDto;
export type DeleteInhabitantModel = DeleteInhabitantDto;

export interface CreateInhabitantModelExtension {
  inhabitant: CreateInhabitantBaseDto;
  file: File;
}
export interface UpdateInhabitantModelExtension {
  inhabitant: EditInhabitantBaseDto;
  file: File;
}

export const apiInhabitantFp = InhabitantApiFp(apiConfig);
export const getInhabitantController = apiInhabitantFp.inhabitantControllerGet;
export const createInhabitantController =
  apiInhabitantFp.inhabitantControllerCreate;
export const updateInhabitantController =
  apiInhabitantFp.inhabitantControllerEdit;
export const deleteInhabitantController =
  apiInhabitantFp.inhabitantControllerDelete;

export const QueryKeyInhabitant = QueryKey.Inhabitant;
