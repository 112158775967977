import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { confirmDialog } from "primereact/confirmdialog";

import {
  HouseholdFormRule,
  HouseholdFormSchema,
} from "@factory/form/form.rule";
import { useFormDefaultHousehold } from "@factory/form/form-default-household.hook";
import { useFormApiMapper } from "@factory/services/form-api-mapper.hook";
import { ActionEnum } from "@factory/model/actions.enum";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import {
  useQyUpdateHousehold,
  useQyGetHouseholdById,
  useQyDeleteHousehold,
} from "@factory/query/household.query";
import { GetResponseHouseholdModel } from "@factory/model/query-model-household";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ButtonAction } from "@shared/ui/header-button/button-action";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { useQyGetGoogleCloudAssetPreview } from "@factory/query/google-cloud-storage.query";

export function useEditHousehold() {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDefault } = useFormDefaultHousehold();
  const navigate = useNavigate();
  const { mapUpdateHousehold, mapDeleteHousehold } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const { householdId } = useParams();
  const actions: ButtonAction[] = [
    { label: "Update", type: "split", value: ActionEnum.Update },
    { label: "Delete", type: "more", value: ActionEnum.Delete },
  ];

  // QUERY DECLARATION
  // API GETBYID Household
  const handleGetApiSuccess = (data: GetResponseHouseholdModel) => {
    if (!data || data.data?.length === 0) {
      return;
    }

    const responseData = data.data?.[0];

    if (responseData?.avatar) {
      viewAsset(responseData?.avatar, {
        onSuccess(data) {
          const assetDisplay = URL.createObjectURL(data);
          setValue("photo", assetDisplay);
        },
      });
    }

    setValue("number", responseData?.number || "");
    setValue("name", responseData?.name || "");
    setValue("streetName", responseData?.street_name || "");
    setValue("subdivision", responseData?.subdivision || "");
    setValue("zone", responseData?.zone || "");
    setValue("sitio", responseData?.sitio || "");
    setValue("purok", responseData?.purok || "");
    setValue("barangay", responseData?.barangay || "");
    setValue("municipality", responseData?.municipality || "");
    setValue("province", responseData?.province || "");
    setValue("region", responseData?.region || "");
    setValue("country", responseData?.country || "");
    setValue("longitude", responseData?.longitude);
    setValue("latitude", responseData?.latitude);
    setValue("avatar", responseData?.avatar || "");
    setValue("allowBoarders", Boolean(responseData?.allow_boarders));
    setValue("hasBoardingPermit", Boolean(responseData?.has_boarding_permit));
    setValue("houseMaterial", responseData?.house_material || "");
    setValue("rooms", responseData?.rooms);
    setValue("toilets", responseData?.toilets);
    setValue("hasBackyardGarden", Boolean(responseData?.has_backyard_garden));
    setValue("incomeSources", responseData?.income_sources || "");
    setValue("pets", responseData?.pets);
    setValue("twoWheelVehicle", responseData?.two_wheel_vehicle);
    setValue("threeWheelVehicle", responseData?.three_wheel_vehicle);
    setValue("fourWheelVehicle", responseData?.four_wheel_vehicle);
  };
  const {
    data: householdResponse,
    isLoading,
    isError: householdError,
  } = useQyGetHouseholdById(householdId || "", handleGetApiSuccess);
  const householdData = householdResponse?.data?.[0];

  // API VIEW ASSET
  const { mutate: viewAsset } = useQyGetGoogleCloudAssetPreview();

  // API UPDATE Household
  const handleUpdateApiSuccess = () => {
    showSuccess("Household updated successfully");
  };
  const { mutate: updateHousehold, isLoading: isEditLoading } =
    useQyUpdateHousehold(handleUpdateApiSuccess);

  // API DELETE Household
  const handleDeleteApiSuccess = () => {
    handleBack();
    showSuccess("Household deleted successfully");
  };
  const { mutate: deleteHousehold } = useQyDeleteHousehold(
    handleDeleteApiSuccess
  );

  // LOCAL FUNCTIONS
  const formMethod = useForm<HouseholdFormSchema>({
    defaultValues: getDefault(householdData),
    resolver: zodResolver(HouseholdFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;

  const handleValidate = (form: HouseholdFormSchema) => {
    if (!householdData || !householdData.code) {
      throw new Error("No household data");
    }

    const formData = mapUpdateHousehold(form, householdData.code);
    updateHousehold(formData);
  };

  const handleValidateError = (err: FieldErrors<HouseholdFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleBack = () => {
    navigate("../");
  };
  const accept = () => {
    if (!householdData || !householdData.code) {
      throw new Error("No household data found");
    }

    const form = mapDeleteHousehold(householdData.code);
    deleteHousehold(form);
  };
  const handleAction = (action: ButtonAction) => {
    switch (action.value) {
      case ActionEnum.Update:
        handleSubmit(handleValidate, handleValidateError)();
        break;

      case ActionEnum.Delete:
        confirmDialog({
          message: "Are you sure you want to delete this record?",
          header: "Delete Confirmation",
          icon: "pi pi-info-circle",
          defaultFocus: "reject",
          acceptClassName: "p-button-danger",
          accept,
        });
        break;
    }
  };

  return {
    householdId,
    householdData,
    isLoading,
    householdError,
    formMethod,
    actions,
    isEditLoading,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handleBack,
    handleAction,
  };
}
