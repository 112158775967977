import { LocalAuth } from "@core/model/local-auth";

export class ApiToFormService {
  static MapLocalAuth(data: any): LocalAuth {
    const mappedData = {
      accessExpiry: data.access_exp,
      accessToken: data.access_token,
      refreshExpiry: data.refresh_exp,
      refreshToken: data.refresh_token,
      userCode: data.user_code,
    } as LocalAuth;

    return mappedData;
  }
}
