import { ReactNode, createContext, useContext } from "react";
import { useFilterAttendance } from "./filter-attendance.hook";

const FilterAttendanceContext = createContext(
  {} as ReturnType<typeof useFilterAttendance>
);
export function useFilterAttendanceContext() {
  return useContext(FilterAttendanceContext);
}

interface FilterAttendanceProviderProps {
  children: ReactNode;
}
export function FilterAttendanceProvider({
  children,
}: FilterAttendanceProviderProps) {
  return (
    <FilterAttendanceContext.Provider value={useFilterAttendance()}>
      {children}
    </FilterAttendanceContext.Provider>
  );
}